import React from "react";
import Button from "../../utilities/components/button/button.index";
import "./startpage.style.scss";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

const StartPage = () => {
  // const navigate = useNavigate();
  const history = useHistory();

  return (
    <div className="background-startpage">
      <div className="content">
        <div className="info">
          <h1>سلام خوش‌ آمدی!</h1>
          <div>
            ما یه تیم از بچه‌های دانشگاه علم و صنعت هستیم که در زمینه هوش مصنوعی
            و پردازش متن تحقیق می‌کنیم. حتما قبلا اسم
            <span dir="rtl">
              <b> MBTI </b>
            </span>
            رو شنیدید و شاید حتی تستش رو هم داده باشید. ما از شما می‌خوایم که
            اینجا یه آزمون کاملاً معتبر
            <span dir="rtl">
              <b> MBTI </b>
            </span>
            بدید. در انتها نتیجه تست و یک‌سری تحلیل جالب از شخصیتتون هم به شما
            خواهیم داد و همچنین برای تشکر از وقتی که برای این تحقیق اختصاص
            می‌دین، هدیه‌ای به شما تعلق خواهد گرفت.
          </div>
          <div>
            شرط شروع این پرسش‌نامه اینه که دارای یک اکانت توییتر پابلیک باشید که
            حداقل 150 توییت در اون وجود داشته باشه.
          </div>
        </div>
        <div className="btn">
          <div className="str" onClick={() => history.push("/twitter")}>
            <Button text="شروع" />
          </div>
          <div className="mbti" onClick={() => history.push("/Info")}>
            <Button text=" MBTI چیست؟" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartPage;
