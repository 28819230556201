import "./Dataset.css";
import { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { ToastContainer, toast } from 'react-toastify';
import { MediaUrl, ServerUrl } from "utils/Constants";

function ImageCaptioningDataset(props) {
    document.title = "جمع‌آوری دیتاست به صورت وارد کردن پرسش و پاسخ | PersianVQA"
    const [image, setImage] = useState(null);

    //////page status://////
    //"ln": loading new pic / question
    //"w": waiting for the user to fill the form and submit
    //"l": submitting the form user filled to server
    //"t": translating the form user filled
    //"s": submitted!
    //"nq": no auto question for this image error
    const [status, setStatus] = useState("ln");
    const [caption, setCaption] = useState("");

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            if (image)
                URL.revokeObjectURL(image.preview);
        },
        [image]
    );

    async function getNewVQ(reset = true) {
        if (!reset && image)
            return;
        setStatus("ln");
        setCaption("");
        console.log("Token " + localStorage.getItem("loginToken"));
        // document.getElementById("autoq_btn").disabled = false;
        fetch(`${ServerUrl}/dataset/check-access`, {
            method: "GET",
            headers: {
                "Authorization": "Token " + localStorage.getItem("loginToken"),
            }
        }).then(r => r.json()).then(r => {
            if (r.access_dataset)
                fetch(`${ServerUrl}/dataset/captions_image`, {
                    method: "GET",
                    headers: {
                        "Authorization": "Token " + localStorage.getItem("loginToken"),
                    }
                }).then(res => res.ok ? res.json() : res.status === 401 ? 401 : null)
                    .then(res => {
                        if (res === 401)
                            window.location.href = "/pvqa/login"
                        else if (!res) {
                            throwError('مشکلی رخ داد! لطفا دوباره تلاش کنید.')
                            return;
                        }
                        else if (!res.path) {
                            throwError('هیچ تصویری موجود نیست!')
                            return;
                        }
                        console.log(res);
                        setImage(res);
                        setCaption("");
                        if (document.getElementById("ques0"))   ////////FIX
                            document.getElementById("ques0").focus();
                    }).catch(err => {
                        throwError('مشکلی رخ داد! لطفا دوباره تلاش کنید.')
                        console.log(err)
                    })
            else
                window.location.href = "/pvqa";
        }).catch(err => {
            window.location.href = "/pvqa";
        })
        setStatus("w");
    }

    function setCaptionWithIdx(idx, value) {
        let tempA = [...caption];
        tempA[idx] = value;
        setCaption([...tempA]);
        console.log(tempA);
    }

    useEffect(() => {
        if (!localStorage.getItem("loginToken")) {
            window.location.href = "/pvqa"
            return;
        }
        getNewVQ(false)
    }, []);

    function throwError(text) {
        toast.error(text, {
            position: "bottom-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function submitCaption() {
        setStatus("l");
        // for (let i = 0; i < ; i++) {
            if (!caption) {
                throwError('لطفا قبل از ثبت، متن مربوط به تصویر را بنویسید.')
                setStatus("w")
                return;
            }
            if (caption.split(/[\s,،.]+/).filter(element => element).length < 8) {
                throwError('حداقل تعداد کلمات متن باید ۸ باشد.')
                setStatus("w")
                return;
            }
        // }

        let fd = new FormData()
        fd.append("caption", caption);
        fd.append("image", image.id);
        fetch(`${ServerUrl}/dataset/captions/`, {
            method: "POST",
            headers: {
                "Authorization": "Token " + localStorage.getItem("loginToken"),
            },
            body: fd
        }).then(res => res.ok ? res.json() : null)
            .then(res => {
                if (!res) {
                    throwError('مشکلی در ثبت داده رخ داد!')
                    setStatus("w")
                    return;
                }
                console.log(res);
                setStatus("s");
            }).catch(err => {
                console.log(err);
                throwError('مشکلی در ثبت داده رخ داد!')
                setStatus("w")
            })
        }

    let KeyPress = function (e) {
        e = e || window.event;
        if (e.code === "Enter") {
            switch (status) {
                case "s": getNewVQ();
                    break;
                case "nq": setStatus("w");
                    break;
                default:
            }
        }
    };
    document.addEventListener('keydown', KeyPress);
    return (
        <div className="dataset-page dataset-with-question">
            <div className="dataset-page-inner">
                <div className="page-title-description" style={{ marginBottom: "5vh" }}>
                    <h1>جمع‌آوری دیتاست عنوان‌گذاری تصویر</h1>
                    {/* <p className="description">
                        
                         در این قسمت...
                    </p> 
                    {/* <p className="description">
                    
            در صورت نیاز می‌توانید راهنمای جمع‌آوری دیتاست را ببینید.
          </p> */}
          <p className="description">از خاموش بودن VPN یا فیلترشکن اطمینان حاصل کنید.</p>
          <p className="description">
            ** توجه داشته باشید کلماتی که هم صورت چسبیده هم جدای آن‌ها رایج است (مانند "اینطور" و "این طور") را به شکل جدا از هم بنویسید. (در این مثال "این طور" خواستهٔ ماست.)
            اگر می‌دانید کلمه‌ای با نیم‌فاصله است در صورت امکان آن را با نیم‌فاصله بنویسید.
          </p>
                </div>
                <>
                    <div className="dataset">
                        {status !== "w" && (
                            <div className="dataset-loading">
                                <div className="dataset-loading-inner">
                                    {status === "l" ? (
                                        <>
                                            <h5>در حال ثبت</h5>
                                            <p>لطفا منتظر بمانید</p>
                                            <div className="stage">
                                                <div className="dot-flashing"></div>
                                            </div>
                                        </>
                                    ) : status === "s" ? (
                                        <>
                                            <CheckCircleIcon />
                                            <h5 className="submitted">
                                                اطلاعات شما ثبت شد. از همکاریتان متشکریم!
                                            </h5>
                                            <button className="btn" onClick={() => getNewVQ()}>
                                                ثبت دیتای جدید
                                            </button>
                                        </>
                                    ) : status === "ln" ? (
                                        <>
                                            <h5>در حال ایجاد فرم جدید</h5>
                                            <p>لطفا منتظر بمانید</p>
                                            <div className="stage">
                                                <div className="dot-flashing"></div>
                                            </div>
                                        </>
                                    ) : (
                                        //status = "nq"
                                        <>
                                            <SentimentVeryDissatisfiedIcon />
                                            <h5 className="submitted">
                                                پرسشی برای این تصویر ثبت نشده است. لطفا پرسش را دستی وارد کنید.
                                            </h5>
                                            <button className="btn" onClick={() => setStatus("w")}>
                                                ادامه
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 order-lg-2 col-lg-6 ">
                                <div className="picture-field">
                                    <div className="img-container">
                                        <img src={MediaUrl + (MediaUrl?.includes("/pvqa")?"/":"/pvqa/") + image?.path} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 ">
                                {/* <div className="q-submit-field"> */}
                                <div className="col">
                                        <div>
                                            
                                            <div className="q-submit-field">
                                                <textarea
                                                    rows={5}
                                                    placeholder="متن مربوط به تصویر را اینجا بنویسید"
                                                    maxLength="200"
                                                    onChange={(e) => setCaption(e.target.value)}
                                                    value={caption}
                                                    id={"ans"}
                                                />
                                            </div>
                                        </div>
                                    {!!image && <>
                                        <button
                                            className="btn"
                                            onClick={submitCaption}
                                            disabled={status !== "w"}
                                        >
                                            <div className="btn-inner">
                                                {"ثبت"}
                                                <ChevronLeftIcon />
                                            </div>
                                        </button>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
}

export default ImageCaptioningDataset;
