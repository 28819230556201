import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef, Component } from "react";
import "./userInfo.style.scss";
import "antd/dist/antd.min.css";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import RadioGroup, { useRadioGroup } from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { post } from "../../scripts/api";
// import { useNavigate } from 'react-router-dom';
import { APIPath } from "../../data";

let Gender = "";
const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === props.value;
    Gender = radioGroup.value;
  }
  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};

const UserInfo = (props) => {
  const [Location, setLocation] = React.useState("");
  const [Age, setAge] = React.useState("");
  const [Degree, setDegree] = React.useState("");
  const [complete, setComplete] = React.useState(false);
  const [CodeToShow, setCodeToShow] = React.useState("");
  const [MbtiType, setMbtiType] = React.useState("");
  // const navigate = useNavigate();
  const history = useHistory();

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  const handleChangeDegree = (event) => {
    setDegree(event.target.value);
  };

  const btnClicked = () => {
    if (Gender === "" || Degree === "" || Age === "") {
      setComplete(false);
      return toast("تعدای از پرسش ها بدون پاسخ باقی مانده اند");
    } else {
      setComplete(true);
      //////do request and stuff
      const body = {
        twitter_id: props.Id,
        auth_token: props.token,
        answers: props.QValue,
        age: Age,
        gender: Gender,
        location: Location,
        degree: Degree,
      };
      post(APIPath.submit.submit, body).then((data) => {
        setCodeToShow(data.data.token);
        props.SaveCode(data.data.token);
        setMbtiType(data.data.mbti_result);
        history.push("/" + data.data.mbti_result);
      });
    }
  };

  return (
    <div className="userbackground">
      <ToastContainer />
      <div className="usercontent">
        <div className="userInfo">
          <div className="userItems">
            <h6>جنسیت</h6>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="زن"
            >
              <MyFormControlLabel value="2" label="زن" control={<Radio />} />
              <MyFormControlLabel value="1" label="مرد" control={<Radio />} />
            </RadioGroup>
          </div>
          <div className="userItems">
            <h6> در کدام منطقه جغرافیایی کشور زندگی می کنید؟</h6>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">
                <b> منطقه</b>
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={Location}
                label="location"
                onChange={handleChange}
              >
                <MenuItem value={"1"}>شمال</MenuItem>
                <MenuItem value={"3"}>شرق</MenuItem>
                <MenuItem value={"4"}>غرب</MenuItem>
                <MenuItem value={"2"}>جنوب</MenuItem>
                <MenuItem value={"5"}>مرکز</MenuItem>
                <MenuItem value={"6"}>خارج از کشور</MenuItem>
                <MenuItem value={"7"}>مایل نیستم به این سوال پاسخ دهم</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="userItems">
            <h6>چند ساله هستید؟</h6>
            <TextField
              id="outlined-number"
              label="سن"
              type="number"
              InputProps={{ inputProps: { min: 9, max: 120 } }}
              onChange={(event) =>
                event.target.value < 0 || event.target.value > 120
                  ? (event.target.value = "")
                  : setAge(event.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="userItems">
            <h6> آخرین مقطع تحصیلی که به پایان رسانده یا درحال تحصیل هستید، چیست؟</h6>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">
                <b> تحصیلات</b>
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={Degree}
                label="Degree"
                onChange={handleChangeDegree}
              >
                <MenuItem value={"1"}>دیپلم و پایین تر </MenuItem>
                <MenuItem value={"2"}>کارشناسی</MenuItem>
                <MenuItem value={"3"}>کارشناسی ارشد</MenuItem>
                <MenuItem value={"4"}>دکترا و بالاتر </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="fill-button">
          <Button onClick={btnClicked} variant="contained" size="large">
            اتمام و مشاهده نتایج
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
