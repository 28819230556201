import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Form, FormControl } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import "./Navbar.css";

function CustomNavbar(props) {
    const [profile, setProfile] = useState(null);
    const [dropVis, setDropVis] = useState(false);

    useEffect(() => { }, [props.triggerNavbarUpdate]);

    // useEffect(() => {
    //     function checkProfile(){
    //         console.log("here");
    //         setProfile(localStorage.getItem("loginToken"));
    //     }
    //     window.addEventListener('storage', checkProfile);
      
    //     return () => {
    //       window.removeEventListener('storage', checkProfile);
    //     }

    //   }, [])
    const logout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("loginToken");
        // window.location.replace("/");
    };

    window.onclick = function (event) {
        if (!event.target.matches(".nav-dropdown-toggle")) {
            if (dropVis) setDropVis(false);
        }
        if (!event.target.matches(".dropdown-toggle")) {
            var dropdowns = document.getElementsByClassName("dropdown-menu");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains("show")) {
                    openDropdown.classList.remove("show");
                }
            }
        }
    };

    return (
        <Navbar fixed="top" className={"custom-nav navbar-default " + (props.theme[0] === "l" ? "navbar-light" : "navbar-dark")} collapseOnSelect expand="md">
            {/* <div className="nav-upper">  */}
            <Nav className="ml-auto" style={{ marginLeft: "2px" }}>
                {!localStorage.getItem("loginToken") ? (
                    <Nav.Link
                        href="/pvqa/login"
                        data-testid="no-profile"
                        className="no-profile"
                    >
                        ورود / ثبت نام
                    </Nav.Link>
                ) : (
                    // <>
                    //     <div className="nav-profile">
                    //         <img
                    //             data-testid="nav-prof-img"
                    //             className="image btn nav-dropdown-toggle"
                    //             src={
                    //                 profile && profile.urls && profile.urls.length > 0
                    //                     ? profile.urls[0].uploaded_file
                    //                     : "/profile.png"
                    //             }
                    //             alt="profile"
                    //             onClick={() => setDropVis(!dropVis)}
                    //         />
                    //     </div>
                    // </>
                    <Nav.Link className="no-profile"
                    >
                        <div onClick={()=> {logout(); window.location.href= window.location.href}}>خروج از حساب-{localStorage.getItem("username")}</div>
                    </Nav.Link>
                )}
            </Nav>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="m-auto">
                    <Nav.Link href="#">دربارۀ ما</Nav.Link>
                    <Nav.Link href="/pvqa/dataset/guideline">راهنمای دیتاست</Nav.Link>

                    <Nav.Link href="/pvqa/blog">بلاگ‌پست</Nav.Link>
                    <Nav.Link href="/pvqa/dataset/statistics">آمار دیتاست</Nav.Link>
                    <Nav.Link href="/pvqa/dataset/view">بازبینی دیتاست</Nav.Link>
                    <Nav.Link href="/pvqa/dataset">جمع‌آوری دیتاست</Nav.Link>
                    <Nav.Link href="/pvqa/demo">دمو</Nav.Link>
                    <Nav.Link href="/pvqa">صفحه اصلی</Nav.Link>

                </Nav>
            </Navbar.Collapse>
            <Navbar.Brand className="mr-auto">
                <div
                    className="btn"
                    data-testid="nav-theme-toggle"
                    onClick={() => props.setMode(props.theme[0] === "l" ? "d" : "l")}
                >
                    {" "}
                    {props.theme[0] === "l" ? <Brightness4Icon /> : <Brightness5Icon />}
                </div>
                <a href="/">PersianVQA</a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {/* </div> */}
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            {/* <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav"> */}
            {/* </Navbar.Collapse> */}
        </Navbar>
    );
}

export default CustomNavbar;
