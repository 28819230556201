import { useHistory } from "react-router-dom";
import React from "react";
import "./ISTJ.style.scss";
import istjPic from "../../../assets/images/types/istj/ISTJ.jpg";

import Squidward from "../../../assets/images/types/istj/istj1.png";
import Hermione from "../../../assets/images/types/istj/istj2.png";
import Kendall from "../../../assets/images/types/istj/istj3.png";
import Mikasa from "../../../assets/images/types/istj/istj4.png";
import Sheldon from "../../../assets/images/types/istj/istj5.png";
import Ross from "../../../assets/images/types/istj/istj6.png";
import Sigmund from "../../../assets/images/types/istj/istj7.png";
import Kim from "../../../assets/images/types/istj/istj8.png";
import Irene from "../../../assets/images/types/istj/istj9.png";
import Mike from "../../../assets/images/types/istj/istj10.png";
// import { useNavigate } from 'react-router-dom';

const ISTJ = () => {
  const history = useHistory();
  //   const navigate = useNavigate();
  return (
    <div className="back-istj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={istjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ISTJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ISTJ: </b>
              جدی و آرام می‌باشد و موفقیت را با تمرکز و پشتکار به‌دست‌می‌آورد.
              واقع‌گرا، منظم و منطقی می‌باشد. قابل اطمینان است و همیشه می‌توان
              روی او حساب کرد. مایل است همه‌چیز منظم و مرتب باشد. مسئولیت‌پذیر
              است. افکارش را روی کاری که باید انجام دهد معطوف می‌کند و به‌شدت در
              جهت رسیدن به آن هدف گام برمی‌دارد. این کار را بدون توجه به معترضین
              و کسانی که سعی در ایجاد اختلال در کارش را داشته باشد انجام می‌دهد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ISTJ:</div>
            <div className="jobs">
              حسابدار، مدیر مدرسه و معلم، بازرس، بانک‌دار ، کارگر خدمات نظافتی،
              برنامه‌نویس کامپیوتر، کارگر ساختمان، پیمان‌کار، دندان‌پزشک و پزشک،
              کارآگاه، برق‌کار، کارمندِ دولت، نمایندهٔ بیمه، حقوق‌دان،
              کتاب‌خانه‌دار، تکنسین پزشکی، متخصصِ چشم، داروخانه‌دار، افسرِ پلیس،
              تکنسین
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>در به‌خاطرآوردن حقایق و واقعیت‌ها عالی هستند.</li>
              <li>
                برای دقت اهمیت فراوانی قابل هستند. دقت می‌کنند که چیزی فراموش
                نشود. به‌شدت به جزئیات توجه دارند. کارکنانی سخت‌کوش هستند.
              </li>
              <li>
                با ماشین‌ها، واقعیت‌ها، حقایق و اعداد ارقام بهتر از دیگران کار
                می‌کنند.
              </li>
              <li>از به‌تنهایی کار کردن لذت می‌برند.</li>
              <li>از پرت‌شدن حواسشان ناراحت می‌شوند.</li>
              <li>
                دوست دارند مقررات رعایت شود و کسانی را که از این قاعده منحرف
                شوند را تحمل نمی‌کنند.
              </li>
              <li>تمایل دارند که سخت‌گیر و بی‌انعطاف باشند.</li>
              <li>
                از مشاغلی که در آن‌ها لباس، محیط اطراف و ظواهر امر اهمیت ندارد،
                دوری می‌گزینند.
              </li>
              <li>از بی‌حالی و رخوت و نابسامانی خوششان نمی‌آیند.</li>
              <li>
                با سخت‌کوشی، به‌دست آوردن مدارک تخصصی و احترام به سازمان، پیشرفت
                می‌کنند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ISTJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Squidward} alt="Squidward Tentacles" />
                  <div className="intru">
                    <div className="name">Squidward Tentacles</div>
                    <div className="role">باب اسفنجی (1999)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Hermione} alt="Hermione Granger" />
                  <div className="intru">
                    <div className="name">Hermione Granger</div>
                    <div className="role">سری داستان‌های هری پاتر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kendall} alt="Kendall Jenner" />
                  <div className="intru">
                    <div className="name">Kendall Jenner</div>
                    <div className="role">مدل</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Mikasa} alt="Mikasa Ackerman" />
                  <div className="intru">
                    <div className="name">Mikasa Ackerman</div>
                    <div className="role">Attack on Titan</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Sheldon} alt="Sheldon Cooper" />
                  <div className="intru">
                    <div className="name">Sheldon Cooper</div>
                    <div className="role">The Big Bang Theory (2007)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Ross} alt="Ross Geller" />
                  <div className="intru">
                    <div className="name">Ross Geller</div>
                    <div className="role">Friends (1994)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Sigmund} alt="Sigmund Freud" />
                  <div className="intru">
                    <div className="name">Sigmund Freud</div>
                    <div className="role">عصب‌شناس</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kim} alt="Kim Kardashian" />
                  <div className="intru">
                    <div className="name">Kim Kardashian</div>
                    <div className="role">مدل</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Irene} alt="Irene (Red Velvet)" />
                  <div className="intru">
                    <div className="name">Irene (Red Velvet)</div>
                    <div className="role">خوانندهٔ k-pop</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Mike} alt="Mike Ehrmantraut" />
                  <div className="intru">
                    <div className="name">Mike Ehrmantraut</div>
                    <div className="role">Breaking Bad (2008)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ISTJ;
