import React, { useState, useMemo, useEffect } from "react";
import "./Get Dataset/DatasetList.css";
import Pagination from "./Get Dataset/Pagination";
import { MediaUrl, ServerUrl } from "utils/Constants";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

function DatasetStatistics(props) {
  const [currentTableData, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [scenario, setScenario] = useState("scenario3");
  const dropdownOptions = {
    "scenario1": "سناریو 1",
    "scenario2": "سناریو 2",
    "scenario3": "سناریو 3",
  };
  const SearchDropDownToggle = () => {
    document.getElementById("shop-search-dropdown").classList.toggle("show");
  };
  const history = useHistory();

  function throwError(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    //CHECK ACCESS ---- UNCOMMENT LATER
    fetch(`${ServerUrl}/dataset/check-access`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
    })
      .then((r) => r.json())
      .then((r) => {
        if (!r.access_dataset)
            window.location.href = "/pvqa";
      })
      .catch((err) => {
        window.location.href = "/pvqa";
      });
  }, []);

  useEffect(() => {
    console.log(localStorage.getItem("loginToken"))
    // const firstPageIndex = (currentPage - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;

    //fetch new data
    let data = {}
    // var data = {
    //   data: [
    //     {
    //       user: {first_name:"یه کسی", last_name:"با یه فامیلی ای", username: "somebody", id: 1 },
    //       captions: 0,
    //       "no_captions": 100
    //     }
    //   ],
    //   sum: {
    //     captions: 0,
    //     "no_captions": 100
    //   },
    // };
    // console.log(data);
    // setData(data);

    switch(scenario){
      case "scenario3":
        fetch(`${ServerUrl}/user/stats-sc3/`, {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("loginToken"),
        },
      })
        .then((res) => (res.ok ? res.json() : res.status === 401 ? 401 : null))
        .then((res) => {
          if (res === 401) window.location.href = "/pvqa/login";
          else if (!res) {
            throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
            return;
          }
          data = res;
          console.log(data);
          setData(data);
        })
        .catch((err) => {
          throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
          console.log(err);
        });
      break;
      case "scenario2":
        fetch(`${ServerUrl}/user/stats-sc2/`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("loginToken"),
          },
        })
          .then((res) => (res.ok ? res.json() : res.status === 401 ? 401 : null))
          .then((res) => {
            if (res === 401) window.location.href = "/pvqa/login";
            else if (!res) {
              throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
              return;
            }
            data = res;
            console.log(data);
            fetch(`${ServerUrl}/dataset/unanswered-vqa-count/`, {
              method: "GET",
              headers: {
                Authorization: "Token " + localStorage.getItem("loginToken"),
              },
            })
              .then((res) => (res.ok ? res.json() : res.status === 401 ? 401 : null))
              .then((res) => {
                if (res === 401) window.location.href = "/pvqa/login";
                else if (!res) {
                  throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
                  return;
                }
                data["unanswered_image_count"] = res.count;
                console.log(data);
                setData(data);
              })
              .catch((err) => {
                throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
                console.log(err);
              });
          })
          .catch((err) => {
            throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
            console.log(err);
          });
      break;
      default: //scenario1
    }
    // fetch(`${ServerUrl}/user/stats/`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: "Token " + localStorage.getItem("loginToken"),
    //   },
    // })
    //   .then((res) => (res.ok ? res.json() : res.status === 401 ? 401 : null))
    //   .then((res) => {
    //     if (res === 401) window.location.href = "/pvqa/login";
    //     else if (!res) {
    //       throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
    //       return;
    //     }
    //     data = res;
    //     console.log(data);
    //     setData(data);
    //   })
    //   .catch((err) => {
    //     throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
    //     console.log(err);
    //   });

  }, [scenario]);
  document.title = "آمار دیتاست | PersianVQA";
  return (
    <div className="dataset-page">
      <div style={{display:'flex',justifyContent:'flex-end'}}>
        <Button onClick={()=>history.push('statistics/categories')}> مشاهده آمار دسته بندی ها </Button>
      </div>
      <div className="dataset-page-inner">
        <div className="page-title-description" style={{ marginBottom: "5vh" }}>
          <h1>آمار دیتاست</h1>
        </div>
        <div
          className="dropdown"
          style={{ width: "fit-content", margin: "auto" }}
        >
          <button
            className="btn dropdown-toggle input-group-btn"
            data-testid="shop-filterby-button"
            onClick={(e) => {
              e.preventDefault();
              SearchDropDownToggle();
            }}
          >
            {dropdownOptions[scenario]}
          </button>
          <div className="dropdown-menu" id="shop-search-dropdown">
            {Object.entries(dropdownOptions).map(([t, label], i) => (
              <div
                key={i}
                className="dropdown-item"
                onClick={() => {
                  setScenario(t);
                }}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
        {scenario == "scenario1"?
        <div className="dataset-list">
          {/* <table>
            <thead>
              <tr>
                <th>نام کاربری</th>
                <th>تعداد سوالات تولید شده</th>
                <th>تعداد پاسخ‌های سوالات الگویی</th>
                <th>تعداد پاسخ‌های سوالات غیرالگویی</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData?.data?.map((item) => {
                return (
                  <tr key={item.username} className="main-row">
                    <td className="cell">
                      {item.first_name
                        ? `${item.first_name} ${item.last_name}`
                        : `${item.username}`}
                    </td>
                    <td className="cell">{item.captions}</td>
                    <td className="cell">{item.no_captions}</td>
                  </tr>
                );
              })}
              <tr className="main-row sum-row">
                <td className="cell">مجموع</td>
                <td className="cell">{currentTableData?.total_questions}</td>
                <td className="cell">
                  {currentTableData?.total_from_template_answers}
                </td>
                <td className="cell">
                  {currentTableData?.total_not_from_template_answers}
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
        : scenario == "scenario2"?
        <div className="dataset-list">
          <table>
            <thead>
              <tr>
                <th>نام کاربری</th>
                <th>تعداد تصاویری که برایشان پرسش و پاسخ شده</th>
                <th>تعداد پرسش و پاسخ های وارد شده</th>
                <th>تعداد تصاویر بدون پرسش و پاسخ</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData?.users_stats?.map((item) => {
                return (
                  <tr key={item.username} className="main-row">
                    <td className="cell">
                      {item.first_name
                        ? `${item.first_name} ${item.last_name}`
                        : `${item.username}`}
                    </td>
                    <td className="cell">{item.vqa_images_count}</td>
                    <td className="cell">{item.vqa_questions_count}</td>
                    <td className="cell">
                      -
                    </td>
                  </tr>
                );
              })}
              <tr className="main-row sum-row">
                <td className="cell">مجموع</td>
                <td className="cell">
                  {currentTableData?.vqa_images_total}
                </td>
                <td className="cell">
                  {currentTableData?.vqa_questions_total}
                </td>
                <td className="cell">{currentTableData?.unanswered_image_count}</td>
              </tr>
            </tbody>
          </table>
        </div>
        : //scenario3
        <div className="dataset-list">
          <table>
            <thead>
              <tr>
                <th>نام کاربری</th>
                <th>تعداد کپشن‌های وارد شده</th>
                <th>تعداد کپشن‌های باقی‌مانده</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData?.users_stats?.map((item) => {
                return (
                  <tr key={item.username} className="main-row">
                    <td className="cell">
                      {item.first_name
                        ? `${item.first_name} ${item.last_name}`
                        : `${item.username}`}
                    </td>
                    <td className="cell">{item.captions_count}</td>
                    <td className="cell">{item.unanswered_captions_count}</td>
                  </tr>
                );
              })}
              <tr className="main-row sum-row">
                <td className="cell">مجموع</td>
                <td className="cell">{currentTableData?.captions_total}</td>
                <td className="cell">
                  {currentTableData?.unanswered_total}
                </td>
              </tr>
            </tbody>
          </table>
          {/* <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={currentTableData?.data?.length ? currentTableData?.data?.length : 10}
                pageSize={currentTableData?.data?.length? currentTableData?.data?.length : 0}
                onPageChange={page => setCurrentPage(page)}
            /> */}
        </div>
        }
      </div>
    </div>
  );
}

export default DatasetStatistics;
