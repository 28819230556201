import { useHistory } from "react-router-dom";
import React from "react";
import "./INFJ.style.scss";
import infjPic from "../../../assets/images/types/infj/INFJ.jpg";
import Rowling from "../../../assets/images/types/infj/infj1.png";
import Armin from "../../../assets/images/types/infj/infj2.png";
import Carl from "../../../assets/images/types/infj/infj3.png";
import Dumbledore from "../../../assets/images/types/infj/infj4.png";
import Dostoevsky from "../../../assets/images/types/infj/infj5.png";
import Edward from "../../../assets/images/types/infj/infj6.png";
import Tsunemori from "../../../assets/images/types/infj/infj7.png";
import Blanchett from "../../../assets/images/types/infj/infj8.png";
import Chomsky from "../../../assets/images/types/infj/infj9.png";
import Gatsby from "../../../assets/images/types/infj/infj10.png";
// import { useNavigate } from 'react-router-dom';

const INFJ = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  return (
    <div className="back-infj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={infjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> INFJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد INFJ: </b>
              موفقیت را با پشتکار فراوان به‌دست‌می‌آورد. طرح ایده‌های جدید
              می‌نماید و اشتیاق در انجام هرکاری که موردنیاز است از خود نشان
              می‌دهد. بیش‌ترین تلاش خود را در کارها می‌نماید. آرام باقدرت و
              وظیفه‌شناس است. علاقه به کمک به دیگران دارد، اصولِ اخلاقی که به آن
              پایبند می‌باشد، برای اغلب مردم مورد احترام است. همیشه از طرف مردم
              جهت خدمت به دیگران و جامعه از او قدردانی می‌شود.
            </div>
            <div className="jobstitle">شغل‌های مناسب یک INFJ:</div>
            <div className="jobs">
              معمار، روحانی، مشاور، طراح، متخصصِ تغذیه، ویراستار، مسئول
              مراقبت‌های بهداشتی، مفسر، مترجم، کتاب‌خانه‌دار، پزشک، موسقی‌دان/
              آهنگساز، فیلسوف، طراحِ برنامه، روان‌درمانگر، معلم دینی، پژوهش‌گر،
              دانشمند اجتماعی، نویسنده
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>به‌شدت بکر و خلاق‌اند.</li>
              <li>
                برای دست‌یابی به هدف‌های بلند‌مدت خود کار می‌کنند. می‌خواهند
                ببینند که نقطه نظر‌هایشان صورت تحقق پیدا می‌کند و به کار
                گرفته‌می‌شود.
              </li>
              <li>
                می‌توانند از خود و دیگران به شدت متوقع باشند. اغلب کمال‌طلب
                هستند.
              </li>
              <li>در دفاع از ارزش‌های و باورهای خود بسیار راسخ هستند.</li>
              <li>از ایده‌آل‌ها و هدف‌های‌شان حمایت می‌کنند.</li>
              <li>اغلب به رفاه مردم کمک می‌کنند.</li>
              <li>ناکامی‌ها را مسائلی می‌دانند که باید حل‌وفصل شوند.</li>
              <li>در استفاده از کلمات بسیار دقیق هستند.</li>
              <li>
                درباره مسائل به طور دقیق فکر می‌کنند. در تمرکزکردن قوی هستند. از
                پرت‌شدن حواس خوششان نمی‌آید، ترجیح می‌دهد در مکان‌هایی بی‌سروصدا
                و سازمان‌یافته کار کنند.
              </li>
              <li>
                خواهان تحسین و احترام هستند اما دوست ندارند که توجه دیگران را به
                خود جلب کنند.
              </li>
              <li>می‌توانند رهبرانی مجاب‌کننده و الهام‌بخش باشند.</li>
              <li>
                دوست دارند اوقات خود را سازمان‌دهی کنند و روی فرایند محصول کنترل
                داشته‌باشند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b>INFJ</b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Rowling} alt="J. K. Rowling" />
                  <div className="intru">
                    <div className="name">J. K. Rowling</div>
                    <div className="role">نویسنده</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Armin} alt="Armin Arlert" />
                  <div className="intru">
                    <div className="name">Armin Arlert</div>
                    <div className="role">Attack on Titan </div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Carl} alt="Carl Jung" />
                  <div className="intru">
                    <div className="name">Carl Jung</div>
                    <div className="role">روانشناس</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Dumbledore} alt="Dumbledore" />
                  <div className="intru">
                    <div className="name">Albus Dumbledore</div>
                    <div className="role">Harry Potter (2007)</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Dostoevsky} alt="Fyodor Dostoevsky" />
                  <div className="intru">
                    <div className="name">Fyodor Dostoevsky</div>
                    <div className="role">نویسنده</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Edward} alt="Edward Cullen" />
                  <div className="intru">
                    <div className="name">Edward Cullen</div>
                    <div className="role">The Twilight Saga (2008)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Tsunemori} alt="Akane Tsunemori" />
                  <div className="intru">
                    <div className="name">Akane Tsunemori</div>
                    <div className="role">Psycho-Pass</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Blanchett} alt="Cate Blanchett" />
                  <div className="intru">
                    <div className="name">Cate Blanchett</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Chomsky} alt="Noam Chomsky" />
                  <div className="intru">
                    <div className="name">Noam Chomsky</div>
                    <div className="role">فیلسوف</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Gatsby} alt="Gatsby" />
                  <div className="intru">
                    <div className="name">Jay Gatsby</div>
                    <div className="role">The Great Gatsby (2013)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default INFJ;
