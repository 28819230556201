import { useHistory } from "react-router-dom";
import React from "react";
import "./ISFP.style.scss";
import isfpPic from "../../../assets/images/types/isfp/ISFP.jpg";
import ali from "../../../assets/images/types/isfp/isfp1.png";
import Harry from "../../../assets/images/types/isfp/isfp2.png";
import Lana from "../../../assets/images/types/isfp/isfp3.png";
import gaga from "../../../assets/images/types/isfp/isfp4.png";
import Audrey from "../../../assets/images/types/isfp/isfp5.png";
import Jon from "../../../assets/images/types/isfp/isfp6.png";
import Michael from "../../../assets/images/types/isfp/isfp7.png";
import Rihanna from "../../../assets/images/types/isfp/isfp8.png";
import Eren from "../../../assets/images/types/isfp/isfp9.png";
import Marilyn from "../../../assets/images/types/isfp/isfp10.png";
// import { useNavigate } from 'react-router-dom';

const ISFP = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-isfp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={isfpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ISFP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ISFP: </b>
              خستگی‌ناپذیر، خوش برخورد، حساس و کم ادعا در مورد توانایی‌های خود
              می‌باشد و از مخالفت پرهیز می‌کند. ارزش ها یا افکار خود را بر
              دیگران تحمیل نمی‌کند. معمولا علاقه‌ای به مدیریت و رهبری ندارد. اما
              اغلب دنباله‌روی بسیار وفاداری می‌باشد. در مورد انجام مسئولیت‌ها یا
              تمام‌کردن وظایف روحیه‌ای توأم با آرامش و راحتی دارد. از زمان حال
              لذت می‌برد و نمی‌خواهد آن را با تقلا و عجلهٔ بی‌مورد خراب نماید.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ISFP:</div>
            <div className="jobs">
              هنرمندِ نقاش، آرایشگر، گیاه‌شناس، نجار، منشی، کارمند، اپراتورِ
              کامپیوتر، مشاور، رقصنده، دستیارِ دندان‌پزشک و پزشک، طراح، متخصصِ
              تغذیه و رژیم غذایی، کارگرِ کارخانه، کارگرِ خدمات‌غذایی، جنگل‌بان،
              باغبان، جغرافی‌دان، زیست‌شناس دریایی، مکانیک، پرستار، چشم‌پزشک،
              افسرِ پلیس، دام‌پزشک، دستیارِ دام‌پزشک
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                کمال‌طلب و آرمان‌گرا هستند. دوست دارند کارهای رضایت‌بخش انجام
                بدهند به شرط آن که با ارزش‌های آن‌ها سازگار باشد.
              </li>
              <li>
                کارکنانی همکار و صمیمی هستند. اگر به کاری که می‌کنند اعتقاد
                فراوان داشته‌باشند، بسیار پرکار نشان می‌دهند.
              </li>
              <li>
                دوست دارند مفید باشند و از کمک‌کردن به دیگران برای افزودن بر
                مهارت‌های علمی لذت می‌برند.
              </li>
              <li>
                انعطاف‌پذیر و سازگار هستند. دوست دارند با اشخاص به شکل تعاونی و
                همکاری کار کنند.
              </li>
              <li>
                در معرضِ قوانین بیش از اندازه احساس محدودیت می‌کنند. از
                ساختارهای انعطاف‌پذیر دل خوشی ندارد.
              </li>
              <li>
                از محیط‌هایی که در آن تعرض و تضاد میان شخص وجود نداشته‌باشد
                استقبال می‌کنند.
              </li>
              <li>به‌آن‌چه در لحظه به آن احتیاج دارند، توجه می‌کنند.</li>
              <li>
                نیازی به کنترل‌کردن و رقابت‌کردن احساس نمی‌کنند. ترجیح می‌دهند
                که در پشت صحنه کار کنند.
              </li>
              <li>می‌توانند در محیطِ کار خود لذت و شادابی را وارد کنند.</li>
              <li>
                درشرایط اضطراری به خوبی عمل می‌کنند و با خود مهربانی را به محیط
                می‌آورند.
              </li>
              <li>
                به‌خاطر نداشتن قاطعیت، نداشتن سمت و جهت و انگیزه کلافه و ناراحت
                می‌شوند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ISFP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={ali} alt="Ali Abdul  / No. 199" />
                  <div className="intru">
                    <div className="name">Ali Abdul/No.199</div>
                    <div className="role">Squid Game (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Harry} alt="Harry Potter" />
                  <div className="intru">
                    <div className="name">Harry Potter</div>
                    <div className="role">Harry Potter (2007)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Lana} alt="Lana Del Rey" />
                  <div className="intru">
                    <div className="name">Lana Del Rey</div>
                    <div className="role">خوانندهٔ راک</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={gaga} alt="Lady Gaga" />
                  <div className="intru">
                    <div className="name">Lady Gaga</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Audrey} alt="Audrey Hepburn" />
                  <div className="intru">
                    <div className="name">Audrey Hepburn</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Jon} alt="Jon Snow" />
                  <div className="intru">
                    <div className="name">Jon Snow</div>
                    <div className="role">Game of Thrones (2011)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Michael} alt="Michael Jackson" />
                  <div className="intru">
                    <div className="name">Michael Jackson</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Rihanna} alt="Rihanna" />
                  <div className="intru">
                    <div className="name">Rihanna</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Eren} alt="Eren Yeager" />
                  <div className="intru">
                    <div className="name">Eren Yeager</div>
                    <div className="role">Attack on Titan</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Marilyn} alt="Marilyn Monroe" />
                  <div className="intru">
                    <div className="name">Marilyn Monroe</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ISFP;
