export const __RoutePath = {
    Start: {
        MbtiInfo: "/Info",
    },

    Twitter: {
        GetId: "/twitter",
    },

    Questionnaire: {
        Question: "/questionnaire"
    },

    UserInfo: {
        Form: "/userinfo"
    },

    Types: {
        ISTJ: "/ISTJ",
        ISTP: "/ISTP",
        ISFJ: "/ISFJ",
        ISFP: "/ISFP",
        INFJ: "/INFJ",
        INFP: "/INFP",
        INTJ: "/INTJ",
        INTP: "/INTP",
        ESTP: "/ESTP",
        ESTJ: "/ESTJ",
        ESFP: "/ESFP",
        ESFJ: "/ESFJ",
        ENFP: "/ENFP",
        ENFJ: "/ENFJ",
        ENTP: "/ENTP",
        ENTJ: "/ENTJ"

    },
};