import React from "react";
import "./style.scss";
import Example1 from "./images/caption-example1.jpg";

export default function DatasetGuideLinePage() {
  return (
    <div className="dataset-guideline" dir="rtl">
      <h1>راهنمای جمع‌آوری دیتاست برای برچسب‌زنان</h1>
      <p className="guide">
        <h2>نکات کلی</h2>
        <ol>
          <li>
            <div>
            از خاموش بودن VPN یا فیلترشکن اطمینان حاصل کنید.
            </div>
          </li>
          <li>
            <div>
توجه داشته باشید کلماتی که هم صورت چسبیده هم جدای آن‌ها رایج است (مانند "اینطور" و "این طور") را به شکل جدا از هم بنویسید. (در این مثال "این طور" خواستهٔ ماست.) اگر می‌دانید کلمه‌ای با نیم‌فاصله است (مانند "می‌شود") در صورت امکان آن را با نیم‌فاصله بنویسید.
            </div>
          </li>
        </ol>
        <h2>نکات جمع آوری دیتاست پرسش و پاسخ تصویری</h2>
        <h3>سناریو ۱: نوشتن پاسخ برای سوالات از پیش تولید شده</h3>
        <div>
          در هنگام پاسخ به سوالات لطفاً نکات زیر را مد نظر داشته باشید.
        </div>
        <ol>
          <li>
            <div>
              به تمامی سوالات، پاسخ کوتاه دهید. لازم به نوشتن یک جمله نیست.
            </div>
          </li>
          <li>
            <div>
              با توجه به اینکه پرسش&zwnj;ها به صورت خودکار تولید شده&zwnj;اند؛
              ممکن است در برخی موارد سوالات دچار مشکل باشند و معنا و مفهوم درستی
              نداشته باشند. زمانی که با چنین پرسش&zwnj;هایی مواجه شدید، گزینه
              &laquo;اشکال در پرسش&raquo; را انتخاب کنید.
            </div>
          </li>
          <li>
            <div>
              سوالاتی که در مورد حضور اشیا یا موجودات در تصویر است؛ پاسخ بله/خیر
              یا آره/نه بدهید. مانند:
            </div>
          </li>

          <div>سوال: آیا در تصویر گلدان وجود دارد؟&nbsp;</div>
          <div>پاسخ: بله</div>

          <div>سوال: گربه بالای میزه؟</div>
          <div>پاسخ: نه</div>

          <li>
            <div>
              برای پاسخ به سوالاتی که در مورد نوع ورزش است؛ کافی است نام آن ورزش
              را به عنوان پاسخ وارد کنید. مثال:
            </div>
          </li>

          <div>سوال: چه ورزشی انجام می&zwnj;شود؟</div>
          <div>پاسخ: فوتبال</div>

          <li>
            <div>
              به دو روش می&zwnj;توان به سوالاتی که در مورد مکان اشیا یا موجودات
              است پاسخ داد. در روش اول موقعیت شی/موجود را نسبت به خود تصویر با
              جهات مختلف(سمت راست، سمت چپ، بالا، پایین، وسط و ... ) بیان کنید.
              مثال:
            </div>
          </li>

          <div>سوال: کاسه کجای تصویره؟</div>
          <div>پاسخ: سمت راست</div>

          <div>
            در روش دوم موقعیت شی/ موجود را نسبت به سایر اجزای موجود در تصویر
            بیان کنید. مانند:
          </div>

          <div>سوال: در این تصویر کاسه کجا دیده میشه:</div>
          <div>پاسخ: روی میز</div>

          <li>
            <div>
              در پاسخ به سوالاتی که در مورد نوع احساس شخص است؛ از یک کلمه که
              احساس شخص را توصیف می&zwnj;کند استفاده کنید. مانند عصبانی، ناراحت،
              خوشحال، هیجان&zwnj;زده و ...
            </div>
          </li>
          <li>
            <div>
              برخی از پرسش&zwnj;ها در مورد رنگ اشیا است. برای پاسخ به چنین
              سوالاتی از یک یا حداکثر دو رنگ اصلی برای توصیف شی استفاده کنید.
              دقت کنید که ممکن است شی مورد پرسش دارای رنگ&zwnj;های مختلفی باشد.
              شما رنگ غالب را به عنوان جواب در نظر بگیرید. نیاز به مشخص کردن
              کمرنگ بودن یا پررنگ بودن رنگ و ... نیست. مثلا پاسخ سبز کافی است و
              نیاز به اینکه شما مشخص کنید که این سبز پسته&zwnj;ای است یا لجنی
              نیست. مثال:
            </div>
          </li>

          <div>سوال: اتوبوس چه رنگی است؟</div>
          <div>پاسخ: قرمز</div>

          <li>
            <div>
              ممکن است با پرسش&zwnj;هایی مواجه شوید که در مورد تعداد یک شی یا یک
              موجود در تصویر باشد. برای پاسخ به این سوالات با دقت تعداد شی یا
              موجود مورد نظر را بشمارید و عدد آن را به صورت حروف وارد کنید. اگر
              تعداد شی یا موجود قابل شمارش نبود به جای آن کلمه
              &laquo;زیاد&raquo; را به عنوان پاسخ وارد کنید. مثال:&nbsp;
            </div>
          </li>

          <div>سوال: چه تعداد سیب در شکل دیده می&zwnj;شود؟</div>
          <div>پاسخ: دو</div>

          <li>
            <div>
              سوالاتی وجود دارد که شما باید مشخص کنید چه فعالیتی یا چه اتفاقی
              توسط جاندار مد نظر در حال وقوع است. برای پاسخ به این سوالات از یک
              اسم یا مصدر یک فعل استفاده کنید. مثال:
            </div>
          </li>

          <div>
            سوال: شخص در حال انجام چه فعالیتی است؟
            <br />
            &nbsp;پاسخ: سخنرانی
          </div>

          <div>&nbsp;</div>

          <div>سوال: شخص مشغول چه کاری است؟</div>
          <div>پاسخ: خوردن</div>

          <li>
            <div>
              بعضی از سوالات از شما در مورد اینکه چه نوع شی یا اشیایی در تصویر
              وجود دارد می&zwnj;پرسد. در پاسخ به این سوالات نام یک یا حداکثر دو
              شی شاخص در تصویر را وارد کنید. مثال:
            </div>
          </li>

          <div>سوال: چه چیزی در تصویر مشاهده می&zwnj;شود؟</div>
          <div>پاسخ: موتور سیکلت و ماشین پلیس</div>

          <li>
            <div>
              سوالاتی که در مورد جنس اشیا است را با یک کلمه مناسب پاسخ دهید.
              مانند:
            </div>
          </li>

          <div>سوال: جنس میز غذاخوری چی هست؟</div>
          <div>پاسخ: چوبی</div>

          <li>
            <div>
              به سوالاتی که در مورد جنسیت شخص موجود در تصویر است با کلماتی مانند
              زن/مرد و مونث/مذکر و &nbsp;دختر/پسر و خانم/آقا پاسخ دهید.
            </div>
          </li>
        </ol>
        <h3>سناریو ۲: نوشتن پرسش و پاسخ‌های کوتاه و بلند</h3>
        <div>
        در این قسمت نیاز است برای هر تصویر حداقل سه پرسش با پاسخ‌های کوتاه و بلند بنویسید.
        </div>
        <div>
        حتما اولین پرسش و پاسخ به صورت غیرمحاوره‌ای یا رسمی و دومین پرسش و پاسخ محاوره‌ای باشد. همچنین نمی‌توان پرسش‌های مطابق با الگوها را برای این دو وارد کرد و حتما این دو پرسش، سخت و چالش‌برانگیز باشند.
        </div>
        <div>
        پرسش سوم از نظر مشابهت با الگوها و همچنین سختی سوال محدودیتی ندارد اما باید رسمی و نوشتاری  و تا جای ممکن مرتبط با فرهنگ ایرانی باشد. 
        </div>
        <div>
          هر پرسشی را که اضافه بر این سه وارد می‌کنید (پرسش‌های ۴ به بعد) 
          نباید مطابق با الگوها باشد، اما نوشتاری یا محاوره‌ای بودن آن را خود شما انتخاب می‌کنید. این پرسش‌ها باید همانند دو مورد اول سخت و چالش‌برانگیز باشند.
        </div>
        <ol></ol>
        <div>
          در نوشتن سوالات نکات زیر را رعایت کنید:
        </div>
        <ol>
          <li>
            <div>
            پرسش‌ها (به جز پرسش سوم) به گونه‌ای باشد که یک ربات باهوش را فریب دهد و پاسخ دادن به آن سخت و چالش‌برانگیز باشد.
            </div>
          </li>
          <li>
            <div>
            هدف از جمع‌آوری پرسش و پاسخ سوم سختی آن نیست بلکه اشاره به فرهنگ ایرانی‌ست.
          بدین ترتیب تا حد امکان
         به نکات مربوط به فرهنگ ایرانی و موضوعات یا چیزهایی که در خارج از ایران رایج نیست اشاره شود، به طور مثال حجاب، غذا و آداب و رسوم ایرانی.
          </div>
          <div>
            این پرسش و پاسخ باید تا جای ممکن به شکلی باشد که شخصی که با ایران آشنایی ندارد به سختی بتواند به آن جواب دهد، اما جواب دادن به آن غیر ممکن نباشد. (مثلا پرسش درباره نام خاص  یک فرد یا مکان نباشد.)
         </div>
          </li>
          <li>
            <div>
            برای پاسخ دادن به پرسش‌های تولیدشده نیازی به داشتن دانش خارجی نباشد. اطلاعاتی به غیر از اطلاعات و مفاهیمی که انسان با نگاه کردن به تصویر و خواندن پرسش متوجه می‌شود، لازم نباشد. برای مثال سوال در مورد یک تصویر اینگونه پرسیده نشود که «این تصویر مربوط به دهه‌ی 60 است یا دهه‌ی 50؟» زیرا برای پاسخ به این سوال باید فرد دانش خارجی در مورد نوع مد لباس، وسایل نقلیه و ...  در هر دهه داشته باشد تا بتواند تشخیص دهد که تصویر مربوط به کدام دهه است.
            </div>
          </li>
          <li>
            <div>
            پرسش‌های هر تصویر باید به گونه‌ای طرح شود که پاسخ آن نسبت به پاسخ سایر پرسش‌های آن تصویر متفاوت باشد.
            </div>
          </li>
          <li>
            <div>
            پرسش ها باید به صورت بدون ابهام مطرح شوند و دارای یک پاسخ مشخص داشته باشد به طوری که اگر آن سوال را از یک فرد دیگر در مورد تصویر بپرسیم دقیقا همان پاسخ مد نظر را بدهد.
            </div>
          </li>
          <li>
            <div>
            پرسش‌‌ها نباید به گونه‌ای باشند که پاسخ آن‌ها تنها به اطلاعات تصویر و یا تنها به اطلاعات پرسش نیاز داشته باشد. بلکه باید از مجموع اطلاعاتی که از تصویر و پرسش دریافت می‌شود؛ پاسخ سوال داده شود.
            </div>
          </li>
        </ol>
        <div>
          برای نوشتن پاسخ کوتاه به نکات نوشته شده در بخش قبلی (یعنی سناریو ۱: نوشتن پاسخ برای سوالات از پیش تولید شده) حتما توجه کنید.
        </div>
        <ol></ol>
        <div>
          در نوشتن پاسخ بلند نکات زیر را رعایت کنید:
        </div>
        <ol>
          <li>
            <div>
            با توجه به پاسخ کوتاهی که وارد کرده‌اید؛ آن را در قالب یک جمله(یعنی دارای فاعل، مفعول، فعل و سایر ملزومات یک جمله در زبان فارسی) بازنویسی کنید.
            </div>
          </li>
          <li>
            <div>
            بهتر است برای تولید پاسخ بلند از همان کلمات صورت سوال مجدد استفاده نشود. سعی کنید پاسخ بلند را با لحن و زبان دیگری بنویسید. به زبان ساده‌تر، برای تولید پاسخ بلند اینطور نباشد که صورت سوال را از حالت پرسشی به یک جمله خبری تبدیل کنید.
            </div>
          </li>
        </ol>
        <h2>جمع‌آوری متن توصیف تصویر</h2>
        <h3>سناریو ۳: وارد کردن متن (caption) برای تصویر</h3>
        <ol>
          <li>
            <div>
              متن باید حداقل ۸ کلمه داشته باشد.
            </div>
          </li>
          <li>
            <div>
              متن باید توصیف‌کننده منظره، رویداد، فعالیت و موجودات درون تصویر (اشخاص، جانداران دیگر و اشیا) باشد. 
            </div>
          </li>
          <li>
            <div>
            از نوشتن جزئیات ریز تصویر خودداری کنید.
            </div>
          </li>
          <li>
            <div>
            متن حتما به صورت نوشتاری باشد و از به کار بردن کلمات محاوره‌ای خودداری کنید.
            </div>
          </li>
          <li>
            <div>
              متن تک جمله‌ای باشد.
            </div>
          </li>
          <li>
            <div>
              از نوشتن کلمات یا صفات سلیقه‌ای خودداری کنید. مثلا "گربه زیبا" اشتباه است و "گربه" درست است.
            </div>
          </li>
          <li>
            <div>
              از به کار بردن عباراتی که به تصویر یا شکل اشاره می‌کنند خودداری کنید. به طور مثال "در این تصویر ..." یا "در این عکس ..." یا ".... در تصویر است." یا "متن تصویر عبارت است از ..."
            </div>
          </li>
          <li>
            <div>
              از نوشتن اسم خاص افراد مانند اسامی افراد مشهور خودداری کنید. 
            </div>
          </li>
        </ol>
        <div>چند مثال برای این قسمت:</div>
        {/* <ol>
          <li> */}
            <img src={Example1} href="example1"/>
            <ul>
              <li>
            <div className="correct">دو زن درون چادر در حال فرش‌بافی و نخ‌ریسی بر روی یک فرش هستند.</div>
            </li>
            <li>
            <div className="correct">یک زن با لباس سبز تیره به صورت نشسته در حال فرش‌بافی و دیگری با لباس آبی به صورت ایستاده در حال نخ‌ریسی است.</div>
            </li>
            <li>
            <div className="correct">یک زن ایستاده نخ می‌ریسد و یک زن نشسته فرش می‌بافد.</div>
            </li>
            <li>
            <div className="incorrect">در این تصویر یک زن ایستاده نخ می‌ریسد و یک زن نشسته فرش می‌بافد.</div>
            <div className="desc">"در این تصویر" نباید نوشته شود.</div>
            </li>
            <li>
            <div className="incorrect">دو زن فرش می‌بافند.</div>
            <div className="desc">متن بسیار ناقص است. همچنین کمتر از ۸ کلمه دارد.</div>
            </li>
            <li>
            <div className="incorrect">یک زن با عینک و حالت صورت جدی به دوربین نگاه کرده و نخ می‌ریسد. زن دیگر سرش را پایین گرفته و فرش می‌بافد. این دو درون چادری هستند که از دیواره‌هایش تزئیناتی آویزان است.</div>
            <div className="desc">این متن بیش از حد جزئیات تصویر را بیان می‌کند. هم چنین بیشتر از یک جمله دارد.</div>
            </li>
            </ul>
          {/* </li>
        </ol> */}
      </p>
    </div>
  );
}
