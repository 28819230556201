import "./Dataset.css";
import { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { ToastContainer, toast } from 'react-toastify';
import { MediaUrl, ServerUrl } from "utils/Constants";
import { TemplateChecker } from "./TemplateChecker";

function DatasetWithQuestion(props) {
    document.title = "جمع‌آوری دیتاست به صورت وارد کردن پرسش و پاسخ | PersianVQA"
    let submittedRef = useRef();
    let category_val = {
        "object presence": 1, "sport recognition": 2,
        "positional reasoning": 3, "sentiment understanding": 4
        , "color attributes": 5, "counting object": 6,
        "activity recognition": 7, "object detection": 8,
        "object material": 9, "gender recognition": 10,
        "other": 11
    }
    const [image, setImage] = useState(null);

    //////page status://////
    //"ln": loading new pic / question
    //"w": waiting for the user to fill the form and submit
    //"l": submitting the form user filled to server
    //"t": translating the form user filled
    //"s": submitted!
    //"nq": no auto question for this image error
    const [status, setStatus] = useState("ln");
    const [answer, setAnswer] = useState([]);
    const [longAnswer, setLongAnswer] = useState([]);
    // const [userQ, setUserQ] = useState("");
    // const [autoQ, setAutoQ] = useState(true); // two states: auto question (true) / user writes a question (false)
    const [Q, setQ] = useState([]); //null
    const [colloquial, setColloquial] = useState([]);
    const requiredQACount = 3;
    const [QACount, setQACount] = useState(requiredQACount);
    const [category, setCategory] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState([]);

    const templateChecker = new TemplateChecker();

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            if (image)
                URL.revokeObjectURL(image.preview);
        },
        [image]
    );

    async function getNewVQ(reset = true) {
        if (!reset && image)
            return;
        setStatus("ln");
        // setAutoQ(false);
        setQ([]);
        setAnswer([]);
        setLongAnswer([]);
        setColloquial([]);
        setCategory([]);
        setConfirmDelete([]);
        setQACount(requiredQACount);
        // setUserQ("");
        console.log("Token " + localStorage.getItem("loginToken"));
        // document.getElementById("autoq_btn").disabled = false;
        fetch(`${ServerUrl}/dataset/check-access`, {
            method: "GET",
            headers: {
                "Authorization": "Token " + localStorage.getItem("loginToken"),
            }
        }).then(r => r.json()).then(r => {
            if (r.access_dataset)
                fetch(`${ServerUrl}/dataset/random-image`, {
                    method: "GET",
                    headers: {
                        "Authorization": "Token " + localStorage.getItem("loginToken"),
                    }
                }).then(res => res.ok ? res.json() : res.status === 401 ? 401 : null)
                    .then(res => {
                        if (res === 401)
                            window.location.href = "/pvqa/login"
                        else if (!res) {
                            throwError('مشکلی رخ داد! لطفا دوباره تلاش کنید.')
                            return;
                        }
                        else if (!res.path) {
                            throwError('هیچ تصویری موجود نیست!')
                            return;
                        }
                        console.log(res);
                        setImage(res);
                        setQ(Array(QACount).fill(""));
                        setAnswer(Array(QACount).fill(""));
                        setLongAnswer(Array(QACount).fill(""));
                        setColloquial(Array(QACount).fill(false));
                        setcolloquialWithIdx(1,true);
                        setCategory(Array(QACount).fill("other"));
                        setConfirmDelete(Array(QACount).fill(false));
                        if (document.getElementById("ques0"))   ////////FIX
                            document.getElementById("ques0").focus();
                    }).catch(err => {
                        throwError('مشکلی رخ داد! لطفا دوباره تلاش کنید.')
                        console.log(err)
                    })
            else
                window.location.href = "/pvqa";
        }).catch(err => {
            window.location.href = "/pvqa";
        })
        setStatus("w");
    }

    function setAnswerWithIdx(idx, value) {
        let tempA = [...answer];
        tempA[idx] = value;
        setAnswer([...tempA]);
        // console.log(tempA);
    }

    function setLongAnswerWithIdx(idx, value) {
        let tempA = [...longAnswer];
        tempA[idx] = value;
        setLongAnswer([...tempA]);
        // console.log(tempA);
    }

    function setQuestionWithIdx(idx, value) {
        let tempA = [...Q];
        tempA[idx] = value;
        setQ([...tempA]);
        // console.log(tempA);
        if (idx == requiredQACount-1) return;
        let template_match = templateChecker.match(value);
        if (template_match) {
            document.getElementById("ques" + idx).style.borderColor = "red";
            document.getElementById("ques-err" + idx).hidden = false;
        }
        else {
            let elem = document.getElementById("ques" + idx);
            if (elem.style.removeProperty) {
                elem.style.removeProperty('border-color');
            } else {
                elem.style.removeAttribute('border-color');
            }
            document.getElementById("ques-err" + idx).hidden = true;
        }
    }

    function setcolloquialWithIdx(idx) {
        let tempA = [...colloquial];
        tempA[idx] = !tempA[idx];
        setColloquial([...tempA]);
        // console.log(tempA);
    }

    function setCategoryWithIdx(idx, value) {
        let tempA = [...category];
        tempA[idx] = value;
        setCategory([...tempA]);
        // console.log(tempA);
    }

    function setConfirmDeleteWithIdx(idx) {
        let tempA = [...confirmDelete];
        tempA[idx] = !tempA[idx];
        setConfirmDelete([...tempA]);
        // console.log(tempA);
    }

    useEffect(() => {
        if (!localStorage.getItem("loginToken")) {
            window.location.href = "/pvqa"
            return;
        }
        getNewVQ(false)
    }, []);

    function throwError(text) {
        toast.error(text, {
            position: "bottom-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    useEffect(() => {
        if (status == 's')
            window.scrollTo({
                behavior: "smooth",
                top:
                    submittedRef.current.getBoundingClientRect().top + window.scrollY - 100,
            });
    }, [status])

    async function submitVQWithIndex(idx, maxQLength) {
        // if (Q[idx].useless) {
        //     fetch(`${ServerUrl}/dataset/useless-question/${Q[idx].id}`, {
        //         method: "PATCH",
        //         headers: {
        //             "Authorization": "Token " + localStorage.getItem("loginToken"),
        //         }
        //     }).then(res => res.ok ? res.json() : null)
        //         .then(res => {
        //             if (!res) {
        //                 throwError('مشکلی در ثبت داده رخ داد!')
        //                 setStatus("w")
        //                 return;
        //             }
        //             idx += 1;
        //             if (idx == maxQLength) {
        //                 setStatus("s");
        //             }
        //             else {
        //                 submitVQWithIndex(idx, maxQLength);
        //             }
        //         }).catch(err => {
        //             console.log(err);
        //             throwError('مشکلی در ثبت داده رخ داد!')
        //             setStatus("w")
        //         })
        // }
        // else {

        ////// FIX : create Question
        ////// and maybe the english version
        //////create long answer

        let fd = new FormData()
        fd.append("image", image.id);
        fd.append("question", Q[idx]);
        fd.append("short_answer", answer[idx]);
        fd.append("long_answer", longAnswer[idx]);
        fd.append("category", category_val[category[idx]]);
        console.log(category[idx])
        switch(idx){
            case 0:
            case 2:
                fd.append("formal", true)
                break;
            case 1:
                fd.append("formal", false)
                break;
            default:
            fd.append("formal", !colloquial[idx]);
        }
        fetch(`${ServerUrl}/dataset/persian-data/`, {
            method: "POST",
            headers: {
                "Authorization": "Token " + localStorage.getItem("loginToken"),
            },
            body: fd
        }).then(res => res.ok ? res.json() : null)
            .then(res => {
                if (!res) {
                    throwError('مشکلی در ثبت داده رخ داد!')
                    setStatus("w")
                    return;
                }
                idx += 1;
                if (idx == maxQLength) {
                    setStatus("s");
                }
                else {
                    submitVQWithIndex(idx, maxQLength);
                }
            }).catch(err => {
                console.log(err);
                throwError('مشکلی در ثبت داده رخ داد!')
                setStatus("w")
            })
        // }
    }

    async function submitVQ() {
        setStatus("l");
        for (let i = 0; i < QACount; i++) {
            if (!answer[i] || !longAnswer[i] || !Q[i]) {
                throwError('لطفا قبل از ثبت فیلدها را پر کنید.')
                setStatus("w");
                return;
            }
            let template_match = templateChecker.match(Q[i]);
            if (i != requiredQACount-1 && template_match) {
                throwError(`لطفا پرسش ${i + 1}ام را تغییر دهید.`)
                setStatus("w");
                return;
            }
        }
        submitVQWithIndex(0, QACount);
    }

    function AddToArray(array, default_val) {
        let tempA = [...array];
        tempA.push(default_val);
        return tempA;
    }

    function DeleteFromArray(array, idx) {
        let temp = [...array];
        temp.splice(idx, 1);
        return temp;
    }

    function AddNewQA() {
        setQACount(QACount + 1);
        setAnswer([...AddToArray(answer, "")]);
        setLongAnswer([...AddToArray(longAnswer, "")]);
        setCategory([...AddToArray(category, "other")]);
        setQ([...AddToArray(Q, "")]);
        setColloquial([...AddToArray(colloquial, false)]);
        setConfirmDelete([...AddToArray(confirmDelete, false)]);
    }

    function DeleteQA(idx) {
        setQACount(QACount - 1);
        setAnswer([...DeleteFromArray(answer, idx)]);
        setLongAnswer([...DeleteFromArray(longAnswer, idx)]);
        setCategory([...DeleteFromArray(category, idx)]);
        setQ([...DeleteFromArray(Q, idx)]);
        setColloquial([...DeleteFromArray(colloquial, idx)]);
        setConfirmDelete([...DeleteFromArray(confirmDelete, idx)]);
    }

    // let KeyPress = function (e) {
    //     e = e || window.event;
    //     if (e.code === "Enter") {
    //         switch (status) {
    //             case "s": getNewVQ();
    //                 break;
    //             case "nq": setStatus("w");
    //                 break;
    //             default:
    //         }
    //     }
    // };
    // document.addEventListener('keydown', KeyPress);
    return (
        <div className="dataset-page dataset-with-question">
            <div className="dataset-page-inner">
                <div className="page-title-description" style={{ marginBottom: "5vh" }}>
                    <h1>جمع‌آوری دیتاست با وارد کردن پرسش و پاسخ</h1>
                    <p className="description">
                        در این قسمت نیاز است برای هر تصویر حداقل سه پرسش با پاسخ‌های کوتاه و بلند بنویسید.
                    </p>
                    <p className="description">
        حتما اولین پرسش و پاسخ به صورت غیرمحاوره‌ای یا رسمی و دومین پرسش و پاسخ محاوره‌ای باشد. همچنین نمی‌توان پرسش‌های مطابق با الگوها را برای این دو وارد کرد و حتما این دو پرسش، سخت و چالش‌برانگیز باشند.
                    </p>
                    <p className="description">
        پرسش سوم از نظر مشابهت با الگوها و همچنین سختی سوال محدودیتی ندارد اما باید رسمی و نوشتاری  و تا جای ممکن مرتبط با فرهنگ ایرانی باشد. 
                    </p>
                    <p className="description">
          هر پرسشی را که اضافه بر این سه وارد می‌کنید (پرسش‌های ۴ به بعد) 
          نباید مطابق با الگوها باشد، اما نوشتاری یا محاوره‌ای بودن آن را خود شما انتخاب می‌کنید. این پرسش‌ها باید همانند دو مورد اول سخت و چالش‌برانگیز باشند.
                    </p>
                    <p className="description">
            ** توجه داشته باشید کلماتی که هم صورت چسبیده هم جدای آن‌ها رایج است (مانند "اینطور" و "این طور") را به شکل جدا از هم بنویسید. (در این مثال "این طور" خواستهٔ ماست.)
            اگر می‌دانید کلمه‌ای با نیم‌فاصله است در صورت امکان آن را با نیم‌فاصله بنویسید.
          </p>
                </div>
                <>
                    <div className="dataset">
                        {status !== "w" && (
                            <div className="dataset-loading">
                                <div className="dataset-loading-inner">
                                    {status === "l" ? (
                                        <>
                                            <h5>در حال ثبت</h5>
                                            <p>لطفا منتظر بمانید</p>
                                            <div className="stage">
                                                <div className="dot-flashing"></div>
                                            </div>
                                        </>
                                    ) : status === "s" ? (
                                        <>
                                            <CheckCircleIcon ref={submittedRef} />
                                            <h5 className="submitted">
                                                اطلاعات شما ثبت شد. از همکاریتان متشکریم!
                                            </h5>
                                            <button className="btn" onClick={() => getNewVQ()}>
                                                ثبت دیتای جدید
                                            </button>
                                        </>
                                    ) : status === "ln" ? (
                                        <>
                                            <h5>در حال ایجاد فرم جدید</h5>
                                            <p>لطفا منتظر بمانید</p>
                                            <div className="stage">
                                                <div className="dot-flashing"></div>
                                            </div>
                                        </>
                                    ) : (
                                        //status = "nq"
                                        <>
                                            <SentimentVeryDissatisfiedIcon />
                                            <h5 className="submitted">
                                                پرسشی برای این تصویر ثبت نشده است. لطفا پرسش را دستی وارد کنید.
                                            </h5>
                                            <button className="btn" onClick={() => setStatus("w")}>
                                                ادامه
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 order-lg-2 col-lg-5 ">
                                <div className="picture-field">
                                    <div className="img-container">
                                        <img src={MediaUrl + (MediaUrl?.includes("/pvqa")?"/":"/pvqa/") + image?.path} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7">
                                {/* <div className="q-submit-field"> */}
                                <div className="col">
                                    {!!Q && Q.map((question, idx) =>
                                        <div key={idx}>
                                            <p style={{ textAlign: "right" }}>.{idx + 1}</p>
                                            {QACount > requiredQACount && idx > (requiredQACount-1) && (!confirmDelete[idx] ?
                                                <div onClick={() => setConfirmDeleteWithIdx(idx)} className="btn delete-btn">
                                                    حذف پرسش زیر و پاسخ‌های آن
                                                </div> : <>
                                                    <p>آیا از حذف پرسش زیر و پاسخ‌های آن اطمینان دارید؟</p>
                                                    <div onClick={() => DeleteQA(idx)} className="btn delete-btn">
                                                        تایید و حذف
                                                    </div><div onClick={() => setConfirmDeleteWithIdx(idx)} className="btn delete-btn">
                                                        لغو حذف
                                                    </div>
                                                </>)
                                            }
                                            <div className="q-submit-field">
                                                <textarea
                                                    rows={2}
                                                    placeholder={idx!=requiredQACount-1? "متن پرسش را اینجا بنویسید" : "متن پرسش را با توجه به فرهنگ ایرانی بنویسید"}
                                                    maxLength="200"
                                                    onChange={(e) => setQuestionWithIdx(idx, e.target.value)}
                                                    value={question}
                                                    id={"ques" + idx}
                                                />
                                            </div>
                                            <p className="ques-validator-err" id={"ques-err" + idx} hidden={true}>{"این پرسش مشابه سوالات الگوست."} لطفا پرسش را تغییر دهید.</p>
                                            <div className="q-submit-field">
                                                <textarea
                                                    rows={1}
                                                    placeholder="پاسخ کوتاه را اینجا بنویسید"
                                                    maxLength="200"
                                                    onChange={(e) => setAnswerWithIdx(idx, e.target.value)}
                                                    value={answer[idx]}
                                                    id={"ans" + idx}
                                                />
                                            </div>
                                            <div className="q-submit-field">
                                                <textarea
                                                    rows={2}
                                                    placeholder="پاسخ بلند را اینجا بنویسید"
                                                    maxLength="200"
                                                    onChange={(e) => setLongAnswerWithIdx(idx, e.target.value)}
                                                    value={longAnswer[idx]}
                                                    id={"ans" + idx}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="colloquial-container col-12 col-md-6">
                                                    <label>
                                                        پرسش و پاسخ محاوره‌ای است.
                                                        <input type="checkbox" defaultChecked={colloquial[idx]} onChange={(e) => setcolloquialWithIdx(idx)} disabled={idx < requiredQACount}/>
                                                    </label>

                                                </div>
                                                <div className="category col-12 col-md-6">
                                                    <label htmlFor="category">دسته بندی پرسش</label>
                                                    <select
                                                        multiple={false}
                                                        name="category"
                                                        id="category"
                                                        onChange={(e) => setCategoryWithIdx(idx, e.target.value)}
                                                        value={category[idx]}
                                                    >
                                                        <option value="object presence">وجود شیء</option>
                                                        <option value="sport recognition">شناسایی ورزش</option>
                                                        <option value="positional reasoning">استدلال مکانی</option>
                                                        <option value="sentiment understanding">درک عواطف</option>
                                                        <option value="color attributes">ویژگی رنگی</option>
                                                        <option value="counting object">شمارش شیء</option>
                                                        <option value="activity recognition">شناسایی فعالیت</option>
                                                        <option value="object detection">یافتن شیء</option>
                                                        <option value="object material">جنس شیء</option>
                                                        <option value="gender recognition">شناسایی جنسیت</option>
                                                        <option value="other">سایر</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!!image && <>
                                        <button
                                            className="btn"
                                            onClick={submitVQ}
                                            disabled={status !== "w"}
                                        >
                                            <div className="btn-inner">
                                                {"ثبت"}
                                                <ChevronLeftIcon />
                                            </div>
                                        </button>
                                        <button
                                            className="btn add-btn"
                                            onClick={AddNewQA}
                                            disabled={status !== "w"}
                                        >
                                            <div className="btn-inner">
                                                {"اضافه کردن پرسش و پاسخ جدید"}
                                            </div>
                                        </button>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
}

export default DatasetWithQuestion;
