import React from "react";
import "./style.css";

const Info = (props) => {
  return (
    <div class="back">
      <div class="content-info">
        <div class="info">
          <h3>تشخیص ویژگی‌های شخصیتی با استفاده از متن افراد</h3>
          <p style={{ lineHeight: "2rem" }}>
            پروژه «تشخیص ویژگی‌های شخصیتی با استفاده از متن افراد در زبان فارسی»
            یکی از پروژه‌های در حال انجام در آزمایشگاه پردازش زبان‌های طبیعی در
            دانشگاه علم و صنعت ایران است. این پروژه از پاییز 1400 توسط گروهی از
            دانشجو‌های کارشناسی ارشد و کارشناسی زیر نظر استادیار دانشکده مهندسی
            کامپیوتر آقای سید صالح اعتمادی در حال انجام است. در این پروژه با
            جمع‌آوری داده‌های فارسی در شبکه اجتماعی توییتر یک مجموعه دادۀ معتبر
            فارسی در زمینۀ تشخیص ویژگی‌های شخصیتی تهیه شده و سامانه‌ای برای
            تشخیص خودکار تیپ‌‌های شخصیتی افراد طراحی می‌شود. در حال حاضر این
            پروژه برای تشخیص تیپ‌های مدل شخصیتی MBTI در حال انجام است اما امید
            است که این سیستم به دیگر مدل‌های شخصیتی نیز بسط پیدا کند. این طرح در
            سال 1400 توسط ستاد علوم و فناوری‌های شناختی پذیرفته شده تا در جهت
            پیشبرد هوش مصنوعی و علوم شناختی در زبان فارسی از آن حمایت مالی صورت
            گیرد.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
