import { useEffect, useState } from "react";
import {MediaUrl, ServerUrl} from "utils/Constants";
import BlogCard from "./BlogCard";
import AddIcon from '@mui/icons-material/Add';

function BlogPage(props) {
    const [posts, setPosts] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
  document.title="بلاگ | PersianVQA"
  useEffect(() => {
    fetch(`${ServerUrl}/blogpost/posts/latest`, {
        method: "GET",
      }).then(res => res.json())
      .then(res=>{
          setPosts(res);
          console.log(res);
      })
      .catch(err => console.error(err));
    fetch(`${ServerUrl}/user/is_admin`,{
        method: "GET",
        headers: {
          "Authorization": "Token " + localStorage.getItem("loginToken"),
        }
      }).then(res => res.ok? res.json() : setIsAdmin(false))
      .then(res =>{
        setIsAdmin(res);
      }).catch(err =>{
        console.error(err);
      })
  }, []);

  return (
    <div className="dataset-page">
      <div className="dataset-page-inner">
        <div className="page-title-description" style={{ marginBottom: "5vh" }}>
          <div style={{display: "flex", direction: "rtl"}}>
            <h1>بلاگ</h1>
            {isAdmin && <a href="/post/new" className="btn" 
            style={{marginTop: "0.3rem", padding:"unset", color: "var(--secondary-color)", fontSize: "1.7rem"}}
            ><AddIcon /></a>}
          </div>
          <div className="blog-list">
            {
                posts.map((post, i) =><div key={post.id}>
                    <BlogCard post={post} isAdmin={isAdmin}/>
                    {
                        i !== posts.length - 1 && (
                            <div className="separator" />
                        )
                    }
                </div>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
