import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BlogPostEdit from "apps/PVQA/components/Blog/BlogPostEdit";
import BlogPost from "apps/PVQA/components/Blog/BlogPost";
import BlogPage from "apps/PVQA/components/Blog/BlogPage";
import DemoPage from "apps/PVQA/components/Demo/DemoPage";
import Auth from "apps/PVQA/components/Login-Signup/Authentication";
import DatasetPage from "apps/PVQA/pages/Dataset/DatasetPage";
import DatasetWithQuestion from "apps/PVQA/pages/Dataset/DatasetWithQuestion";
import ImageCaptioningDataset from "apps/PVQA/pages/Dataset/ImageCaptioningDataset";
import DatasetSelectPage from "apps/PVQA/pages/Dataset/DatasetSelectPage";
import DatasetListPage from "apps/PVQA/pages/Dataset/DatasetListPage";
import DatasetStatistics from "apps/PVQA/pages/Dataset/DatasetStatistics";
import LandingPage from "apps/PVQA/pages/LandingPage/LandingPage";
import DatasetGuidelinePage from "apps/PVQA/pages/DatasetGuidelinePage";

import CategoriesDatasetChart from "apps/PVQA/components/Charts";
import EditImageCaptioningData from "../pages/Dataset/EditImageCaptioningData";
const AppRouter = ({ triggerNavbarUpdate, setTriggerNavUpdate }) => {
  return (
    <Router basename="/pvqa">
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => (
            <LandingPage
              triggerNavbarUpdate={triggerNavbarUpdate}
              setTriggerNavUpdate={setTriggerNavUpdate}
              {...props}
            />
          )}
        />
        <Route exact path="/demo" component={DemoPage} />
        <Route exact path="/login" component={Auth} />
        <Route path="/dataset/statistics" component={DatasetStatistics} exact />
        <Route
          path="/dataset/statistics/categories"
          component={CategoriesDatasetChart}
        />
        <Route path="/dataset/view" component={DatasetListPage} />
        <Route path="/dataset/answer-only" component={DatasetPage} />
        <Route path="/dataset/question-answer" component={DatasetWithQuestion} />
        <Route path="/dataset/captions/edit/:id" component={EditImageCaptioningData} />
        <Route path="/dataset/captions" component={ImageCaptioningDataset} />
        <Route exact path="/dataset" component={DatasetSelectPage} />
        <Route path="/post/new" component={BlogPostEdit} />
        <Route path="/post/:id/edit" component={BlogPostEdit} />
        <Route path="/post/:id" component={BlogPost} />
        <Route path="/blog" component={BlogPage} />
        <Route path="/dataset/guideline" component={DatasetGuidelinePage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
