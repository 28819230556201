import axios from "axios";
import {ServerUrl} from 'utils/Constants'

const apiInstance = axios.create();
apiInstance.defaults.baseURL = ServerUrl;

apiInstance.interceptors.request.use(
  (config) => {
    const loginToken = localStorage.getItem("loginToken");
    if (loginToken) {
      config.headers["Authorization"] = "Token " + loginToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


export default apiInstance;
