import React from "react";
import "./FinalPage.style.scss";
// import { useNavigate } from "react-router-dom";
import { getThemeProps } from "@material-ui/styles";
import { CopyOutlined } from "@ant-design/icons";

const FinalPage = (props) => {
  // const navigate = useNavigate();

  return (
    <div className="Final">
      <div className="background scroll">
        <div className="thanks-content">
          <div className="thanks-info">
            <div className="code-box">
              <div className="Code">
                <div className="Code-text">
                  <b>{props.Code}</b>
                  {/* <button
                  className="btn-color"
                  onClick={() => navigator.clipboard.writeText(props.Code)}
                >
                  <CopyOutlined />
                </button> */}
                </div>

              </div>
              <p>
                لطفا از طریق آی‌دی‌ای که به ما دادید کد بالا رو به دایرکت اکانت
                <a href="https://twitter.com/IUST_NLP"> IUST_NLP@ </a>
                ارسال کنید.
                <b> توجه داشته باشید اگر ما از آی‌دی شما دایرکتی دریافت نکنیم داده‌های شما متاسفانه برای ما قابل استفاده نخواهد بود. پس لطفاً این مرحله رو انجام بدین.</b>
                <br />
                ضمناً هدیۀ شما هم از طریق همان دایرکت و در پاسخ کد فرستاده‌شده ارسال می‌شه.
                <br />
                <b>
                  بنابراین لطفاً اطمینان حاصل کنید که آی‌دی شما با آی‌دی‌ای که به ما دادید
                  مطابقت داشته باشه و اکانت هم متعلق به خودتون باشه.
                </b>
                <br />

              </p>

            </div>
            <div>
              خیلی ممنون که در کنار این‌که اطلاعاتی درباره تیپ شخصیتی خودتون
              گرفتین، به ما هم در پژوهشمون در راستای پیشرفت هوش مصنوعی در زبان
              فارسی کمک کردین.
            </div>
            <div>
              <b>
                برای قدردانی از کمکتون، تا حداکثر ۲۴ ساعت آینده کد هدیه‌ای در قالب
                دایرکت آی‌دی توییترتون براتون ارسال می‌شه.
              </b>
            </div>
          </div>
          <div className="reference-text">
            <div>
              منابعی که ما ازشون برای تهیه این پرسش‌نامه کمک گرفتیم:
              <li>
                کتاب آزمون‌های روان‌شناختی (جلد دوم) نوشته دکتر علی فتحی آشتیانی و
                همکاران. این کتاب که مورد تایید اساتید رشته روان‌شناسی در ایرانه
                کمک کرد که آزمون، تشخیص تیپ شخصیتی و توضیحات کاملاً معتبر باشند.
              </li>
              <li>
                شخصیت‌های معروف توی هر تیپ شخصیتی از وب‌سایت
                <a href="https://www.personality-database.com/">
                  Personality Database{" "}
                </a>
                وام گرفته شده .
              </li>
              <li>
                تصاویر گرافیکی از صفحه
                <a href="https://instagram.com/sakkowo?igshid=YmMyMTA2M2Y=">
                  sakkowo@
                </a>
                در اینستاگرام
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalPage;
