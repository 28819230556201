import "./PeopleMiniView.css";
import { useState, useEffect } from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ServerUrl } from "utils/Constants"; //not sure about the url

function PeopleMiniView(props) {
    const [People, SetPeople] = useState([]);

    useEffect(() => {
        //remove this when fetch starts working 
        //and can get members from server:
        SetPeople([{
            first_name: "Name", last_name: "Last Name", about: "an ordinary person. nothing worth noting.\ncompletely normal lol",
            image: "https://i.pinimg.com/564x/69/39/43/6939434841ebd31f182e29911bd0d6c7.jpg",
            role: ["prof", "developer"], accounts: [], id: 1
        },
        {
            first_name: "Some other name", last_name: "Last Name", about: "an ordinary person. nothing worth noting.\ncompletely normal lol",
            image: "https://i.pinimg.com/564x/69/39/43/6939434841ebd31f182e29911bd0d6c7.jpg",
            role: ["full-stack developer", "researcher"], accounts: [], id: 2
        },
        {
            first_name: "Some name", last_name: "Some other last name", about: "an ordinary person. nothing worth noting.\ncompletely normal lol",
            image: "https://i.pinimg.com/564x/69/39/43/6939434841ebd31f182e29911bd0d6c7.jpg",
            role: ["prof"], accounts: [], id: 3
        }
        ]);

        //get members from the server
        fetch(`${ServerUrl}/members/`, {
            method: "GET",
        }).then(res => res.json())
            .then(res => {
                SetPeople(res);
                console.log(res);
            })
            .catch(err => console.error(err));
    }, []);

    return (<div className="people-mini-view">
        <div className="row">
            {People.map((p, i) => (
                <div className="col-6 col-sm-4 col-md-3 col-xl-2">
                    <PeopleMiniViewCard info={p} />
                </div>
            ))}

        </div>
        <a className="people-link" href="/people">View all members<KeyboardDoubleArrowRightIcon /></a>
    </div>)
}

function PeopleMiniViewCard(props) {
    return (<div className="people-card">
        <div className='img-container'>
            <img src={props.info.image} alt={props.info.first_name + " " + props.info.last_name + "'s profile"}/>
        </div>
        <h6>{props.info.first_name + " " + props.info.last_name}</h6>
        <p className="role">{props.info.role?.join(", ")}</p>
    </div>)
}

export default PeopleMiniView;