import { useHistory } from "react-router-dom";
import React from "react";
import "./INTP.style.scss";
import intpPic from "../../../assets/images/types/intp/INTP.jpg";
import Einstein from "../../../assets/images/types/intp/intp1.png";
import Darwin from "../../../assets/images/types/intp/intp2.png";
import Turing from "../../../assets/images/types/intp/intp3.png";
import Sherlock from "../../../assets/images/types/intp/intp4.png";
import Amy from "../../../assets/images/types/intp/intp5.png";
import Alice from "../../../assets/images/types/intp/intp6.png";
import Garfield from "../../../assets/images/types/intp/intp7.png";
import Patrick from "../../../assets/images/types/intp/intp8.png";
import L from "../../../assets/images/types/intp/intp10.png";
import Kakashi from "../../../assets/images/types/intp/intp9.png";
// import { useNavigate } from "react-router-dom";

const INTP = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-intp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={intpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> INTP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد INTP: </b>
              آرام و تودار می‌باشد. در آزمون‌های آموزشی به خصوص در زمینهٔ علمی و
              نظری عمل می‌کند. عموما به ایده‌ها و نظریات جدید علاقه نشان می‌دهد.
              توجه زیادی به محافل اجتماعی یا بحث‌های بیهوده ندارد. به نظر می‌آید
              علاقهٔ منحصربه‌فرد و پیچیده‌ای دارد. نیاز به انتخاب شغل‌هایی دارد
              که بتواند از موضوعات موردعلاقه و سرگرمی او در زمینهٔ کارهای مفید
              استفاده نماید.
            </div>
            <div className="jobstitle">شغل‌های مناسب یک INTP:</div>
            <div className="jobs">
              باستان‌شناسی، معمار، فضانورد، وکیل، زیشت‌شناس، شیمی‌دان، تحلیل‌گر
              یا برنامه‌نویسِ کامپیوتر، برنامه‌نویسِ مالی، طراحِ گرافیک،
              تاریخ‌دان، مخترع، ریاضی‌دان، موسیقی‌دان، فیلسوف، عکاس، پزشک،
              روانپزشک، روانشناس، پژوهشگر، دانشمند، استاد دانشگاه، نویسنده
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                می‌توانند سیستم‌های پیچیده و الگوهای مفهومی را بهبود ببخشند، اما
                ترجیح می‌دهند که کاربرد و اجرا را به دیگران واگذار نمایند.
              </li>

              <li>
                از صلاحیت حرفه‌ای سطح بالا برخوردارند و از این حیث به خود
                می‌بالند.
              </li>

              <li>
                همه انتخاب‌ها به دقت وارسی می‌کنند. متوجه تضادها، ناسازگاری‌ها و
                اشکالات منطقی در اندیشه‌های خود و دیگران هستند.
              </li>

              <li>وقتی موضوعی را به درستی درک کنند به حرکت درمی‌آیند.</li>

              <li>
                دوست دارند هدف‌ها و استانداردهای خود را در محیطی انعطاف‌پذیر و
                بی‌ساختار تعیین کنند.
              </li>

              <li>
                در صورتی که نتوانند از توانمندی‌های خود استفاده کنند، بدبین و
                افسرده می‌شوند.
              </li>

              <li>
                دوست دارند که به‌طور مستقل کار کنند، اما تکمیل‌کردن کارها
                برای‌شان دشوار است.
              </li>

              <li>دوست ندارند دیگران را سرپرستی کنند.</li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> INTP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Einstein} alt="Albert Einstein" />
                  <div className="intru">
                    <div className="name">Albert Einstein</div>
                    <div className="role">فیزیک‌دان</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Darwin} alt="Charles Darwin" />
                  <div className="intru">
                    <div className="name">Charles Darwin</div>
                    <div className="role">زیست‌شناس</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Turing} alt="Alan Turing" />
                  <div className="intru">
                    <div className="name">Alan Turing</div>
                    <div className="role">ریاضی‌دان و دانشمند کامپیوتر</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Sherlock} alt="Sherlock Holmes" />
                  <div className="intru">
                    <div className="name">Sherlock Holmes</div>
                    <div className="role">Sherlock (2010)</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={Kakashi} alt="Kakashi" />
                  <div className="intru">
                    <div className="name">Kakashi Hatake</div>
                    <div className="role">Naruto</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Amy} alt="Amy" />
                  <div className="intru">
                    <div className="name">Amy Farrah Fowler</div>
                    <div className="role">The Big Bang Theory (2007)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Alice} alt="Alice" />
                  <div className="intru">
                    <div className="name">Alice</div>
                    <div className="role">آلیس در سرزمین عجايب (1951)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Garfield} alt="Garfield" />
                  <div className="intru">
                    <div className="name">Garfield the Cat</div>
                    <div className="role">Garfield (1980)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Patrick} alt="Patrick" />
                  <div className="intru">
                    <div className="name">Patrick Star</div>
                    <div className="role"> باب اسفنجی (1999)</div>
                  </div>
                </div>

                <div className="item-detail">
                  <img src={L} alt="L" />
                  <div className="intru">
                    <div className="name">L</div>
                    <div className="role">Death Note</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default INTP;
