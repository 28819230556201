import React from "react";
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './index.css';
import LandingPage from './LandingPage';
import LandingPageUpperPart from './LandingPageUpperPart';
import "theme/main_themes.css";
import PageFooter from 'components/MainFooter/PageFooter';
import CustomNavBar from 'components/MainStickyNavBar/CustomNavBar';

export default function LandingPageFull() {
  const [triggerNavbarUpdate, setTriggerNavUpdate] = useState(false);
  return (
    <div className="main">
      <div className="main-page-holder">
        <Router>
          <Switch>
            <Route
              path='/' render={(props) =>
                <LandingPageUpperPart
                  triggerNavbarUpdate={triggerNavbarUpdate}
                  setTriggerNavUpdate={setTriggerNavUpdate}
                  {...props}
                />
              }
            />
          </Switch>
        </Router>
        <CustomNavBar
          riggerNavbarUpdate={triggerNavbarUpdate}
          setTriggerNavUpdate={setTriggerNavUpdate}
        />
        <Router>
          <Switch>
            <Route
              path='/' render={(props) =>
                <LandingPage
                  triggerNavbarUpdate={triggerNavbarUpdate}
                  setTriggerNavUpdate={setTriggerNavUpdate}
                  {...props}
                />
              }
            />
          </Switch>
        </Router>
        <PageFooter />
      </div>
    </div>
  );
}
