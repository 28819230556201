import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from "react";
import "./Blog.scss";
import { createTypeAnnotationBasedOnTypeof } from '@babel/types';
import {MediaUrl, ServerUrl} from "utils/Constants";

/*
راهنمایی ها:
برای قرار دادن کدهای چند خطی، باید به جای enter از shift + enter استفاده کرد
*/


function BlogPostEdit(props) {
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [postStr, setPostStr] = useState("");
  const [loading, setLoading] = useState(true); //when loading, we should make sure the user's an admin
  const [isNewPost, SetIsNewPost] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [postId, setPostId] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photoId, setPhotoId] = useState(null);

  const id = window.location.pathname.split("/")[2];

  document.title = `blogpost editor | PersianVQA`;

  useEffect(() => {
    //after checking the account privileges
    fetch(`${ServerUrl}/user/is_admin`, {
      method: "GET",
      headers: {
        "Authorization": "Token " + localStorage.getItem("loginToken"),
      }
    }).then(res => res.ok? res.json(): window.location.href = "/pvqa")
      .then(res => {
        if (res == false) {
          window.location.href = "/pvqa";
        }
        else
          setLoading(false);
      }).catch(err => {
        window.location.href = "/pvqa";
      })
    if (window.location.href.includes("new"))
      SetIsNewPost(true);
    else {
      //get last post
      setPostId(id);
      fetch(`${ServerUrl}/blogpost/posts/${id}`, {
        method: "GET",
      }).then(res => res.ok ? res.json() : alert(res.statusText))
        .then(res => {
          console.log(res);
          if (!res)
            return;
          document.getElementById("title").value = res.title;
          document.getElementById("duration").value = res.duration_time;
          document.getElementById("keywords").value = res.tags.join("، ");
          document.getElementById("summary").value = res.summary;
          setPhotoId(res.image?.id);
          const blocksFromHtml = htmlToDraft(res.content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setContentState(EditorState.createWithContent(contentState));
        })
        .catch(err => {alert(err); console.log(err)})
    }
  }, [])

  function setNewPostContent(val) {
    // console.log(draftToHtml(convertToRaw(val.getCurrentContent())))
    setContentState(val);
  }

  function uploadCallback(file) {
    let fd = new FormData();
    fd.append("blogPost", postId);
    fd.append("uploaded_file", file);
    return new Promise((resolve, reject) => {
      fetch(`${ServerUrl}/blogpost/upload-file`, {
        method: 'POST',
        headers: {
          "Authorization": "Token " + localStorage.getItem("loginToken"),
        },
        body: fd
      })
        .then(res => res.json())
        .then(resData => {
          console.log(resData)
          resolve({ data: { link: resData.uploaded_file } });
        })
        .catch(error => {
          console.log(error)
          alert(error)
          reject(error.toString())
        })
    })

  }

  function ContentStateToHTMLstr() {
    let str = draftToHtml(convertToRaw(contentState.getCurrentContent()));
    str = str.replace(/<p /g, '<p dir="auto" ');
    str = str.replace(/<p>/g, '<p dir="auto">');
    str = str.replace(/<h1/g, '<h1 dir="auto"');
    str = str.replace(/<h2/g, '<h2 dir="auto"');
    str = str.replace(/<h3/g, '<h3 dir="auto"');
    str = str.replace(/<h4/g, '<h4 dir="auto"');
    str = str.replace(/<h5/g, '<h5 dir="auto"');
    str = str.replace(/<h6/g, '<h6 dir="auto"');
    str = str.replace(/<li/g, '<li dir="auto" ');
    console.log(str)
    return str
  }

  function showPostPreview() {
    if (!showPreview) {
      setPostStr(ContentStateToHTMLstr())
    }
    setShowPreview(!showPreview)
  }

  function getKeywords() {
    let keywords = document.getElementById("keywords").value;
    console.log(keywords);
    if (keywords) {
      keywords = keywords.split(/\s?،\s?/);
      let temp = []
      for(let i of keywords)
        i && temp.push(i)
      keywords = temp;
      return keywords;
    }
    return [];
  }

  function uploadMainPhoto(e) {
    if (e.target.files.length === 0)
      return;
    setPhoto(e.target.files[0]);

    // const reader = new FileReader();
    // reader.onload = () => {
    //     if (reader.readyState === 2) {
    //       setPhoto(reader.result);
    //     }
    // }
    // reader.readAsDataURL(e.target.files[0]);

  }

  function createPost() {
    let title = document.getElementById("title").value;
    let duration = document.getElementById("duration").value;
    let keywords = getKeywords();
    let fd = new FormData();
    fd.append("title", title);
    fd.append("duration_time", duration)

    // let keywords_converted = "[";
    // for(let i = 0; i< keywords.length; i++){
    //   keywords_converted += "\"" + keywords[i] + "\",";
    // }
    // keywords_converted += "]";
    // console.log(keywords_converted);
    console.log(JSON.stringify(keywords));
    fd.append("tags", JSON.stringify(keywords));

    //let body = JSON.stringify({"tags" : keywords_converted, "title": title, "duration_time": duration});
    fetch(`${ServerUrl}/blogpost/posts/`, {
      method: "POST",
      headers: {
        "Authorization": "Token " + localStorage.getItem("loginToken"),
      },
      body: fd
    }).then(res => res.ok ? res.json() : null)
      .then(res => {
        if (!!res) {
          setPostId(res.id);
        }
      })
      .catch(err => alert(err))
  }

  function completePost() {
    if(!photo && !photoId)
    {
      alert("لطفا یک تصویر برای پست انتخاب کنید.");
      return;
    }
    let str = ContentStateToHTMLstr()
    let title = document.getElementById("title").value;
    let duration = document.getElementById("duration").value;
    let summary = document.getElementById("summary").value;
    let keywords = getKeywords();
    let fd = new FormData();
    fd.append("title", title);
    fd.append("duration_time", duration);
    fd.append("content", str);
    fd.append("summary", summary);
    fd.append("tags", JSON.stringify(keywords));
    console.log(JSON.stringify(keywords));
    let fd_img = new FormData();
    fd_img.append("blogPost", postId);
    fd_img.append("uploaded_file", photo);
    if(isNewPost || photo){
      fetch(`${ServerUrl}/blogpost/upload-file`, {
        method: 'POST',
        headers: {
          "Authorization": "Token " + localStorage.getItem("loginToken"),
        },
        body: fd_img
      })
        .then(res => res.json())
        .then(resData => {
          console.log(resData)
          fd.append("photo", resData.id);
          PatchPost(fd);
  
        })
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    else{
      fd.append("photo", photoId);
      PatchPost(fd);
    }

  }

  function PatchPost(fd) {
    fetch(`${ServerUrl}/blogpost/posts/${postId}`, {
      method: "PATCH",
      headers: {
        "Authorization": "Token " + localStorage.getItem("loginToken"),
      },
      body: fd
    }).then(res => res.ok ? res.json() : null)
      .then(res => {
        if (!!res) {
          console.log(res);
          window.location.href = "/pvqa/blog"
        }
      })
      .catch(err => 
        alert(err))
  }

  return (
    <div className="dataset-page blog">
      <div className="dataset-page-inner" style={{ width: "100%" }}>
        {loading ?
          <div>
            <h5 dir="rtl">در حال چک کردن دسترسی حساب...</h5>
          </div>
          :
          <div className="page-title-description" style={{ marginBottom: "5vh" }}>
            <h1>{isNewPost ? "پست جدید" : "ویرایش پست"}</h1>
            <div className="post-info-editor">
              <label >عنوان پست*</label>
              <input type="text" id="title" dir="auto" />
              <label id="header-img">تصویر پست*</label>
              <input type="file" htmlfor="#header-img" id="img" onChange={uploadMainPhoto} />
              <label>زمان مطالعه*</label>
              <input type="text" id="duration" dir="auto" />
              <label>کلیدواژه ها</label>
              <textarea type="text" rows="2" id="keywords" dir="auto" />
              <p>لطفا کلیدواژه‌ها را با "،" جدا کنید</p>
              <label>چکیده</label>
              <textarea type="text" rows="4" id="summary" dir="auto" />
            </div>
            {postId ? <>
              <div className="editor-container">
                <Editor
                  toolbar={{
                    fontFamily: {
                      options: ['Nazanin', 'Vazir', 'yekan', 'Roya',
                        'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                    },
                    image: {
                      uploadCallback: uploadCallback,
                      previewImage: true,
                      alt: { present: true, mandatory: false },
                      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    }
                  }}
                  editorState={contentState}
                  onEditorStateChange={setNewPostContent}
                  editorClassName="blog-editor"
                  toolbarClassName="blog-toolbar"
                />
              </div>
              <div className="preview-btn btn" onClick={() => showPostPreview()}>{showPreview ? "Hide preview" : "Update and Show preview"}</div>
              {
                showPreview && <div className="post-container preview-container">
                  <div className="post" dangerouslySetInnerHTML={{ __html: postStr }} />
                </div>
              }
              <div className="save-btn btn" onClick={() => completePost()}>{"ذخیره و پست"}</div></>
              :
              <>
                <p dir="rtl">حداقل دو فیلد عنوان و زمان مطالعه را پر کنید و دکمه زیر را برای نوشتن متن پست فشار دهید.</p>
                <div id="start-editor-btn" className="save-btn btn" onClick={() => createPost()}>{"باز کردن ادیتور"}</div></>}
          </div>}
      </div>
    </div>
  )
}

export default BlogPostEdit;