import { useHistory } from "react-router-dom";
import React from "react";
import "./ESTJ.style.scss";
import estjPic from "../../../assets/images/types/estj/ESTJ.jpg";
import Dwight from "../../../assets/images/types/estj/estj1.png";
import Hillary from "../../../assets/images/types/estj/estj2.png";
import Monica from "../../../assets/images/types/estj/estj3.png";
import Helen from "../../../assets/images/types/estj/estj4.png";
import Emma from "../../../assets/images/types/estj/estj5.png";
import Mycroft from "../../../assets/images/types/estj/estj6.png";
import Dolores from "../../../assets/images/types/estj/estj7.png";
import Draco from "../../../assets/images/types/estj/estj8.png";
import Eugene from "../../../assets/images/types/estj/estj9.png";
import Cersei from "../../../assets/images/types/estj/estj10.png";
// import { useNavigate } from "react-router-dom";

const ESTJ = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-estj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={estjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ESTJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ESTJ: </b>
              واقع‌بین، اهل عمل و کم‌احساس می‌باشد. در زمینهٔ تجارت و کارهای فنی
              استعداد خاصی از خود نشان می‌دهد. در مورد موضوعاتی که نیاز به آن‌ها
              دیده نمی‌شود بی‌علاقگی نشان می‌دهد ولی در صورت لزوم آن را به کار
              می‌برد. علاقهٔ زیادی به سازمان‌دهی و هدایت فعالیت‌ها و پروژه‌ها
              دارد. معمولا کارها را به خوبی انجام می‌دهد. به‌خصوص اگر هنگام
              اتخاذ تصمیم، احساسات و نقطه‌نظرهای متفاوت را در نظر بگیرد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ESTJ:</div>
            <div className="jobs">
              مدیرِ مدرسه، وکیل، بازپرس، کارمند بانک، برنامه‌نویس کامپیوتر،
              کارگر ساختمان، دندان پزشک و پزشک، برق‌کار، مدیر مالی، پیمانکار،
              کارمند دولت، مدیرعامل بیمه، مکانیک، افسر ارتش و پلیس، واسطهٔ
              املاک، آموزگار
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                در زمينهٔ سازماندهی به روش‌ها، سیاست‌ها و فعالیت‌ها عالی عمل
                می‌کنند.{" "}
              </li>
              <li>
                از زمان و منابع به شکل مؤثر استفاده می‌کنند تا نتایج فوری و
                ملموس بگیرند.
              </li>
              <li>برای حل مسأله، رسیدن به نتیجه و پیشرفت آماده‌اند . </li>
              <li> از دیگران انتظار دارند از خود صلاحیت نشان دهند.</li>
              <li>آنچه را که غیر منطقی است، به خوبی درک می‌کنند. </li>
              <li>دوست دارند که ریسک‌های حساب شده بکنند. </li>
              <li>
                محیط باثبات و قابل پیش بینی را دوست دارند، اما محیطشان پر از
                انواع آدم‌ها است. می‌توانند سخت‌گیر و متوقع باشند اما در نهایت
                منصف‌اند.{" "}
              </li>
              <li>
                به کسانی که در برابر آن‌ها می ایستند احترام می‌گذارند، به شرط آن
                که آن‌ها واقعیت‌ها را شناخته، از قوانین اطلاع داشته‌باشند و مایل
                باشند در برابر کارهایشان مسئولیت را بپذیرند.{" "}
              </li>
              <li>معتاد به کار هستند و تلاش فراوان می‌کنند . </li>
              <li>
                ممکن است برای رسیدن به خواسته‌های خود حق دیگران را پایمال کنند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ESTJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Dwight} alt="Dwight K. Schrute" />
                  <div className="intru">
                    <div className="name">Dwight K. Schrute</div>
                    <div className="role">The Office (2005)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Hillary} alt="Hillary Clinton" />
                  <div className="intru">
                    <div className="name">Hillary Clinton</div>
                    <div className="role">سیاستمدار</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Monica} alt="Monica Geller" />
                  <div className="intru">
                    <div className="name">Monica Geller</div>
                    <div className="role">Friends (1994)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Helen} alt="Helen Parr “Elastigirl”" />
                  <div className="intru">
                    <div className="name">Helen Parr “Elastigirl”</div>
                    <div className="role">The Incredibles (2004)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Emma} alt="Emma Watson" />
                  <div className="intru">
                    <div className="name">Emma Watson</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Mycroft} alt="Mycroft Holmes" />
                  <div className="intru">
                    <div className="name">Mycroft Holmes</div>
                    <div className="role">Sherlock (2010)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Dolores} alt="Dolores Umbridge" />
                  <div className="intru">
                    <div className="name">Dolores Umbridge</div>
                    <div className="role">Harry Potter (2007)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Draco} alt="Draco Malfoy" />
                  <div className="intru">
                    <div className="name">Draco Malfoy</div>
                    <div className="role">Harry Potter (2007)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Eugene} alt="Eugene H. Krabs" />
                  <div className="intru">
                    <div className="name">Eugene H. Krabs</div>
                    <div className="role"> باب اسفنجی (2019)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Cersei} alt="Cersei Lannister" />
                  <div className="intru">
                    <div className="name">Cersei Lannister</div>
                    <div className="role">Game of Thrones (2011)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ESTJ;
