import './PageFooter.css'
import logo from 'assets/images/iust.png'

function PageFooter(props) {
    return (<div className="page-footer">
        <div className="row" >
            <div className="col-12 col-sm-4" >
                <h4>بیشتر بدانید</h4>
                <a>پرسش پاسخ تصویری</a>
                <a>راهنمای دمو</a>
                <a>راهنمای جمع‌آوری دیتاست</a>
            </div>
            <div className="col-12 col-sm-4" >
                <h4>ارتباط با ما</h4>
                <a>درباره ما</a>
                <a>تماس با ما</a>
            </div>
            <div className="col-12 col-sm-4" >
                <div className="logo-container">
                    <img src={logo} alt="iust-logo" />
                </div>
            </div>
        </div>
        <p>PersianVQA 2021</p>
    </div>)
}

export default PageFooter;