import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { faIR } from "@mui/material/locale";

export const muiTheme = responsiveFontSizes(
  createTheme(
    {
      palette: {
        primary: {
          main: "#000000",
        },
      },
      typography: {
        fontFamily: "Vazir, Arial",
      },
    },
    faIR
  )
);
