
export class TemplateChecker {
    Question_Templates = [
        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا در $image $object وجود دارد؟ ', 'id': 1 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا در $image $object هست؟ ', 'id': 2 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا در $image $object می‌بینید؟ ', 'id': 3 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا در $image $object مشاهده می‌شود؟ ', 'id': 4 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا در $image $object دیده می‌شود؟ ', 'id': 5 },

        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا $object1 $direction $object2 هست؟', 'id': 6 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 1, 'template': 'آیا $object1 $direction $object2 قرار دارد؟', 'id': 7 },

        { 'category': 'object presence', 'category_id': 1, 'formal': 0, 'template': 'توی $image $object وجود داره؟', 'id': 8 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 0, 'template': 'توی $image $object دیده میشه؟', 'id': 9 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 0, 'template': 'توی $image $object میبینی؟', 'id': 10 },

        { 'category': 'object presence', 'category_id': 1, 'formal': 0, 'template': '$object1 $direction $object2 قرار داره؟', 'id': 11 },
        { 'category': 'object presence', 'category_id': 1, 'formal': 0, 'template': '$object1 $direction $object2ه؟', 'id': 12 },

        { 'category': 'sport recognition', 'category_id': 2, 'formal': 1, 'template': 'این چه ورزشی است؟', 'id': 13 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 1, 'template': 'چه ورزشی انجام می‌شود؟', 'id': 14 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 1, 'template': 'این $image چه ورزشی را نشان می‌دهد؟', 'id': 15 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 1, 'template': 'در این $image چه ورزشی می‌بینید؟', 'id': 16 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 1, 'template': 'در این $image چه ورزشی مشاهده می‌شود؟', 'id': 17 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 1, 'template': 'در این $image چه ورزشی دیده می‌شود؟', 'id': 18 },

        { 'category': 'sport recognition', 'category_id': 2, 'formal': 0, 'template': 'چه ورزشیه؟', 'id': 19 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 0, 'template': 'چه ورزشی انجام میشه؟', 'id': 20 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 0, 'template': 'توی $image چه ورزشی می‌بینی؟', 'id': 21 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 0, 'template': 'توی $image چه ورزشی دیده میشه؟', 'id': 22 },
        { 'category': 'sport recognition', 'category_id': 2, 'formal': 0, 'template': 'این $image چه ورزشی رو نشون میده؟', 'id': 23 },

        { 'category': 'positional reasoning', 'category_id': 3, 'formal': 1, 'template': 'در این $image $object کجا است؟', 'id': 24 },
        { 'category': 'positional reasoning', 'category_id': 3, 'formal': 1, 'template': 'در این $image $object کجا قرار دارد؟', 'id': 25 },
        { 'category': 'positional reasoning', 'category_id': 3, 'formal': 1, 'template': 'در این $image $object کجا دیده می‌شود؟', 'id': 26 },

        { 'category': 'positional reasoning', 'category_id': 3, 'formal': 0, 'template': '$object توی $image کجا قرار داره؟', 'id': 27 },
        { 'category': 'positional reasoning', 'category_id': 3, 'formal': 0, 'template': '$object کجای $imageه؟', 'id': 28 },

        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 1, 'template': 'شخص چه احساسی دارد؟', 'id': 29 },
        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 1, 'template': 'احساس شخص چگونه به نظر می‌رسد؟', 'id': 30 },
        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 1, 'template': 'شخص درون تصویر ظاهراً دارای چه احساسی است؟', 'id': 31 },
        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 1, 'template': 'آیا شخص $feeling است؟', 'id': 32 },
        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 1, 'template': 'شخص $feeling است؟', 'id': 33 },

        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 0, 'template': 'شخص چه احساسی داره؟', 'id': 34 },
        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 0, 'template': 'احساس شخص چه‌طور به نظر میرسه؟', 'id': 35 },
        { 'category': 'sentiment understanding', 'category_id': 4, 'formal': 0, 'template': 'شخص $feelingه؟', 'id': 36 },

        { 'category': 'color attributes', 'category_id': 5, 'formal': 1, 'template': '$object چه رنگی است؟', 'id': 37 },
        { 'category': 'color attributes', 'category_id': 5, 'formal': 1, 'template': '$object چه رنگی دارد؟', 'id': 38 },
        { 'category': 'color attributes', 'category_id': 5, 'formal': 1, 'template': 'آیا رنگ $object $color است؟', 'id': 39 },
        { 'category': 'color attributes', 'category_id': 5, 'formal': 1, 'template': 'رنگ $object $color است؟', 'id': 40 },

        { 'category': 'color attributes', 'category_id': 5, 'formal': 0, 'template': '$object چه رنگیه؟', 'id': 41 },
        { 'category': 'color attributes', 'category_id': 5, 'formal': 0, 'template': '$object چه رنگی هست؟', 'id': 42 },
        { 'category': 'color attributes', 'category_id': 5, 'formal': 0, 'template': 'رنگ $object چیه؟', 'id': 43 },
        { 'category': 'color attributes', 'category_id': 5, 'formal': 0, 'template': '$object $colorه؟', 'id': 44 },

        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چه تعداد $object در $image وجود دارد؟', 'id': 45 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چه تعداد $object در $image است؟', 'id': 46 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چه تعداد $object در $image می‌بینید؟', 'id': 47 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چه تعداد $object در $image دیده می‌شود؟', 'id': 48 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چه تعداد $object در $image مشاهده می‌شود؟', 'id': 49 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چند $object در $image وجود دارد؟', 'id': 50 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چند $object در $image است؟', 'id': 51 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چند $object در $image می‌بینید؟', 'id': 52 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چند $object در $image دیده می‌شود؟', 'id': 53 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 1, 'template': 'چند $object در $image مشاهده می‌شود؟', 'id': 54 },

        { 'category': 'counting object', 'category_id': 6, 'formal': 0, 'template': 'چندتا $object توی $image هست؟', 'id': 55 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 0, 'template': 'چندتا $object توی $image وجود داره؟', 'id': 56 },
        { 'category': 'counting object', 'category_id': 6, 'formal': 0, 'template': 'چندتا $object توی $image دیده میشه؟', 'id': 57 },

        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': '$animate چه کاری انجام می‌دهد؟', 'id': 58 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': '$animate چه فعالیتی انجام می‌دهد؟', 'id': 59 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': '$animate چه عملی انجام می‌دهد؟', 'id': 60 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': 'در $image چه عملی انجام می‌شود؟', 'id': 61 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': 'در $image چه کاری در حال وقوع است؟', 'id': 62 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': '$animate در حال انجام چه کاری است؟', 'id': 63 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': '$animate در حال انجام چه فعالیتی است؟', 'id': 64 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 1, 'template': '$animate مشغول چه کاری‌ست؟', 'id': 65 },

        { 'category': 'activity recognition', 'category_id': 7, 'formal': 0, 'template': 'این $animate داره چیکار میکنه؟', 'id': 66 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 0, 'template': '$animate داره چیکار میکنه؟', 'id': 67 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 0, 'template': 'این $animate چیکار میکنه؟', 'id': 68 },
        { 'category': 'activity recognition', 'category_id': 7, 'formal': 0, 'template': '$animate چیکار میکنه؟', 'id': 69 },

        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something در $image است؟', 'id': 70 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something در $image دیده ‌می‌شود؟', 'id': 71 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something در $image وجود دارد؟', 'id': 72 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'در $image چیست؟', 'id': 73 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': '$direction $object چه $something قرار دارد؟', 'id': 74 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': '$direction $object چه $something است؟', 'id': 75 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': '$direction $object چه $something وجود دارد؟', 'id': 76 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': '$direction $object چه $something می‌بینید؟', 'id': 77 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': '$direction $object چه $something دیده می‌شود؟', 'id': 78 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': '$direction $object چه $something مشاهده می‌شود؟', 'id': 79 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something $direction $object قرار دارد؟', 'id': 80 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something $direction $object وجود دارد؟', 'id': 81 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something $direction $object می‌بینید؟', 'id': 82 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something $direction $object دیده می‌شود؟', 'id': 83 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 1, 'template': 'چه $something $direction $object مشاهده می‌شود؟', 'id': 84 },

        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': 'چه $something توی $image وجود داره؟', 'id': 85 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': 'چه $something توی $image هست؟', 'id': 86 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': 'چه $something توی $imageه؟', 'id': 87 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': '$direction $object چیه؟', 'id': 88 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': '$direction $object چی قرار داره؟', 'id': 89 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': '$direction $object چی وجود داره؟', 'id': 90 },
        { 'category': 'object detection', 'category_id': 8, 'formal': 0, 'template': 'چی $direction $objectه؟', 'id': 91 },

        { 'category': 'object material', 'category_id': 9, 'formal': 1, 'template': '$object از چه چیزی ساخته شده؟', 'id': 92 },
        { 'category': 'object material', 'category_id': 9, 'formal': 1, 'template': '$object از چه چیزی ساخته شده است؟', 'id': 93 },
        { 'category': 'object material', 'category_id': 9, 'formal': 1, 'template': '$object چه جنسی دارد؟', 'id': 94 },
        { 'category': 'object material', 'category_id': 9, 'formal': 1, 'template': 'جنس $object چیست؟', 'id': 95 },
        { 'category': 'object material', 'category_id': 9, 'formal': 1, 'template': 'جنس $object $material است؟', 'id': 96 },
        { 'category': 'object material', 'category_id': 9, 'formal': 1, 'template': 'آیا جنس $object $material است؟', 'id': 97 },

        { 'category': 'object material', 'category_id': 9, 'formal': 0, 'template': 'جنس $object چیه؟', 'id': 98 },
        { 'category': 'object material', 'category_id': 9, 'formal': 0, 'template': 'جنس $object چی هست؟', 'id': 99 },
        { 'category': 'object material', 'category_id': 9, 'formal': 0, 'template': '$object جنسش چیه؟', 'id': 100 },
        { 'category': 'object material', 'category_id': 9, 'formal': 0, 'template': 'جنس $object $material هست؟', 'id': 101 },
        { 'category': 'object material', 'category_id': 9, 'formal': 0, 'template': 'جنس $object $materialه؟', 'id': 102 },

        { 'category': 'gender recognition', 'category_id': 10, 'formal': 1, 'template': 'جنسیت شخص چیست؟', 'id': 103 },
        { 'category': 'gender recognition', 'category_id': 10, 'formal': 1, 'template': 'شخص موجود در $image چه جنسیتی دارد؟', 'id': 104 },
        { 'category': 'gender recognition', 'category_id': 10, 'formal': 1, 'template': 'آیا شخص موجود در $image $gender است؟', 'id': 105 },
        { 'category': 'gender recognition', 'category_id': 10, 'formal': 1, 'template': 'شخص موجود در $image $gender است؟', 'id': 106 },

        { 'category': 'gender recognition', 'category_id': 10, 'formal': 0, 'template': 'جنسیت شخص چیه؟', 'id': 107 },
        { 'category': 'gender recognition', 'category_id': 10, 'formal': 0, 'template': 'شخص توی $image چه جنسیتی داره؟', 'id': 108 },
        { 'category': 'gender recognition', 'category_id': 10, 'formal': 0, 'template': 'شخص توی $image $genderه؟', 'id': 109 },

    ];

    IMAGE = ['تصویر', 'عکس', 'شکل']
    DIRECTION = ['سمت راست', 'سمت چپ', 'بالای', 'پایین', 'زیر', 'روی', 'روبه روی', 'مقابل', 'کنار']
    SOMETHING = ['موجودی', 'چیزی', 'شیئی', 'اشیائی', 'موجوداتی', 'چیزهایی']
    COLOR = ['قرمز', 'نارنجی', 'قهوه‌ای', 'صورتی', 'سرخابی', 'زرد', 'سبز', 'آبی', 'سرمه‌ای', 'سیاه', 'مشکی', 'سفید', 'بنفش', 'خاکستری', 'طوسی']
    MATERIAL = ['چوبی', 'پلاستیکی', 'چرمی', 'فلزی', 'آهنی', 'چینی', 'بلور', 'شیشه‌ای', 'کاغذی']
    FEELING = ['ناراحت', 'خوشحال', 'عصبانی', 'خسته', 'ترسیده', 'خجالت زده', 'هیجان زده', 'متعجب', 'مضطرب', 'بی‌حوصله', 'خندان', 'گریان',]
    GENDER = ['مرد', 'زن', 'مذکر', 'مونث', 'دختر', 'پسر']


    constructor() {
        this.regexes = this.format_templates(this.Question_Templates)
    }

    match(question) {
        for (let template of this.regexes)
            if (question.match(template) !== null) {
                console.log("matched with " + template)
                return true;
            }
        return false;
    }

    format_templates(templates) {
        let new_templates = []
        for (let tem of templates) {
            let template = tem['template']
            template = template.replace(" ", "\\s+")  //replace all spaces with \s+

            let multiword = "[\\u0600-\\u06FF\\u0698\\u067E\\u0686\\u06AF\\w]+"

            template = template.replace(/\$object1/, multiword)
            template = template.replace(/\$object2/, multiword)
            template = template.replace(/\$object/, multiword)
            template = template.replace(/\$animate/, multiword)

            template = template.replace(/\$image/, "(" + this.IMAGE.join("|") + ")")
            template = template.replace(/\$direction/, "(" + this.DIRECTION.join("|") + ")")
            template = template.replace(/\$something/, "(" + this.SOMETHING.join("|") + ")")
            template = template.replace(/\$color/, "(" + this.COLOR.join("|") + ")")
            template = template.replace(/\$material/, "(" + this.MATERIAL.join("|") + ")")
            template = template.replace(/\$feeling/, "(" + this.FEELING.join("|") + ")")
            template = template.replace(/\$gender/, "(" + this.GENDER.join("|") + ")")

            template = template.replace("؟", "(\\s+)?([؟\\?\\.\\!]+)?"); //replace ؟ with a rejex accepting optional ? or ؟ or . or !
            template = "^(\\s+)?" + template + "(\\s+)?$";  ////FIX
            let pattern = new RegExp(template)
            new_templates.push(pattern)
            // console.log(pattern);
        }
        return new_templates;
    }
}