import { useHistory } from "react-router-dom";
import React from "react";
import "./ESFJ.style.scss";
import esfjPic from "../../../assets/images/types/esfj/ESFJ.jpg";
// import { useNavigate } from 'react-router-dom';

import Taylor from "../../../assets/images/types/esfj/esfj1.png";
import SpongeBob from "../../../assets/images/types/esfj/esfj2.png";
import Joe from "../../../assets/images/types/esfj/esfj3.png";
import Woody from "../../../assets/images/types/esfj/esfj4.png";
import Rose from "../../../assets/images/types/esfj/esfj5.png";
import JHope from "../../../assets/images/types/esfj/esfj6.png";
import Reiner from "../../../assets/images/types/esfj/esfj7.png";
import Paul from "../../../assets/images/types/esfj/esfj8.png";
import Skyler from "../../../assets/images/types/esfj/esfj9.png";
import Snow from "../../../assets/images/types/esfj/esfj10.png";

const ESFJ = () => {
  const history = useHistory();
  //   const navigate = useNavigate();
  return (
    <div className="back-esfj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={esfjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ESFJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ESFJ: </b>
              خوش‌قلب، خوش‌صحبت، محبوب و مسئولیت‌پذیر است. از سنین پایین مشارکت
              و همکاری با دیگران را به‌خوبی یاد می‌گیرد و از اعضای فعال گروه‌ها
              و تیم‌ها می‌شود. همیشه می‌خواهد یک کار نیک برای دیگران انجام دهد.
              نیاز به تشویق و قدردانی از طرف دیگران دارد. علاقهٔ کمی به نظریه‌ها
              و موضوعاتِ فنی دارد. استعدادش در زمینه‌هایی شکوفا می‌شود که به‌طور
              مستقیم و چشمگیری در زندگی او تاثیر بگذارد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ESFJ:</div>
            <div className="jobs">
              بانکدار، دفتردار، کارگر خدمات نظافتی، مربی، مشاور، دستیارِ
              دندانپزشک، مهمان‌دارِ هواپیما، آرایشگرِ مو، مددکار بهداشتی، مدرس
              منابعِ انسانی، طراح داخلی، برنامه‌ریز کالا، پرستار، کارمند آژانس
              ملک و املاک، خدمتگزار مذهبی، کارگر رستوران، منشی، مددکار اجتماعی،
              آموزگار، بازاریاب
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                آگاه و هوشیار هستند و به شیوه‌ای سازمان‌یافته کارشان را انجام
                می‌دهند.{" "}
              </li>
              <li>کارها را به شیوه‌ای برنامه‌ریزی‌شده انجام می‌دهند. </li>
              <li>
                به جزئیات بها داده و دقت می‌کنند همه چیز با کارایی انجام شود.
              </li>
              <li>بازیگران گروه خوبی هستند. </li>
              <li>
                دوست دارند همه کسانی که با آنها همکار هستند در شمار دوستان آن‌ها
                باشند.{" "}
              </li>
              <li>
                دوست دارند تصمیم‌گیری ها سریعا انجام شوند، درعین حالی که همه
                تصمیمات را خودشان بگیرند.
              </li>
              <li>
                اهل کمک و تعاون هستند، خدمت‌گزار هستند و دوست دارند به دیگران
                کمک کنند.{" "}
              </li>
              <li>تصمیم‌گیری بر اساس باورهای نظری و فلسفی را دوست ندارند. </li>
              <li>در برخورد با دیگران گرم و صمیمی هستند.</li>
              <li>
                بسیار معاشرتی بوده و دوست دارند کارهایی بکنند که دیگران در آن
                حضور داشته‌باشند.{" "}
              </li>
              <li>کارکردن در محیطی عاری از تضاد و تنش را دوست دارند.</li>
              <li>
                خواهان مشاغل مدیریتی نیستند اما وقتی این مقام‌ها را به آن‌ها
                بدهند، تلاش فراوانی از خود به خرج می‌دهند.{" "}
              </li>
              <li>
                به مقامات بالادست احترام گذارده و معتقدند که دیگران هم باید به
                مقامات احترام بگذارند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ESFJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Taylor} alt="Taylor Swift" />
                  <div className="intru">
                    <div className="name">Taylor Swift</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Rose} alt="Rosé (BLACKPINK)" />
                  <div className="intru">
                    <div className="name">Rose (BLACKPINK)</div>
                    <div className="role">خوانندهٔ k-pop</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Joe} alt="Joe Biden" />
                  <div className="intru">
                    <div className="name">Joe Biden</div>
                    <div className="role">سیاستمدار</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Woody} alt="Woody" />
                  <div className="intru">
                    <div className="name">Woody</div>
                    <div className="role">Toy Story (1995)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={SpongeBob} alt="SpongeBob SquarePants" />
                  <div className="intru">
                    <div className="name">SpongeBob</div>
                    <div className="role">باب اسفنجی (1999)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={JHope} alt="J-Hope (BTS)" />
                  <div className="intru">
                    <div className="name">J-Hope (BTS)</div>
                    <div className="role">خوانندهٔ k-pop</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Reiner} alt="Reiner Braun" />
                  <div className="intru">
                    <div className="name">Reiner Braun</div>
                    <div className="role">Attack on Titan</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Paul} alt="Paul McCartney" />
                  <div className="intru">
                    <div className="name">Paul McCartney</div>
                    <div className="role">خوانندهٔ راک</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Skyler} alt="Skyler White" />
                  <div className="intru">
                    <div className="name">Skyler White</div>
                    <div className="role">Breaking Bad (2008)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Snow} alt="Snow White" />
                  <div className="intru">
                    <div className="name">Snow White</div>
                    <div className="role">سفیدبرفی و هفت کوتوله (1937)</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ESFJ;
