
export const __APIPath = {
    questions: {
        getquestions: "list-questions/",
    },

    createRespondent:{
        create:"create-resp/",
    },

    submit:{
        submit:"submit-resp/"
    }
};