import { useHistory } from "react-router-dom";
import React from "react";
import "./ESTP.style.scss";
import estpPic from "../../../assets/images/types/estp/ESTP.jpg";
import Thor from "../../../assets/images/types/estp/estp1.png";
import Donald from "../../../assets/images/types/estp/estp2.png";
import Merida from "../../../assets/images/types/estp/estp3.png";
import Gilbert from "../../../assets/images/types/estp/estp4.png";
import Damon from "../../../assets/images/types/estp/estp5.png";
import Tokyo from "../../../assets/images/types/estp/estp6.png";
import Sandy from "../../../assets/images/types/estp/estp7.png";
import Disgust from "../../../assets/images/types/estp/estp8.png";
import Angelina from "../../../assets/images/types/estp/estp9.png";
import Madonna from "../../../assets/images/types/estp/estp10.png";
// import { useNavigate } from 'react-router-dom';

const ESTP = () => {
  //   const navigate = useNavigate();
  const history = useHistory();
  return (
    <div className="back-estp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={estpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ESTP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ESTP: </b>
              واقع‌گرا و به‌ندرت نگران می‌شود و یا در کارها عجله می‌کند.از هر چه
              پیش آید لذت می‌برد. معمولا به موضوع‌های فنی علاقه نشان می‌دهد.
              دوست دارد در محافل ورزشی مختلف شرکت کند. ممکن است کمی کند و خونسرد
              باشد. به ریاضیات و علوم فقط هنگامی که نیاز باشد علاقه نشان می‌دهد.
              علاقه‌ای به موضوع‌های تشریحی طولانی ندارد. استعداد در زمینهٔ
              موضوعاتی مانند اصلاح کارها و راه‌حل‌های زیرکانه در تصحیح امور ظریف
              تبحر خاصی از خود نشان می‌دهد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ESTP:</div>
            <div className="jobs">
              وکیل دعاوی، نجار، مربی، کارگر ساختمان، کارآگاه، تکنسینِ بخش
              اورژانس، مهندس، کارفرما، زارع، مأمور آتش‌نشانی، مشاور مدیریت،
              مکانیک، افسر ارتش یا پلیس، ورزشکار حرفه‌ای، نمایندهٔ فروش، واسطهٔ
              اوراق بهادار و بورس، تکنسین، اپراتور حمل و نقل
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>مبتکر، پر مایه و عمل‌گرا هستند.</li>
              <li>دوست دارند با حقایق روبه‌رو شوند. به‌شدت نتیجه‌گرا هستند.</li>
              <li>در برخورد با مسائل از منطق استفاده می‌کنند.</li>
              <li>
                از این که در آخرین لحظه کارها را انجام بدهند، لذت می‌برند.
              </li>
              <li>
                زیر فشار بسیار خونسرد هستند. توانایی آن که موقعیت‌های پرفشار را
                نرم و ملایم بکنند را دارند.
              </li>
              <li>رفتاری محکم و فشرده دارند.</li>
              <li>دنبال موفقیت و شناخت می‌گردند.</li>
              <li>
                رقابت‌جو بوده و در بحران‌ها و گرفتاری‌ها به‌هیجان می‌آیند.
              </li>
              <li>
                تنها در صورتی مطابق مقررات رفتار می‌کنند که بتوانند به
                خواسته‌های خود برسند.
              </li>
              <li>متقاعدکننده هستند و خودشان را به سرعت بالا می‌کشند.</li>
              <li>اغلب چند پروژه را با هم انجام می‌دهند.</li>
              <li>از مشاغل پرانرژی و پراسترس لذت می‌برند .</li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ESTP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Thor} alt="Thor" />
                  <div className="intru">
                    <div className="name">Thor</div>
                    <div className="role">Thor (2011)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Donald} alt="Donald Trump" />
                  <div className="intru">
                    <div className="name">Donald Trump</div>
                    <div className="role">سیاستمدار</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Merida} alt="Merida of Dunbroch" />
                  <div className="intru">
                    <div className="name">Merida of Dunbroch</div>
                    <div className="role">Brave (2012)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Gilbert} alt="Gilbert Blythe" />
                  <div className="intru">
                    <div className="name">Gilbert Blythe</div>
                    <div className="role">Anne with an E (2017)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Damon} alt="Damon Salvatore" />
                  <div className="intru">
                    <div className="name">Damon Salvatore</div>
                    <div className="role">The Vampire Diaries (2009)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Tokyo} alt="Tokyo" />
                  <div className="intru">
                    <div className="name">Tokyo</div>
                    <div className="role">Money Heist (2017)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Sandy} alt="Sandy Cheeks" />
                  <div className="intru">
                    <div className="name">Sandy Cheeks</div>
                    <div className="role">باب اسفنجی (2019)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Disgust} alt="Disgust" />
                  <div className="intru">
                    <div className="name">Disgust</div>
                    <div className="role">Inside Out (2015)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Angelina} alt="Angelina Jolie" />
                  <div className="intru">
                    <div className="name">Angelina Jolie</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Madonna} alt="Madonna" />
                  <div className="intru">
                    <div className="name">Madonna</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ESTP;
