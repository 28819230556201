import { useHistory } from "react-router-dom";
import React from "react";
import "./ENFP.style.scss";
import enfpPic from "../../../assets/images/types/enfp/ENFP.jpg";
// import { useNavigate } from "react-router-dom";

import Anna from "../../../assets/images/types/enfp/enfp1.png";
import Anne from "../../../assets/images/types/enfp/enfp2.png";
import John from "../../../assets/images/types/enfp/enfp3.png";
import Kanye from "../../../assets/images/types/enfp/enfp4.png";
import Michael from "../../../assets/images/types/enfp/enfp5.png";
import Jennifer from "../../../assets/images/types/enfp/enfp6.png";
import Phoebe from "../../../assets/images/types/enfp/enfp7.png";
import Po from "../../../assets/images/types/enfp/enfp8.png";
import Ariel from "../../../assets/images/types/enfp/enfp9.png";
import Naruto from "../../../assets/images/types/enfp/enfp10.png";

const ENFP = () => {
  const history = useHistory();
  //   const navigate = useNavigate();
  return (
    <div className="back-enfp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={enfpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ENFP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ENFP: </b>
              پرحرارت و پرانرژی می‌باشد و روحیهٔ بالایی دارد. دارای قوهٔ تخیلِ
              بالا و مبتکر می‌باشد. تقریبأ توانایی هرکاری که به آن علاقه‌مند است
              را دارد. در پیداکردن راه‌حل برای هر نوع مشکل سریع عمل می‌کند و
              آماده کمک به هر کسی که در مشکل باشد، هست. به جای این که خود را از
              قبل آماده کند به توانایی‌های بالقوه‌اش متکی می‌باشد. تواناییِ
              یافتن دلایلِ کافی برای کارهای خویش را دارد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ENFP:</div>
            <div className="jobs">
              هنرمند، بازیگر، روحانی، معلمِ دینی، برنامه‌ریز همایش، مشاور،
              کارفرما،‌ مسئول خدماتِ غذایی، متخصص توسعهٔ منابع انسانی، طراحِ
              داخلی، روزنامه‌نگار، بازاریاب، متخصص رسانه، سخنرانِ انگیزشی،
              روان‌درمانگر، کارمندِ روابطِ عمومی، مسئول فروش، آموزگار، نویسنده
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                در زمینهٔ توجه به جزئیات حتی در زمینه‌های مالی بی‌علاقه است.{" "}
              </li>
              <li>
                به دنبال تنوع و چالش‌اند. پیوسته در این فکر که روش تخیلی را برای
                انجام‌دادن کارهایشان انتخاب کنند.{" "}
              </li>
              <li>
                می‌توانند رهبرانی باجذبه و الهام‌بخش باشند. آنها با انرژی و
                پشتکار خود به دیگران انگیزه می‌دهند.{" "}
              </li>
              <li>دوست دارند با اشخاصِ خلاق و پرانرژی کار کنند. </li>
              <li>
                برای آن‌ها شروع‌کردن کار از تمام‌کردن آن اهمیت بیشتری دارد.{" "}
              </li>
              <li>
                وقتی درگیرِ پروژهٔ خود می‌شوند، زمان و نیازهای جسمانی خود را
                فراموش می‌کنند.{" "}
              </li>
              <li>می‌توانند روی پای خود بایستند و مستقلانه فکر کنند. </li>
              <li>
                در بسیاری از زمینه‌ها بااستعداد هستند اما در کم‌کردن حیطهٔ توجه
                خود با دشواری روبه‌رو می‌شوند.{" "}
              </li>
              <li>
                تحتِ تأثیرِ جزئیات کلافه می‌شوند. ترجیح می‌دهند شخصِ دیگری به
                جزئیات رسیدگی کند.{" "}
              </li>
              <li>دوست دارند با سرعت کار کنند. </li>
              <li>می‌خواهند نظارت بر کار آن‌ها کمترین مقدار ممکن باشد.</li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ENFP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Anna} alt="Anna" />
                  <div className="intru">
                    <div className="name">Anna</div>
                    <div className="role">Frozen (2013)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Anne} alt="Anne" />
                  <div className="intru">
                    <div className="name">Anne Shirley</div>
                    <div className="role">Anne with an E (2017)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={John} alt="John" />
                  <div className="intru">
                    <div className="name">John Lennon</div>
                    <div className="role">خوانندهٔ راک</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kanye} alt="Kanye" />
                  <div className="intru">
                    <div className="name">Kanye West</div>
                    <div className="role">خوانندهٔ رپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Michael} alt="Michael Scott" />
                  <div className="intru">
                    <div className="name">Michael Scott</div>
                    <div className="role">The Office (2005)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Jennifer} alt="Jennifer" />
                  <div className="intru">
                    <div className="name">Jennifer Lawrence</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Phoebe} alt="Phoebe Buffay" />
                  <div className="intru">
                    <div className="name">Phoebe Buffay</div>
                    <div className="role">Friends (1994)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Po} alt="Po" />
                  <div className="intru">
                    <div className="name">Po</div>
                    <div className="role">Kung Fu Panda (2008)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Ariel} alt="Ariel" />
                  <div className="intru">
                    <div className="name">Ariel</div>
                    <div className="role">The Little Mermaid (1989)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Naruto} alt="Naruto Uzumaki" />
                  <div className="intru">
                    <div className="name">Naruto Uzumaki</div>
                    <div className="role">Naruto</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ENFP;
