import './PageFooter.css';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function PageFooter(props) {
    return <div className="main-page-footer">
        <div className="row" >
            <div className="col-12 col-md-6" >
                <div className="section" style={{marginRight:"20%"}}>
                    <h1>IUST NLP Lab</h1>
                    <p>Iran University of Science and Technology NLP Group</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2166.1948619991367!2d51.50803396807819!3d35.74203378880424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e032e4dab694f%3A0x7c779a1cc0e0d4c9!2sSchool%20of%20Computer%20Engineering!5e0!3m2!1sen!2s!4v1658564934894!5m2!1sen!2s" 
                     style={{border:0, height:"25vh", width:"100%", margin:"2vh 0"}} 
                    allowfullscreen="" loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3" >
                <div className="section">
                    <h1>Contact Us</h1>
                    <div className="item">
                        <RoomIcon />
                        <p>Tehran, Hengam St., University St., Univerity of Science and Technology, Department of Computer Engineering</p>
                    </div>
                    <div className="item">
                        <PhoneIcon style={{fontSize: "1.4rem"}}/>
                        <p>+982177777777</p>
                        <p>+982177777787</p>
                    </div>
                    <div className="item">
                        <EmailIcon style={{fontSize: "1.45rem"}}/>
                        <p>idk@iust.ac.ir</p>
                        <p>idkk@comp.iust.ac.ir</p>
                        <p>idc@iust.ac.ir</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3" >
                <div className="section">
                    <h1>Follow Us</h1>
                    <div className="follow-us">
                        <a target="_blank" href="https://www.linkedin.com/company/natural-language-processing-lab-iust"><LinkedInIcon /></a>
                        <a target="_blank" href="https://github.com/VQA-Ahmadi-Roshan"><GitHubIcon /></a>
                    </div>
                </div>
            </div>
        </div>
        <p className="copy-right">Copyright © 2022 IUST NLP Lab</p>
    </div>
}


export default PageFooter;