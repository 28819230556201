import { useHistory } from "react-router-dom";
import React from "react";
import "./ISTP.style.scss";
import istpPic from "../../../assets/images/types/istp/ISTP.jpg";
import Levi from "../../../assets/images/types/istp/istp1.png";
import Kang from "../../../assets/images/types/istp/istp2.png";
import Eminem from "../../../assets/images/types/istp/istp3.png";
import Shrek from "../../../assets/images/types/istp/istp4.png";
import Mulan from "../../../assets/images/types/istp/istp5.png";
import Arya from "../../../assets/images/types/istp/istp6.png";
import Taehyun from "../../../assets/images/types/istp/istp7.png";
import Phillip from "../../../assets/images/types/istp/istp8.png";
import Robin from "../../../assets/images/types/istp/istp9.png";
import Kristen from "../../../assets/images/types/istp/istp10.png";
// import { useNavigate } from "react-router-dom";

const ISTP = () => {
  const history = useHistory();
  //   const navigate = useNavigate();
  return (
    <div className="back-istp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={istpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ISTP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ISTP: </b>
              افرادی با نگاه نافذ، آرام و محتاط، زندگی را از زاویه‌ای جدا و
              کنجکاوی منحصربه‌فرد تجزیه و تحلیل می‌کند، اغلب شوخ‌طبعی بسیار جالب
              از خود نشان می‌دهند. علاقه‌مند به اصول علمی، علت و معلول یا چگونه
              و چرایی موضوعات فنی و تجزیه و تحلیل آن‌ها می‌باشد. هیچ‌گاه خود را
              بیش‌ازحد خسته نمی‌کند، زیرا به نظرش تلف‌کردن نیرو و انرژی بیهوده
              است و در هیچ شرایطی به‌صلاح نمی‌باشد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ISTP:</div>
            <div className="jobs">
              ویراستار، سردبیر، نجار، مربی، برنامه‌نویس کامپیوتر، کاگر ساختمانی،
              دستیار دندان‌پزشک، دستیار پزشک، اقتصاددان، مامور آتش‌نشانی،
              زیست‌شناس، مکانیک، مامور ارتش، دستیار وکیل، کارآگاه خصوصی، افسر
              پلیس، تعمیرات‌چی، فلزکار، تحلیل‌گر امنیتی، اپراتور حمل و نقل
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>به واقعیت‌های ملموس واقع در پیرامون خود بها می‌دهند.</li>
              <li>به زمان توجه دارند. روی چیزهای غیرلازم وقت صرف نمی‌کنند.</li>
              <li>
                ابهام را دوست ندارند. حوصلهٔ گوش‌دادن به جزئیات غیرلازم را
                ندارند.
              </li>
              <li>
                دوست دارند مسائل را حل‌وفصل کنند. ترجیح می‌دهند مسائل را به شکلی
                منظم و بر اساس نظم ترتیب حل‌وفصل نمایند.
              </li>
              <li>
                به اعتقاد آن‌ها مقامات و سلسه‌مراتب در سازمان‌های کاری صرورتی
                ندارند و در برابر آن‌ها به‌شدت مقاوم هستند.
              </li>
              <li>
                از سرپرستی‌کردن و سرپرستی‌شدن دلِ خوشی ندارند. ترجیح می‌دهند به
                تنهایی کار کنند، مگر آن که کسی باشد که توانمندی‌اش به مراتب از
                آن‌ها بیشتر باشد تا تمایل به همکاری با او را نشان دهند.
              </li>
              <li>ترجیح می‌دهند در بحران آرام باشند.</li>
              <li>می‌توانند با ابزار به راحتی کار کنند.</li>
              <li>
                در زمینه‌های مورعلاقه به جزئیات بها می‌دهند. می‌توانند به سرعت
                از داده‌های آماری به طور منطقی سود بجویند.
              </li>
              <li>
                اگر کارشان با چالش پیوسته روبه‌رو نباشند از آن مکدر می‌شوند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ISTP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Levi} alt="Levi Ackerman" />
                  <div className="intru">
                    <div className="name">Levi Ackerman</div>
                    <div className="role">Attack on Titan</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kang} alt="Kang Sae-byeok / No. 067" />
                  <div className="intru">
                    <div className="name">Kang Sae-byeok/No.067</div>
                    <div className="role">Squid Game (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Eminem} alt="Eminem" />
                  <div className="intru">
                    <div className="name">Eminem</div>
                    <div className="role">خوانندهٔ رپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Shrek} alt="Shrek" />
                  <div className="intru">
                    <div className="name">Shrek</div>
                    <div className="role">Shrek (2001)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Mulan} alt="Fa Mulan" />
                  <div className="intru">
                    <div className="name">Fa Mulan</div>
                    <div className="role">Mulan (1998)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Arya} alt="Arya Stark" />
                  <div className="intru">
                    <div className="name">Arya Stark</div>
                    <div className="role">Game of Thrones (2011)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Taehyun} alt="Taehyun (TXT)" />
                  <div className="intru">
                    <div className="name">Taehyun (TXT)</div>
                    <div className="role">خواننده K-Pop</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Phillip} alt="Phillip “Lip” Gallagher" />
                  <div className="intru">
                    <div className="name">Phillip “Lip” Gallagher</div>
                    <div className="role">Shameless US (2011)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kristen} alt="Kristen Stewart" />
                  <div className="intru">
                    <div className="name">Kristen Stewart</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Robin} alt="Robin Scherbatsky" />
                  <div className="intru">
                    <div className="name">Robin Scherbatsky</div>
                    <div className="role">How I Met Your Mother (2005)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ISTP;
