import { useHistory } from "react-router-dom";
import React from "react";
import "./ENTJ.style.scss";
import entjPic from "../../../assets/images/types/entj/ENTJ.jpg";
// import { useNavigate } from "react-router-dom";

import Steve from "../../../assets/images/types/entj/entj1.png";
import Thomas from "../../../assets/images/types/entj/entj2.png";
import Bill from "../../../assets/images/types/entj/entj3.png";
import Cruella from "../../../assets/images/types/entj/entj4.png";
import Lord from "../../../assets/images/types/entj/entj5.png";
import Adele from "../../../assets/images/types/entj/entj6.png";
import Napoleon from "../../../assets/images/types/entj/entj7.png";
import Sheldon from "../../../assets/images/types/entj/entj8.png";
import Berlin from "../../../assets/images/types/entj/entj9.png";
import Light from "../../../assets/images/types/entj/entj10.png";

const ENTJ = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-entj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={entjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ENTJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ENTJ: </b>
              پرنشاط، صادق، صبور و دلسوز و موفق در مطالعات و آموزش‌های تحصیلی
              می‌باشد. قدرتِ رهبری در فعالیت‌های مختلف را دارد. معمولا در
              کارهایی که نیاز به منطق زیاد و بیان هوشیارانه دارد مثل صحبت‌کردن
              در یک جمع موفق می‌شود. اطلاعات کافی راجع به موضوعات روز دارند و
              همچنان به منابع اطلاعاتی خود می‌افزاید. گاهی اوقات اعتمادبه‌نفس و
              روحیهٔ قوی‌تری نسبت به تجربهٔ واقعی خود در زندگی به دیگران ارائه
              می‌نماید.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ENTJ:</div>
            <div className="jobs">
              مدیر یا مؤسس شرکت، مدیرعامل، مشاورِ رایانه، وكيل، قاضی، مدیر یا
              رئيس تجاری، استاد دانشگاه
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                دوست دارند مسئول باشند، اغلب مشاغل سرپرستی، مدیریت و رهبری را
                برعهده دارند. آنها مایل هستند که مسئول بهره‌برداری از
                توانایی‌های ویژه خود باشند .
              </li>
              <li>
                رهبرانِ موافق هستند. برای رهبری و بنیان‌نهادن سازمان‌ها بسیار
                مناسب هستند .
              </li>
              <li>
                موانع را به عنوان چالش در نظر می‌گیرند. آن‌ها این توانایی را
                دارند که مشکلات سازمان را آشکارا شناسایی کرده و راه‌حل‌هایی
                ابتکاری برای کوتاه‌مدت یا بلندمدت، بسته به نیاز سازمان ارائه
                کنند .
              </li>
              <li>
                برای رسیدن به هدف‌هایشان به روش‌های استراتژیک توجه می‌کنند .
              </li>
              <li>
                حقایق و استراتژی‌ها را از قبل سازمان‌دهی می‌کنند و دوست دارند که
                کارهایشان زودتر از زمان مقرر تمام شود .{" "}
              </li>
              <li>
                عدمِ کارایی را دوست ندارند، از نداشتن صلاحیت، از نداشتن قاطعیت
                بیزارند.
              </li>
              <li>رقابت‌جو هستند. اغلب معتاد به کار می‌باشند .</li>
              <li>دوست دارند با اشخاصِ قوی، مستقل و نتیجه‌گرا کار کنند .</li>
              <li>بدون این که دلیل منطقی وجود داشته‌باشد تسلیم نمی‌شوند .</li>
              <li>
                دوست دارند عهده‌دار امور باشند، تصمیم‌گیرنده باشند. مایل نیستند
                از دیگران دستورالعمل و راهنمایی بگیرند. مگر آن که آن شخص از دانش
                و صلاحیت بیش‌تری برخوردار باشد .
              </li>
              <li>
                برای مسائل راه‌حل‌های منطقی ارائه می‌دهند. دوست دارند با
                دستورالعمل‌ها و راهنمایی‌های روشن و واضح کار کنند .
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ENTJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Steve} alt="Steve" />
                  <div className="intru">
                    <div className="name">Steve Jobs</div>
                    <div className="role">بنیان‌گذار شرکت اپل</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Thomas} alt="Thomas" />
                  <div className="intru">
                    <div className="name">Thomas Shelby</div>
                    <div className="role">Peaky Blinders (2013)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Bill} alt="Bill" />
                  <div className="intru">
                    <div className="name">Bill Gates</div>
                    <div className="role">بنیان‌گذار شرکت مایکروسافت</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Cruella} alt="Cruella" />
                  <div className="intru">
                    <div className="name">Cruella de Vil</div>
                    <div className="role">Cruella (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Lord} alt="Lord" />
                  <div className="intru">
                    <div className="name">Lord Voldemort</div>
                    <div className="role">Harry Potter (2007)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Adele} alt="Adele" />
                  <div className="intru">
                    <div className="name">Adele</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Napoleon} alt="Napoleon" />
                  <div className="intru">
                    <div className="name">Napoléon Bonaparte</div>
                    <div className="role">امپراطور فرانسه</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Sheldon} alt="Sheldon" />
                  <div className="intru">
                    <div className="name">Sheldon J. Plankton</div>
                    <div className="role">باب اسفنجی (1999)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Berlin} alt="Berlin" />
                  <div className="intru">
                    <div className="name">Berlín</div>
                    <div className="role">Money Heist (2017)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Light} alt="Light" />
                  <div className="intru">
                    <div className="name">Light Yagami</div>
                    <div className="role">Death Note</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ENTJ;
