import React, { useState, useEffect, useRef, Component } from "react";
// import { useNavigate } from 'react-router-dom';
import { get, responseValidator, post } from "../../scripts/api";
import { APIPath, RoutePath } from "../../data";
import Button from "../../utilities/components/button/button.index";
import { Radio, Space } from "antd";
import "./questionnaire.style.scss";
import "antd/dist/antd.min.css";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

toast.configure();
const Questionnaire = (props) => {
  const [questions, setQuestions] = useState([]);
  const [value, setValue] = useState();
  const [currentValue, setCurrentValue] = useState([]);
  const [type, setType] = useState();
  const myRef = React.createRef();
  // const navigate = useNavigate();
  const history = useHistory();

  //////////////////////////////

  const count = [
    "۱",
    "۲",
    "۳",
    "۴",
    "۵",
    "۶",
    "۷",
    "۸",
    "۹",
    "۱۰",
    "۱۱",
    "۱۲",
    "۱۳",
    "۱۴",
    "۱۵",
    "۱۶",
    "۱۷",
    "۱۸",
    "۱۹",
    "۲۰",
    "۲۱",
    "۲۲",
    "۲۳",
    "۲۴",
    "۲۵",
    "۲۶",
    "۲۷",
    "۲۸",
    "۲۹",
    "۳۰",
    "۳۱",
    "۳۲",
    "۳۳",
    "۳۴",
    "۳۵",
    "۳۶",
    "۳۷",
    "۳۸",
    "۳۹",
    "۴۰",
    "۴۱",
    "۴۲",
    "۴۳",
    "۴۴",
    "۴۵",
    "۴۶",
    "۴۷",
    "۴۸",
    "۴۹",
    "۵۰",
    "۵۱",
    "۵۲",
    "۵۳",
    "۵۴",
    "۵۵",
    "۵۶",
    "۵۷",
    "۵۸",
    "۵۹",
    "۶۰",
  ];

  useEffect(() => {
    get(APIPath.questions.getquestions).then((data) => {
      if (responseValidator(data.status) && data.data) {
        setQuestions(data.data);
        data.data.map((q) => currentValue.push(0));
      } else {
        toast.error("سیستم با خطا مواجه شد، مجددا تلاش کنید");
      }
      //console.log("currentValue:", currentValue);
    });
  }, []);

  const changeAnswer = (e, q) => {
    currentValue[q.position - 1] = e.target.value;
    let newAnswer = [];
    currentValue.forEach((e) => {
      newAnswer.push(e);
    });
    setCurrentValue(newAnswer);
    //console.log("Current",currentValue)
    //console.log("newAns",newAnswer)
  };

  const submit = () => {
    console.log("currentValue:", currentValue);
    /*currentValue.shift();*/
    if (currentValue.includes(0)) {
      const tmp = currentValue.indexOf(0);
      const tmp2 = tmp + 1;
      let checkQ = "شما به سوال " + tmp2 + " پاسخ نداده‌اید.";
      toast.error(checkQ);
      //currentValue.unshift(0);
    } else {
      props.save(currentValue);
      history.push(RoutePath.UserInfo.Form);
      // post(APIPath.questions.submit, {
      //   respondent: respo,
      //   answers: currentValue,
      // }).then((data) => {
      //   if (responseValidator(data.status) && data.data) {
      //     setType(data.data.ptype);
      //     console.log(data.data.ptype);
      //     window.location = "/" + data.data.ptype;
      //   } else {
      //     // ???
      //     toast.error("مجددا تلاش کنید.");
      //   }
      // });
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const questionsPerPage = 15;
  const pagesVisited = pageNumber * questionsPerPage;
  const displayQuestions = questions
    .slice(pagesVisited, pagesVisited + questionsPerPage)
    .map((q) => {
      return (
        <div className="q" key={q.position}>
          <div className="counter">{count[q.position - 1]}</div>
          <div className="q_text">
            <Radio.Group
              onChange={(e) => changeAnswer(e, q)}
              value={currentValue[q.position - 1]}
              buttonStyle="solid"
            >
              <Space direction="vertical">
                <Radio.Button value={1}>۱. {q.option1}</Radio.Button>
                <Radio.Button value={2}>۲. {q.option2}</Radio.Button>
              </Space>
            </Radio.Group>
          </div>
        </div>
      );
    });

  const pageCount = Math.ceil(questions.length / questionsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
    myRef.current.scrollTo(0, 0);
  };

  return (
    <div className="questionnaire">
      <div ref={myRef} className="background scroll ">
        <div className="content">
          <div className="header">
            توی هر ردیف یک جفت‌ عبارت وجود داره. لطفاً از بین این دو عبارت هر
            کدوم که شما رو بهتر توصیف می‌کنه مشخص کنید.
            <li>
              <b>
                توجه کنید که پاسخ صحیح یا غلط وجود نداره و کافیه پاسختون صادقانه
                باشه.
              </b>
            </li>
          </div>
          <div className="questions">{questions && displayQuestions}</div>

          <div className="paginate">
            <ReactPaginate
              previousLabel={"قبلی"}
              nextLabel={"بعدی"}
              onPageChange={changePage}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              pageClassName={"hide"}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            <button className="btn" type="button" onClick={submit}>
              پر کردم
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
