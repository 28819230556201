import { useHistory } from "react-router-dom";
import React from "react";
import "./ISFJ.style.scss";
import isfjPic from "../../../assets/images/types/isfj/ISFJ.jpg";

import Bruno from "../../../assets/images/types/isfj/isfj1.png";
import Morty from "../../../assets/images/types/isfj/isfj2.png";
import Cinderella from "../../../assets/images/types/isfj/isfj3.png";
import Selena from "../../../assets/images/types/isfj/isfj4.png";
import Bob from "../../../assets/images/types/isfj/isfj5.png";
import Steve from "../../../assets/images/types/isfj/isfj6.png";
import Pam from "../../../assets/images/types/isfj/isfj7.png";
import John from "../../../assets/images/types/isfj/isfj8.png";
import Soobin from "../../../assets/images/types/isfj/isfj9.png";
import Marge from "../../../assets/images/types/isfj/isfj10.png";
// import { useNavigate } from 'react-router-dom';

const ISFJ = () => {
  //   const navigate = useNavigate();
  const history = useHistory();
  return (
    <div className="back-isfj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={isfjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ISFJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ISFJ: </b>
              آرام، خوش برخورد، مسئولیت‌پذیر و وظیفه‌شناس است. خالصانه برای
              انجام وظایفش کار می‌کند و در خدمت دوستان و اطرافیانش می‌باشد. فردی
              بسیار دقیق و زحمت‌کش می‌باشد. ممکن است در جهت‌گیری موضوعات و
              مهارت‌های فنی احتیاج به زمان بیش‌تری داشته‌باشد. چون علاقه‌اش به
              کارهای فنی نسبتا پایین می‌باشد. نسبت به وظایف و کارهای روزمره
              بسیار صبور است. وفادار و محتاط و در مورد احساسات دیگران بسیار حساس
              است.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ENFP:</div>
            <div className="jobs">
              دفتردار، سرپرستِ کارمندان، اپراتورِ کامپیوتر، مشاور، متخصصِ رژیم
              غذایی، کارمند خدمات بهداشتی، کارگرِ منزل، کتاب‌خانه‌دار، پرستار،
              مدیرِ دفتر، چشم‌پزشک، دستیارِ وکیل، داروساز، مامورِ رسیدگی به امرِ
              زندانیان، مدرسِ علوم دینی، خرده‌فروش، منشی، مددکار اجتماعی،
              آموزگار، دام‌پزشک
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                به جزئیات توجه ویژه دارند. دقیق و علاقه‌مند به حقایق هستند.
              </li>
              <li>
                سعی می‌کنند همه کارها را عالی انجام دهند، اشتباهات کوچک برای‌شان
                نابخشودنی است.
              </li>
              <li>
                محتاط هستند. تمام تلاش خود را می‌کنند تا کارشان را انجام دهند.
              </li>
              <li>از همکاران و کارکنان زیردست خود حمایت می‌کنند.</li>
              <li>در دستور‌دادن، رک‌بودن و کمک‌خواستن مشکل دارند.</li>
              <li>
                ترجیح می‌دهند که پشت‌صحنه کار کنندو دوست ندارند به چشم بیایند.
              </li>
              <li>
                دوست دارند کارهایی بکنند که نتایج ملموس و مشخص به‌دست دهد.
              </li>
              <li>
                اولویت‌های خود را به‌دقت انتخاب می‌کنند و قبل از شروع به کار
                جدید، اقدامات لازم را انجام می‌دهند.
              </li>
              <li>
                دوست دارند به نهاد و موسسه‌ای که برایش کار می‌کنند احترام
                بگذارند.
              </li>
              <li>مصمم و به خود انگیزه‌دهنده هستند.</li>
              <li>
                نیاز اندکی به سرپرستی دارند. از دیگران نمی‌خواهند کاری را که خود
                آن‌ها می‌توانند بکنند انجام بدهند.
              </li>
              <li>دوست ندارد کسی مزاحم کارکردن آن‌ها بشود.</li>
              <li>
                از تغییر پشت‌سرهم استقبال نمی‌کنند. دوست دارند که برای
                انجام‌دادن کارشان دستورالعمل‌های صریح و روشن وجود داشته باشد.
              </li>
              <li>
                به مقامات مسئول احترام می‌گذارند و کسانی که را که این کار را
                نمی‌کنند، درک نمی‌کنند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ISFJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Bruno} alt="Bruno Madrigal" />
                  <div className="intru">
                    <div className="name">Bruno Madrigal</div>
                    <div className="role">Encanto (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Morty} alt="Morty Smith" />
                  <div className="intru">
                    <div className="name">Morty Smith</div>
                    <div className="role">Rick and Morty (2013)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Cinderella} alt="Cinderella" />
                  <div className="intru">
                    <div className="name">Cinderella</div>
                    <div className="role">Cinderella (1950)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Selena} alt="Selena Gomez" />
                  <div className="intru">
                    <div className="name">Selena Gomez</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Bob} alt="Bob Ross" />
                  <div className="intru">
                    <div className="name">Bob Ross</div>
                    <div className="role">نقاش</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Steve} alt="Steve Rogers" />
                  <div className="intru">
                    <div className="name">Steve Rogers</div>
                    <div className="role">Captain America (2011)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Pam} alt="Pam Beesly" />
                  <div className="intru">
                    <div className="name">Pam Beesly</div>
                    <div className="role">The Office (2005)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={John} alt="John Watson" />
                  <div className="intru">
                    <div className="name">John Watson</div>
                    <div className="role">Sherlock (2010)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Soobin} alt="Soobin (TXT)" />
                  <div className="intru">
                    <div className="name">Soobin (TXT)</div>
                    <div className="role">خوانندهٔ k-pop</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Marge} alt="Marge Simpson" />
                  <div className="intru">
                    <div className="name">Marge Simpson</div>
                    <div className="role">The Simpsons (1989)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ISFJ;
