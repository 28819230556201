import Demo from "./Demo";
import './Demo.css';

function DemoPage(props) {
    document.title="دمو | PersianVQA"
    return <div className="demo-page">
        <div className="demo-page-inner">
            <div className="page-title-description" style={{marginBottom:"5vh"}}>
                <h1>دمو</h1>
                <p className="description">برای طراحی این دمو از مدل
                    Stack Attention Network
                    استفاده کرده ایم.
                    برای آموزش این مدل از دادگان
                    VQA v1
                    که به وسیله
                    Google Translate
                    ترجمه ماشینی شده است؛ بهره برده ایم. برای استفاده از این دمو کافی است یک تصویر و یک سوال را در مکان مخصوص به خودشان بارگذاری نمایید.
                    سپس با فشردن دکمه «پاسخ» می توانید جواب سوال را مشاهده کنید.</p>
            </div>
            <Demo />
        </div>
    </div>
}

export default DemoPage;