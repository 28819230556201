import daneshkadePic from './images/daneshkade.jpg';
function LandingPageUpperPart(props) {
    document.title="پرسش و پاسخ تصویری به زبان فارسی | PersianVQA"

    return <div className="landing-upper">
        <img src={daneshkadePic} />

    </div>
}


export default LandingPageUpperPart;