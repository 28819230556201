import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "pages/HomePage";
import MainLanding from "pages/MainLanding";
import VQA_APP from "apps/PVQA";
import MBTI_APP from "apps/MBTI";
import Info from "pages/Info";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/fa" exact render={(props) => <HomePage />} />
        <Route path="/pvqa" render={(props) => <VQA_APP />} />
        <Route path="/mbti" render={(props) => <MBTI_APP />} />
        <Route path="/personality_detection" render={(props) => <Info />} />
        <Route path="/" exact render={(props) => <MainLanding />} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
