import React from 'react';
// import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PeopleIcon from '@mui/icons-material/People';
import './HorizontalList.css';
import { useEffect, useState } from 'react';
import { ServerUrl } from "utils/Constants"; //not sure about the url
// import Carousel from 'react-bootstrap/Carousel';


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

function Items(props) {
  const [items, setItems] = React.useState([]);
  // const [selected, setSelected] = React.useState([]);
  // const [position, setPosition] = React.useState(0);

  useEffect(() => {
    //remove this when fetch starts working 
    //and can get projects from server:
    setItems(Array(20)
      .fill(0)
      .map((_, ind) => ({
        id: `element-${ind}`,
        name: "Project name", picture: "https://appen.com/wp-content/uploads/2018/03/Recent-Developments-in-Neural-Networks.png",
        link: "/", description: "This project is a test that's been repeated for like 20 times to test the horizontal list.\nthat's all.",
        contributors: Array(5).fill(0)
          .map((s, i) => ({
            person_info: {
              first_name: "Name", last_name: "Family", about: "an ordinary person. nothing worth noting.\ncompletely normal lol",
              image: "https://i.pinimg.com/564x/69/39/43/6939434841ebd31f182e29911bd0d6c7.jpg"
            },
            tasks: ["manager", "trainer of a model", "dataset collector"],
            date_joined: "2022/02/02",
            id: i
          }))
      })));

    //get projects from the server
    fetch(`${ServerUrl}/projects/`, {
      method: "GET",
    }).then(res => res.json())
      .then(res => {
        setItems(res);
        console.log(res);
      })
      .catch(err => console.error(err));
  }, []);


  return (
    <>
      <Swiper
        cssMode={true}
        // slidesPerView={1}
        navigation={true}
        pagination={{
          "clickable": true
        }}
        mousewheel={true}
        keyboard={true}
        // loop={true}
        // loopedSlides={items.length}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {items?.map((item) => (
          <SwiperSlide>
            <Card
              itemId={item?.id} // NOTE: itemId is required to track items
              info={item}
              key={item?.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Carousel interval={null} fade className="mySwiper">
        {items.map((id, i) => {
          if (id)
            return (<Carousel.Item key={i}>
              <Card
                itemId={id} // NOTE: itemId is required to track items
                info={id}
                key={id}
              />
            </Carousel.Item>)
        })}
      </Carousel> */}
    </>
  );
}

function Card({ onClick, selected, info, itemId }) {
  const [contribVisibility, setContribVisibility] = useState(false);
  // const [contributors, setContributors] = React.useState([]);
  return (
    <div
      style={{
        width: 'calc(100vw - 105px)',
      }}
      tabIndex={0}
    >
      <div className="card">
        <img src={info.picture} alt={info.name + " picture"} />
        <h2>{info.name}</h2>
        <p>{info.description}</p>
        <p className="contributors-title" onClick={(e) => setContribVisibility(!contribVisibility)}><PeopleIcon style={{ fontSize: "1.2em", margin: "2px 2px 5px 2px" }} />Project members{contribVisibility ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</p>
        <div className="contributors-container row" id={`contributors-${itemId}`} style={{ height: contribVisibility ? "auto" : 0 }}>
          {info?.contributors?.map(e => (ContributorCard(e)))}
        </div>
        <a href={info.link}>Continue to view project<KeyboardDoubleArrowRightIcon /></a>
      </div>

    </div>
  );
}

function ContributorCard(props) {
  const [contributionsColor, setContributionColor] = useState([])
  useEffect(() => {
    let c = [];
    props.tasks.forEach(function (element) {
      c.push({
        backgroundColor: "rgb(" + Math.floor(Math.random() * 127 + 80)
          + "," + Math.floor(Math.random() * 127 + 80) + ","
          + Math.floor(Math.random() * 127 + 80) + ")"
      })
    });
    setContributionColor(c);
  }, [props.tasks])

  return (
    <div className="col-md-4 col-sm-6 col-12">
      <div className="contributor-card">
        <div className="contributors-header">
          <div className='img-container'>

            <img src={props.person_info.image} alt={props.person_info.first_name + " " + props.person_info.last_name + "'s profile"}/>
          </div>
          <h6>{props.person_info.first_name + " " + props.person_info.last_name}</h6>
          <p className="join-date">joined on {props.date_joined}</p> {/* can be another short info instead of join date*/}
        </div>
        <p className="desc">{props.person_info.about}</p>
        {props.tasks?.map((task, i) => (
          <div className="task" style={contributionsColor[i]}>{task}</div>))}
      </div>
    </div>
  )
}

export default Items;
