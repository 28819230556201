import DatasetList from "./Get Dataset/DatasetList";
import Scenario2DatasetList from "./Get Dataset/Scenario2DatasetList";
import React, { useState, useMemo, useEffect } from "react";
import { MediaUrl, ServerUrl } from "utils/Constants";
import { ToastContainer, toast } from "react-toastify";

export default function DatasetListPage() {
  const [daata, setData] = useState("first-render");
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState("answered-images");
  const [scenario, setScenario] = useState("1");
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const dropdownOptions = {
    "answered-images": "تمام دیتاست",
    "user-answered-images": "پاسخ های شما",
    "non-approved-answered-images": "سوالات تایید نشده",
  };
  const SearchDropDownToggle = () => {
    document.getElementById("shop-search-dropdown").classList.toggle("show");
  };
  const scenarioOptions = {
    "1": "سناریو 1",
    "2": "سناریو 2",
    "3": "سناریو 3",
  };
  const ScenarioDropDownToggle = () => {
    document.getElementById("scenario-dropdown").classList.toggle("show");
  };

  function throwError(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    //CHECK ACCESS ---- UNCOMMENT LATER
    fetch(`${ServerUrl}/dataset/check-access`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
    })
      .then((r) => r.json())
      .then((r) => {
        if (!r.access_dataset) window.location.href = "/pvqa";
      })
      .catch((err) => {
        window.location.href = "/pvqa";
      });
  }, []);

  useEffect(() => {
    // const firstPageIndex = (currentPage - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;

    //fetch new data
    switch(scenario){
      case "1":
        var data = [
          // {
          //   image:
          //     "https://i.pinimg.com/564x/f3/2b/f0/f32bf0f79ccc286b37b25aa302369609.jpg",
          //   questions: [
          //     {
          //       text: "در عکس پنجره هست؟",
          //       answers: [
          //         { text: "نه", id: 1 },
          //         { text: "بله!", id: 2 },
          //         { text: "هممم... شاید؟", id: 3 },
          //         { text: "بلی", id: 4 },
          //         { text: "بله", id: 5 },
          //         { text: "آره", id: 6 },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   image:
          //     "https://i.pinimg.com/236x/34/57/eb/3457eb5956316daf3e2ada6eb0ad6ffd.jpg",
          //   questions: [
          //     {
          //       text: "این گربس؟",
          //       id: 2,
          //       answers: [
          //         { text: "خیر", id: 1 },
          //         { text: "خیر\nامکان نداره", id: 2 },
          //       ],
          //     },
          //     { text: "چه رنگیه؟", id: 3, answers: [{ text: "سفید", id: 1 }] },
          //   ],
          // },
          // {
          //   image:
          //     "https://i.pinimg.com/736x/85/4d/cd/854dcd215dbeb47334fdd8024a051954.jpg",
          //   questions: [
          //     { text: "یه سوال سخت؟", id: 5 },
          //     {
          //       text: "در تصویر چه رنگی بیشتر دیده میشود؟",
          //       id: 4,
          //       answers: [
          //         { text: "سبز", id: 1 },
          //         { text: "سبز", id: 2 },
          //         { text: "سبز", id: 3 },
          //         { text: "سبز", id: 4 },
          //         { text: "سبز", id: 5 },
          //       ],
          //     },
          //   ],
          // },
        ];
        fetch(`${ServerUrl}/dataset/${type}/?page=${currentPage}`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("loginToken"),
          },
        })
          .then((res) => (res.ok ? res.json() : res.status === 401 ? 401 : null))
          .then((res) => {
            console.log(res);
            if (res === 401) window.location.href = "/pvqa/login";
            else if (!res) {
              throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
              return;
            }
            data = res;
            for (var d of data.results) {
              let row_count = 0;
              d.questions = d.answered_questions;
              for (var question of d.questions) {
                if (question.update_date) {
                  let str = question.update_date.split(/[T,\.]+/);
                  question.update_date_fixed = str[0] + "\n" + str[1];
                }
                for (var answer of question.answers) {
                  let str = answer.update_date.split(/[T,\.]+/);
                  answer.update_date_fixed = str[0] + "\n" + str[1];
                }
    
                if (!!question.answers && question.answers.length != 0)
                  row_count += question.answers.length;
                else row_count += 1; //for the question itself
              }
              d.row_count = row_count;
            }
            console.log(data);
            setData(data);
            setLoading(false);
          })
          .catch((err) => {
            throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
            console.log(err);
          });
        break;
      case "2":
        fetch(`${ServerUrl}/dataset/persian-data/?page=${currentPage}`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("loginToken"),
          },
        })
          .then((res) => (res.ok ? res.json() : res.status === 401 ? 401 : null))
          .then((res) => {
            console.log(res);
            if (res === 401) window.location.href = "/pvqa/login";
            else if (!res) {
              throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
              return;
            }
            data = res;
            let new_data = []
            let dataWithSameImage = (arr, r) => {
              for (var i = 0; i< arr.length; i++){
                if(arr[i].image.id == r.image.id)
                  return i;
              }
              return null;
            }
            data.results_length = data.results.length;
            for (var r of data.results) {
              if (r.update_date) {
                let str = r.update_date.split(/[T,\.]+/);
                r.update_date_fixed = str[0] + "\n" + str[1];
              }
              let idx = dataWithSameImage(new_data, r)
              if (idx == null){
                let new_object = {image: r.image, data: [r], row_count:1};
                new_data.push(new_object);
              }
              else{
                new_data[idx].data.push(r);
                new_data[idx].row_count = new_data[idx].row_count + 1;
              }
            }
            data.results = new_data;
            console.log(data);
            setData(data);
            setLoading(false);
          })
          .catch((err) => {
            throwError("مشکلی رخ داد! لطفا دوباره تلاش کنید.");
            console.log(err);
          });
        break;
      default:
    }
    
  }, [currentPage, reload, type]);

  useEffect(()=>{
    if(loading)
      ScenarioDropDownToggle();
  },[loading])

  useEffect(()=>{
    if(daata == "first-render")
      return;
    setLoading(false);
  }, [daata])

  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1);
    else setReload(!reload);
  }, [type, scenario]);
  document.title = "بازبینی دیتاست | PersianVQA";
  return (
    <div className="dataset-page dataset-list-page">
      <div className="dataset-page-inner">
        <div className="page-title-description" style={{ marginBottom: "5vh" }}>
          <h1>بازبینی دیتاست</h1>
          <p className="description">
            {/* می توانید جدول را براساس داده هایی که خودتان پاسخ داده اید، فیلتر
            کنید. */}
             با کلیک بر روی پرسش یا پاسخ، در صورت داشتن دسترسی می توانید
            متن آن را تغییر دهید.
          </p>
          {/* <p className="description">
                  در صورت نیاز می‌توانید راهنمای جمع‌آوری دیتاست را ببینید.
                </p> */}
        </div>
        <div
          className="dropdown"
          style={{ width: "fit-content", margin: "auto" }}
        >
          <button
            className="btn dropdown-toggle input-group-btn"
            data-testid="shop-filterby-button"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
            }}
          >
            {scenarioOptions[scenario]}
          </button>
          <div className="dropdown-menu show" id="scenario-dropdown">
            {Object.entries(scenarioOptions).map(([t, label], i) => (
              <div
                key={i}
                className="dropdown-item"
                onClick={() => {
                  if(t == scenario)
                    setLoading(false);
                  else
                    setScenario(t);
                }}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
        {scenario == "1" && !loading ?
          <>
            <div
              className="dropdown"
              style={{ width: "fit-content", margin: "auto" }}
            >
              <button
                className="btn dropdown-toggle input-group-btn"
                data-testid="shop-filterby-button"
                onClick={(e) => {
                  e.preventDefault();
                  SearchDropDownToggle();
                }}
              >
                {dropdownOptions[type]}
              </button>
              <div className="dropdown-menu" id="shop-search-dropdown">
                {Object.entries(dropdownOptions).map(([t, label], i) => (
                  <div
                    key={i}
                    className="dropdown-item"
                    onClick={() => {
                      setType(t);
                    }}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </div>
            <DatasetList
              currentTableData={daata}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              reload={reload}
              setReload={setReload}
            />
          </>
          :
          scenario == "2" && !loading?
          <Scenario2DatasetList
            currentTableData={daata}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            reload={reload}
            setReload={setReload}
          />
          :
          <></>
        }
      </div>
    </div>
  );
}
