import Dataset from "./Dataset";
import "./Dataset.css";

function DatasetPage(props) {
  document.title="جمع‌آوری دیتاست | PersianVQA"
  return (
    <div className="dataset-page">
      <div className="dataset-page-inner">
        <div className="page-title-description" style={{ marginBottom: "5vh" }}>
          <h1>جمع‌آوری دیتاست با وارد کردن پاسخ پرسش‌های مطرح‌شده</h1>
          {/* <p className="description">
            در این قسمت...
          </p> */}
          {/* <p className="description">
            در صورت نیاز می‌توانید راهنمای جمع‌آوری دیتاست را ببینید.
          </p> */}
          <p className="description">از خاموش بودن VPN یا فیلترشکن اطمینان حاصل کنید.</p>
        </div>
        <Dataset />
      </div>
    </div>
  );
}

export default DatasetPage;
