import "./Dataset.css";
import { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { ToastContainer, toast } from 'react-toastify';
import {MediaUrl, ServerUrl} from "utils/Constants";

function Dataset(props) {
  const [image, setImage] = useState(null);

  //////page status://////
  //"ln": loading new pic / question
  //"w": waiting for the user to fill the form and submit
  //"l": submitting the form user filled to server
  //"s": submitted!
  //"nq": no auto question for this image error
  const [status, setStatus] = useState("ln");
  const [answer, setAnswer] = useState([]);
  const [userQ, setUserQ] = useState("");
  const [autoQ, setAutoQ] = useState(true); // two states: auto question (true) / user writes a question (false)
  const [Q, setQ] = useState([]); //null

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (image)
        URL.revokeObjectURL(image.preview);
    },
    [image]
  );

  async function getNewVQ(reset = true) {
    if (!reset && image)
      return;
    setStatus("ln");
    // setAutoQ(false);
    setQ([]);
    setAnswer([]);
    setUserQ("");
    console.log("Token " + localStorage.getItem("loginToken"));
    // document.getElementById("autoq_btn").disabled = false;
    fetch(`${ServerUrl}/dataset/check-access`, {
      method: "GET",
      headers: {
        "Authorization": "Token " + localStorage.getItem("loginToken"),
      }
    }).then(r => r.json()).then(r=>{
      if (r.access_dataset)
        fetch(`${ServerUrl}/dataset/random-unanswered`, {
          method: "GET",
          headers: {
            "Authorization": "Token " + localStorage.getItem("loginToken"),
          }
        }).then(res => res.ok ? res.json() : res.status === 401 ? 401 : null)
          .then(res => {
            if (res === 401)
              window.location.href = "/pvqa/login"
            else if (!res) {
              throwError('مشکلی رخ داد! لطفا دوباره تلاش کنید.')
              return;
            }
            else if (!res.image) {
              throwError('هیچ تصویری موجود نیست!')
              return;
            }
            console.log(res);
            setImage(res);
            setQ(res.useful_unanswered);
            setAnswer(Array(res.useful_unanswered.length).fill(""));
            if (document.getElementById("ans0"))
              document.getElementById("ans0").focus();
          }).catch(err => {
            throwError('مشکلی رخ داد! لطفا دوباره تلاش کنید.')
            console.log(err)
          })
      else
        window.location.href = "/pvqa";
    }).catch(err => {
      window.location.href = "/pvqa";
    })
    setStatus("w");
  }

  function setAnswerWithIdx(idx, value) {
    let tempA = [...answer];
    tempA[idx] = value;
    setAnswer([...tempA]);
    console.log(tempA);
  }

  function ReportIncorrectQuestionTemp(idx) {
    let tempQ = [...Q];
    let newVal = !tempQ[idx].useless;
    tempQ[idx].useless = newVal;
    setQ([...tempQ]);
    console.log(tempQ);
  }

  useEffect(() => {
    if(!localStorage.getItem("loginToken"))
    {
      window.location.href = "/pvqa"
      return;
    }
    getNewVQ(false)
  }, []);

  function throwError(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  async function submitVQWithIndex(idx, maxQLength) {
    if (Q[idx].useless) {
      fetch(`${ServerUrl}/dataset/useless-question/${Q[idx].id}`, {
        method: "PATCH",
        headers: {
          "Authorization": "Token " + localStorage.getItem("loginToken"),
        }
      }).then(res => res.ok ? res.json() : null)
        .then(res => {
          if (!res) {
            throwError('مشکلی در ثبت داده رخ داد!')
            setStatus("w")
            return;
          }
          idx += 1;
          if (idx == maxQLength) {
            setStatus("s");
          }
          else {
            submitVQWithIndex(idx, maxQLength);
          }
        }).catch(err => {
          console.log(err);
          throwError('مشکلی در ثبت داده رخ داد!')
          setStatus("w")
        })
    }
    else {
      let fd = new FormData()
      fd.append("text", answer[idx]);
      fetch(`${ServerUrl}/dataset/answer/create/${Q[idx].id}`, {
        method: "POST",
        headers: {
          "Authorization": "Token " + localStorage.getItem("loginToken"),
        },
        body: fd
      }).then(res => res.ok ? res.json() : null)
        .then(res => {
          if (!res) {
            throwError('مشکلی در ثبت داده رخ داد!')
            setStatus("w")
            return;
          }
          idx += 1;
          if (idx == maxQLength) {
            setStatus("s");
          }
          else {
            submitVQWithIndex(idx, maxQLength);
          }
        }).catch(err => {
          console.log(err);
          throwError('مشکلی در ثبت داده رخ داد!')
          setStatus("w")
        })
    }
  }

  async function submitVQ(){
    setStatus("l");
    for (let i = 0; i < Q.length; i++) {
      if (!answer[i] && !Q[i].useless) {
        throwError('لطفا قبل از ثبت فیلدها را پر کنید.')
        setStatus("w")
        return;
      }
    }
    submitVQWithIndex(0, Q.length);
  }

  let KeyPress = function (e) {
    e = e || window.event;
    if (e.code === "Enter") {
      switch (status) {
        case "s": getNewVQ();
          break;
        case "nq": setStatus("w");
          break;
        default:
      }
    }
  };
  document.addEventListener('keydown', KeyPress);

  return (
    <>
      <div className="dataset">
        {status !== "w" && (
          <div className="dataset-loading">
            <div className="dataset-loading-inner">
              {status === "l" ? (
                <>
                  <h5>در حال ثبت</h5>
                  <p>لطفا منتظر بمانید</p>
                  <div className="stage">
                    <div className="dot-flashing"></div>
                  </div>
                </>
              ) : status === "s" ? (
                <>
                  <CheckCircleIcon />
                  <h5 className="submitted">
                    اطلاعات شما ثبت شد. از همکاریتان متشکریم!
                  </h5>
                  <button className="btn" onClick={() => getNewVQ()}>
                    ثبت دیتای جدید
                  </button>
                </>
              ) : status === "ln" ? (
                <>
                  <h5>در حال ایجاد فرم جدید</h5>
                  <p>لطفا منتظر بمانید</p>
                  <div className="stage">
                    <div className="dot-flashing"></div>
                  </div>
                </>
              ) : (
                //status = "nq"
                <>
                  <SentimentVeryDissatisfiedIcon />
                  <h5 className="submitted">
                    پرسشی برای این تصویر ثبت نشده است. لطفا پرسش را دستی وارد کنید.
                  </h5>
                  <button className="btn" onClick={() => setStatus("w")}>
                    ادامه
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 order-lg-2 col-lg-4 ">
            <div className="picture-field">
              <div className="img-container">
                <img src={image?.image} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 ">
            {/* <div className="q-submit-field"> */}
            <div className="col">
              {!!Q && Q.map((question, idx) =>
                <div key={question.id}>
                  <div className="q-submit-field">
                    {!autoQ ? (
                      <textarea
                        rows={3}
                        placeholder="متن پرسش را اینجا بنویسید"
                        maxLength="200"
                        onChange={(e) => setUserQ(e.target.value)}
                        value={userQ}
                      />
                    ) : (
                      <div className="auto-question">
                        <p> {question?.text} </p>
                      </div>
                    )}

                    {/* <button className="btn" onClick={() => getAutoQ()} id="autoq_btn">
                    <div className="btn-inner">
                      {autoQ ? "وارد کردن پرسش" : "نمایش یک پرسش"}
                      <ChevronLeftIcon />
                    </div>
                  </button> */}
                    <button className="btn" id={"report-btn" + idx} onClick={() => ReportIncorrectQuestionTemp(idx)} tabIndex="-1">
                      <div className="btn-inner">
                        {question.useless ? "لغو علامت زدن پرسش" : "اشکال در پرسش"}
                        <ChevronLeftIcon />
                      </div>
                    </button>
                  </div>
                  <div className="q-submit-field">
                    {question.useless ?
                      <p>سوال اشتباه است.</p>
                      :
                      <textarea
                        rows={2}
                        placeholder="پاسخ را اینجا بنویسید"
                        maxLength="200"
                        onChange={(e) => setAnswerWithIdx(idx, e.target.value)}
                        value={answer[idx]}
                        id={"ans" + idx}
                      />
                    }
                  </div>
                </div>
              )}
              {!!image && <button
                className="btn"
                onClick={submitVQ}
                disabled={status !== "w"}
              >
                <div className="btn-inner">
                  {"ثبت"}
                  <ChevronLeftIcon />
                </div>
              </button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dataset;
