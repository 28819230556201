import { useHistory } from "react-router-dom";
import React from "react";
import "./INTJ.style.scss";
import intjPic from "../../../assets/images/types/intj/INTJ.jpg";
import Beth from "../../../assets/images/types/intj/intj1.png";
import Elon from "../../../assets/images/types/intj/intj2.png";
import Scar from "../../../assets/images/types/intj/intj3.png";
import Batman from "../../../assets/images/types/intj/intj4.png";
import Mark from "../../../assets/images/types/intj/intj5.png";
import Tesla from "../../../assets/images/types/intj/intj6.png";
import Stephen from "../../../assets/images/types/intj/intj7.png";
import Heisenberg from "../../../assets/images/types/intj/intj8.png";
import V from "../../../assets/images/types/intj/intj9.png";
import Hannibal from "../../../assets/images/types/intj/intj10.png";
// import { useNavigate } from "react-router-dom";

const INTJ = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-intj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={intjPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> INTJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد INTJ: </b>
              افکاری بدیع و مبتکرانه دارد و پرانرژی می‌باشد که فقط برای رسیدن به
              اهداف خود از آن استفاده می‌برد. در زمینه‌هایی که علاقه پیدا
              می‌نماید قدرت خاصی جهت سازماندهی کارها دارد و می‌تواند کارها را با
              کمک یا بدون کمک دیگران به پایان برساند. شکاک، منتقد، مستقل و مصمم
              و گاهی اوقات لجوج می‌باشد. باید یاد بگیرد که روی موضوعات جزئی زیاد
              تمرکز نماید تا بتواند در موضوعات مهم‌تر موفق باشد.
            </div>
            <div className="jobstitle">شغل‌های مناسب یک INTJ:</div>
            <div className="jobs">
              مدیر مدرسه، باستان‌شناس، معمار، فضانورد، وکیل، برنامه‌نویس یا
              تحلیل‌گر کامپیوتر، مشاور، طراح، اقتصاددان، مخترع، تحلیل‌گر،
              سرمایه‌گذار، ریاضی‌دان، عکاس، پزشک، روانپزشک، روانشناس، پژوهشگر،
              استاد دانشگاه، نویسنده
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>از توانمندی‌های مفهومی و تصوری فراوان برخوردارند.</li>
              <li>
                اطمینان فراوانی به بینش‌های شمی خود دارند و می‌توانند آن‌ها را
                به واقعیت برسانند.
              </li>
              <li>
                اغلب به مقام رهبری و یا مدیریت در موسسه و شرکت می‌رسند به سازمان
                خود احساسی از هدف‌مندی، پنداره یا مأموریت می‌دهند.
              </li>
              <li>
                به شکلی منطقی و منظم کار می‌کند تا راه‌حل‌هایی نوآور و مبتکرانه
                برای مسائل ایجاد کند.
              </li>
              <li>
                سیستم‌ها و راه‌کارهایی می‌بیند که برای کاری که در دست دارد جالب
                است.
              </li>
              <li>
                برای رسیدن به مقصود همان اندازه که به خود فشار می‌آورد به دیگران
                هم فشار می‌آورد.
              </li>
              <li>
                وقتی واقعیت با الگوی آن‌ها هم‌خوانی ندارد از خود انتقاد می‌کنند.
              </li>
              <li>
                دوست دارد که مستقل کار کند، دوست ندارد کسی در کار او دخالت کند.
              </li>

              <li>دوست دارند بر اجرای عقاید خود کنترل داشته‌باشند.</li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> INTJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Scar} alt="Scar" />
                  <div className="intru">
                    <div className="name">Scar</div>
                    <div className="role">The Lion King</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Stephen} alt="Stephen" />
                  <div className="intru">
                    <div className="name">Stephen Strange</div>
                    <div className="role">Doctor Strange</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Hannibal} alt="Hannibal" />
                  <div className="intru">
                    <div className="name">Hannibal Lecter</div>
                    <div className="role">Hannibal (2013)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Heisenberg} alt="Heisenberg" />
                  <div className="intru">
                    <div className="name">Walter White</div>
                    <div className="role">Breaking Bad (2008)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Batman} alt="Batman" />
                  <div className="intru">
                    <div className="name">"Bruce Wayne "Batman</div>
                    <div className="role">The Dark Knight Trilogy (2005)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Tesla} alt="Tesla" />
                  <div className="intru">
                    <div className="name">Nikola Tesla</div>
                    <div className="role">مخترع</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Mark} alt="Mark Zuckerberg" />
                  <div className="intru">
                    <div className="name">Mark Zuckerberg</div>
                    <div className="role">مؤسس فیس‌بوک</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Elon} alt="Elon Musk" />
                  <div className="intru">
                    <div className="name">Elon Musk</div>
                    <div className="role">مدیرعامل تسلا</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={V} alt="V" />
                  <div className="intru">
                    <div className="name">V</div>
                    <div className="role">V for Vendetta (2005)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Beth} alt="Elizabeth" />
                  <div className="intru">
                    <div className="name">Beth Harmon</div>
                    <div className="role">The Queen’s Gambit (2020)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default INTJ;
