import React, { useState, useMemo } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";
import { MediaUrl, ServerUrl } from "utils/Constants";
import useAuth from "apps/PVQA/context/AuthContext";
import apiInstance from "config/axios";
import "./DatasetList.css";

function DatasetList(props) {
  let username = localStorage.getItem("username");
  const auth = useAuth();

  async function checkPermissions(question) {
    if (question.author.username == username) return true;

    if (auth.isAdmin || auth.isSuperAnnotator) return true;
  }
  const [approved, setApproved] = useState([]);
  const [nonApproved, setNonApproved] = useState([]);

  const [markedUseless, setMarkedUseless] = useState([]);

  const ApproveBox = ({ question }) => {
    const approvedBy = question.approved_by?.username;
    return (
      <td className="answer cell approved_by">
        {nonApproved.includes(question.id)
          ? "-"
          : approvedBy ??
            (approved.includes(question.id) ? <div>{auth.username}</div> : "-")}
      </td>
    );
  };

  const QuestionStatusBox = ({ question }) => {
    const approvedBy = question.approved_by?.username;
    const approveQ = (qId, isRemove) => {
      apiInstance
        .post(`/dataset/questions/${qId}/approve/`, { remove: isRemove })
        .then((res) => {
          if (isRemove) {
            setNonApproved([...nonApproved, qId]);
            setApproved(approved.filter((x) => x !== qId));
          } else {
            setApproved([...approved, qId]);
            setNonApproved(nonApproved.filter((x) => x !== qId));

          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const markUselessQ = (qId) => {
      apiInstance
        .post(`/dataset/questions/${qId}/mark_useless/`)
        .then((res) => {
          setMarkedUseless([...markedUseless, qId]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!auth.isSuperAnnotator) return <></>;
    return (
      <div className="">
        {(!nonApproved.includes(question.id) && approvedBy) ||
        approved.includes(question.id) ? (
          <Tooltip
            title="حذف تایید"
            onClick={() => approveQ(question.id, true)}
          >
            <IconButton>
              <RemoveDoneIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="تایید سوال">
            <IconButton
              color="success"
              onClick={() => approveQ(question.id, false)}
            >
              <DoneAllIcon />
            </IconButton>
          </Tooltip>
        )}
        {!(question.useless || markedUseless.includes(question.id)) && (
          <Tooltip title="useless علامت زدن">
            <IconButton color="error" onClick={() => markUselessQ(question.id)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const editQuestion = (question) => {
    checkPermissions(question).then((r) => {
      if (!r) {
        throwError("شما اجازه ویرایش پرسش را ندارید!");
        return;
      }
      document.getElementById(`question-${question.id}`).hidden = true;
      let editBox = document.getElementById(`question-${question.id}-edit`);
      editBox.hidden = false;
      editBox.firstChild.focus();
      editBox.firstChild.setSelectionRange(
        editBox.firstChild.value.length,
        editBox.firstChild.value.length
      );
    });
  };
  function throwError(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const submitEdittedQuestion = (question) => {
    let new_q_text = document.getElementById(
      `question-${question.id}-edit-val`
    ).value;
    let fd = new FormData();
    fd.append("text", new_q_text);
    fetch(`${ServerUrl}/dataset/question/${question.id}`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
      body: fd, //JSON.stringify({text: new_q_text})
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((res) => {
        if (!res) {
          throwError("مشکلی در ثبت داده رخ داد!");
          return;
        }
        document.getElementById(`question-${question.id}`).hidden = false;
        document.getElementById(`question-${question.id}-edit`).hidden = true;
        props.setReload(!props.reload);
      })
      .catch((err) => {
        console.log(err);
        throwError("مشکلی در ثبت داده رخ داد!");
      });
  };

  const editAnswer = (q_id, answer) => {
    checkPermissions(answer).then((r) => {
      if (!r) {
        throwError("شما اجازه ویرایش پاسخ را ندارید!");
        return;
      }
      document.getElementById(`q-${q_id}-a-${answer.id}`).hidden = true;
      let editBox = document.getElementById(`q-${q_id}-a-${answer.id}-edit`);
      editBox.hidden = false;
      editBox.firstChild.focus();
      editBox.firstChild.setSelectionRange(
        editBox.firstChild.value.length,
        editBox.firstChild.value.length
      );
    });
  };

  const submitEdittedAnswer = (q_id, answer) => {
    let new_q_text = document.getElementById(
      `q-${q_id}-a-${answer.id}-edit-val`
    ).value;
    let fd = new FormData();
    fd.append("text", new_q_text);
    fetch(`${ServerUrl}/dataset/answer/${answer.id}`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
      body: fd, //JSON.stringify({text: new_q_text})
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((res) => {
        if (!res) {
          throwError("مشکلی در ثبت داده رخ داد!");
          return;
        }
        document.getElementById(`q-${q_id}-a-${answer.id}`).hidden = false;
        document.getElementById(`q-${q_id}-a-${answer.id}-edit`).hidden = true;
        props.setReload(!props.reload);
      })
      .catch((err) => {
        console.log(err);
        throwError("مشکلی در ثبت داده رخ داد!");
      });
  };

  return (
    <div className="dataset-list">
      <table>
        <thead>
          <tr>
            <th>تصویر</th>
            <th>پرسش</th>
            <th>ثبت کننده</th>
            <th>تاریخ</th>
            <th>پاسخ</th>
            <th>ثبت کننده</th>
            <th>تاریخ</th>
            <th>تایید شده توسط</th>
          </tr>
        </thead>
        {/* <tbody> */}
        {/* {props.currentTableData.map(item => {
            return (
              <tr className="main-row">
                <td className='image-cell'>
                    <img src={item.image} alt={`image id ${item.id}`} 
                    onClick={() => window.open(item.image, "_blank")}/>
                    </td>
                {item.questions.map(question => 
                    <>
                    <td className='cell question-cell' id={`question-${question.id}`} onClick={()=>editQuestion(question)}>{question.text}</td>
                    <td className='cell question-cell' id={`question-${question.id}-edit`} hidden="true"><textarea /></td>
                    <td className='answers-container answer-cell'>
                        {question.answers.map(answer=> 
                    <div key={answer.id} className='answer cell'>{answer.text}</div>)}</td>
                    </>)}
              </tr>
            );
          })} */}
        {props.currentTableData?.results?.map((item) => {
          return (
            <tbody key={item.id}>
              <tr className="main-row border-top">
                <td rowspan={`${item.row_count}`} className="image-cell">
                  <img
                    src={item.image.replace(ServerUrl, MediaUrl)}
                    alt={`image id ${item.id}`}
                    onClick={() => window.open(item.image, "_blank")}
                  />
                </td>
                {item.questions?.length > 0 && (
                  <>
                    <td
                      rowspan={`${item.questions[0].answers?.length}`}
                      className="cell question-cell"
                      id={`question-${item.questions[0].id}`}
                      onClick={(e) =>
                        item.questions[0].from_template
                          ? e.preventDefault()
                          : editQuestion(item.questions[0])
                      }
                    >
                      {item.questions[0].text}

                      <QuestionStatusBox question={item.questions[0]} />
                    </td>
                    <td
                      rowspan={`${item.questions[0].answers?.length}`}
                      className="cell question-cell"
                      id={`question-${item.questions[0].id}-edit`}
                      hidden="true"
                    >
                      <textarea
                        id={`question-${item.questions[0].id}-edit-val`}
                        defaultValue={item.questions[0].text}
                        rows="1"
                        autofocus
                      />
                      <div
                        className="btn"
                        onClick={() => submitEdittedQuestion(item.questions[0])}
                      >
                        ثبت
                      </div>
                    </td>
                    <td
                      rowspan={`${item.questions[0].answers?.length}`}
                      className="cell author"
                    >
                      {item.questions[0].author?.username +
                        (item.questions[0].from_template ? "-الگو" : "")}
                    </td>
                    <td
                      rowspan={`${item.questions[0].answers?.length}`}
                      className="cell time"
                    >
                      {item.questions[0].update_date_fixed}
                    </td>
                    {item.questions[0].answers?.length > 0 && (
                      <>
                        <td
                          className="answer cell"
                          id={`q-${item.questions[0].id}-a-${item.questions[0].answers[0].id}`}
                          onClick={() =>
                            editAnswer(
                              item.questions[0].id,
                              item.questions[0].answers[0]
                            )
                          }
                        >
                          {item.questions[0].answers[0].text}
                        </td>
                        <td
                          className="answer cell"
                          id={`q-${item.questions[0].id}-a-${item.questions[0].answers[0].id}-edit`}
                          hidden="true"
                        >
                          <textarea
                            id={`q-${item.questions[0].id}-a-${item.questions[0].answers[0].id}-edit-val`}
                            defaultValue={item.questions[0].answers[0].text}
                            rows="1"
                            autofocus
                          />
                          <div
                            className="btn"
                            onClick={() =>
                              submitEdittedAnswer(
                                item.questions[0].id,
                                item.questions[0].answers[0]
                              )
                            }
                          >
                            ثبت
                          </div>
                        </td>
                        <td className="answer cell author">
                          {item.questions[0].answers[0].author?.username}
                        </td>
                        <td className="answer cell time">
                          {item.questions[0].answers[0].update_date_fixed}
                        </td>

                        <ApproveBox question={item.questions[0]} />
                      </>
                    )}
                  </>
                )}
              </tr>
              {/* <div className='answers-container answer-cell'> */}
              {item.questions?.length > 0 &&
                item.questions[0].answers?.map((answer, answer_idx) =>
                  answer_idx ? (
                    <tr key={answer.id}>
                      <td
                        className="answer cell"
                        id={`q-${item.questions[0].id}-a-${answer.id}`}
                        onClick={() => editAnswer(item.questions[0].id, answer)}
                      >
                        {answer.text}
                      </td>
                      <td
                        className="answer cell"
                        id={`q-${item.questions[0].id}-a-${answer.id}-edit`}
                        hidden="true"
                      >
                        <textarea
                          id={`q-${item.questions[0].id}-a-${answer.id}-edit-val`}
                          defaultValue={answer.text}
                          rows="1"
                          autofocus
                        />
                        <div
                          className="btn"
                          onClick={() =>
                            submitEdittedAnswer(item.questions[0].id, answer)
                          }
                        >
                          ثبت
                        </div>
                      </td>
                      <td className="answer cell author">
                        {answer.author?.username}
                      </td>
                      <td className="answer cell time">
                        {answer.update_date_fixed}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )
                )}
              {/* </div> */}
              {item.questions?.map((question, q_idx) => (
                <>
                  {q_idx ? (
                    <>
                      <tr className="border-top2">
                        <td
                          rowspan={`${question.answers?.length}`}
                          className="cell question-cell"
                          id={`question-${question.id}`}
                          onClick={(e) =>
                            question.from_template
                              ? e.preventDefault()
                              : editQuestion(question)
                          }
                        >
                          {question.text}
                          <QuestionStatusBox question={question} />
                        </td>
                        <td
                          rowspan={`${question.answers?.length}`}
                          className="cell question-cell"
                          id={`question-${question.id}-edit`}
                          hidden="true"
                        >
                          <textarea
                            id={`question-${question.id}-edit-val`}
                            defaultValue={question.text}
                            rows="1"
                            autofocus
                          />
                          <div
                            className="btn"
                            onClick={() => submitEdittedQuestion(question)}
                          >
                            ثبت
                          </div>
                        </td>
                        <td
                          rowspan={`${question.answers?.length}`}
                          className="cell author"
                        >
                          {question.author?.username +
                            (question.from_template ? "-الگو" : "")}
                        </td>
                        <td
                          rowspan={`${question.answers?.length}`}
                          className="cell time"
                        >
                          {question.update_date_fixed}
                        </td>

                        {question.answers?.length > 0 && (
                          <>
                            <td
                              className="answer cell"
                              id={`q-${question.id}-a-${question.answers[0].id}`}
                              onClick={() =>
                                editAnswer(question.id, question.answers[0])
                              }
                            >
                              {question.answers[0].text}
                            </td>
                            <td
                              className="answer cell"
                              id={`q-${question.id}-a-${question.answers[0].id}-edit`}
                              hidden="true"
                            >
                              <textarea
                                id={`q-${question.id}-a-${question.answers[0].id}-edit-val`}
                                defaultValue={question.answers[0].text}
                                rows="1"
                                autofocus
                              />
                              <div
                                className="btn"
                                onClick={() =>
                                  submitEdittedAnswer(
                                    question.id,
                                    question.answers[0]
                                  )
                                }
                              >
                                ثبت
                              </div>
                            </td>
                            <td className="answer cell author">
                              {question.answers[0].author?.username}
                            </td>
                            <td className="answer cell time">
                              {question.answers[0].update_date_fixed}
                            </td>
                            <ApproveBox question={question} />
                          </>
                        )}
                      </tr>
                      {/* <div className='answers-container answer-cell'> */}
                      {question.answers?.map((answer, answer_idx) =>
                        answer_idx ? (
                          <tr key={answer.id}>
                            <td
                              className="answer cell"
                              id={`q-${question.id}-a-${answer.id}`}
                              onClick={() => editAnswer(question.id, answer)}
                            >
                              {answer.text}
                            </td>
                            <td
                              className="answer cell"
                              id={`q-${question.id}-a-${answer.id}-edit`}
                              hidden="true"
                            >
                              <textarea
                                id={`q-${question.id}-a-${answer.id}-edit-val`}
                                defaultValue={answer.text}
                                rows="1"
                                autofocus
                              />
                              <div
                                className="btn"
                                onClick={() =>
                                  submitEdittedAnswer(question.id, answer)
                                }
                              >
                                ثبت
                              </div>
                            </td>
                            <td className="answer cell author">
                              {answer.author?.username}
                            </td>
                            <td className="answer cell time">
                              {answer.update_date_fixed}
                            </td>
                            <td className="answer cell time">
                              {answer.update_date_fixed}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                      {/* </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </tbody>
          );
        })}
        {/* </tbody> */}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={props.currentPage}
        totalCount={
          props.currentTableData?.count ? props.currentTableData.count : 1
        }
        pageSize={
          props.currentTableData?.results
            ? props.currentTableData.results.length
            : 1
        }
        onPageChange={(page) => props.setCurrentPage(page)}
      />
    </div>
  );
}

export default DatasetList;
