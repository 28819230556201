import {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from "react";
import apiInstance from "config/axios";

const authContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAnnotator, setIsSuperAnnotator] = useState(false);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(null);

  const updateInfo = useCallback(() => {
    apiInstance
      .get("/user/me")
      .then((res) => res.data)
      .then((data) => {
        console.log("User: ", data);
        setUser(data);
        setUsername(data.username);
        setIsLoggedIn(true);
        setIsAdmin(data.is_superuser);
        setIsSuperAnnotator(data.is_super_annotator);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    updateInfo();
  }, []);

  const login = useCallback((loginToken, refresh_token) => {
    localStorage.setItem("loginToken", loginToken);
    updateInfo();
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setIsLoggedIn(false);
    setIsAdmin(false);
    setIsSuperAnnotator(false);
    localStorage.removeItem("loginToken");
  }, []);

  return (
    <authContext.Provider
      value={useMemo(
        () => ({
          login,
          logout,
          user,
          username,
          isLoggedIn,
          isAdmin,
          isSuperAnnotator,
        }),
        [login, logout, isLoggedIn, isAdmin, isSuperAnnotator, user,username]
      )}
    >
      {children}
    </authContext.Provider>
  );
}

const useAuth = () => useContext(authContext);

export default useAuth;
