import React from "react";
import "./ENFJ.style.scss";
import { useState, useEffect } from "react";
import enfjPic from "../../../assets/images/types/enfj/ENFJ.jpg";
// import { useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import Targaryen from "../../../assets/images/types/enfj/enfj1.png";
import Harry from "../../../assets/images/types/enfj/enfj2.png";
import Jimin from "../../../assets/images/types/enfj/enfj3.png";
import Nam from "../../../assets/images/types/enfj/enfj4.png";
import Moana from "../../../assets/images/types/enfj/enfj5.png";
import Tanjiro from "../../../assets/images/types/enfj/enfj6.png";
import Judy from "../../../assets/images/types/enfj/enfj7.png";
import Padme from "../../../assets/images/types/enfj/enfj8.png";
import Emma from "../../../assets/images/types/enfj/enfj9.png";
import Lily from "../../../assets/images/types/enfj/enfj10.png";

const ENFJ = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-enfj">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={enfjPic} class="responsive"></img>
          </div>

          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ENFJ </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ENFJ: </b>
              مسئولیت‌پذیر، دلسوز، یاری‌رسان و مهرطلب می‌باشد. احساس واقعی نسبت
              به آنچه دیگران می‌خواهند و فکر می‌کنند، دارد. سعی در انجام کارهای
              دیگران با توجه زیاد به عواطف و احساسات آن‌ها دارد. در ارائه يك
              موضوع با رهبری یک بحث گروهی توانایی خاصی دارد و از عهده آن به
              آسانی بر‌می‌آید. زودجوش، محبوب و فعال در امور آموزشی می‌باشد، در
              عینِ حال زمانِ کافی جهت مطالعات متنوع برای انجام بهتر کارها تخصیص
              می‌دهد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ENFJ:</div>
            <div className="jobs">
              مشاور، مستشار، روان‌شناس، معلم، روحانی، نماینده‌ٔ فروش، مدیر،
              هماهنگ‌کننده‌ی رویدادها، سیاست‌مدار یا دیپلمات، نویسنده
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                افرادی جاه‌طلب که برای رسیدن به خواسته‌‌هایشان تلاش می‌کنند.
              </li>
              <li>
                آگاه، منظم، هدف‌گرا و قاطع هستند و از دیگران هم همین انتظار را
                دارند.
              </li>
              <li>در حرف‌زدن و ابراز احساسات راحتند.</li>
              <li>احتياجات دیگران را به‌خوبی متوجه می‌شوند.</li>
              <li>به نظرات مختلف احترام می‌گذارند.</li>
              <li>در ایجاد روحیهٔ گروهی بسیار موفق هستند.</li>
              <li>
                دوست دارند با اشخاص خلاق و پرانرژی کار کنند و درگیر کارهای
                متنوعی شوند.
              </li>
              <li>
                از سیاست‌ها و رویه‌ها تا جایی که با ارزش‌ها و نیازهای آن‌ها
                منافات نداشته‌باشد، استفاده می‌کنند.
              </li>
              <li>
                وقتی به مشکلی برخورد کنند آن را به‌عنوان یک چالش جدید می‌پذیرند.
              </li>
              <li>
                مشاغلی را ترجیح می‌دهند که ایده‌آل‌های آن‌ها را منعکس کند و به
                دیگران الهام و هماهنگی ببخشد.
              </li>
              <li>دوست دارند اقدامات سریع انجام بدهند.</li>
              <li>
                اغلب در عجله و شتاب هستند و با کندیِ جریاناتِ کار، صبر و قرارشان
                را از دست می‌دهند.
              </li>
              <li>
                دوست دارند در شرایط کنترل باشند و در قبال پروژه‌های خودشان
                مسئولانه عمل کنند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ENFJ </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Targaryen} alt="Targaryen" />
                  <div className="intru">
                    <div className="name">Daenerys Targaryen</div>
                    <div className="role">Game of Thrones (2011)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Harry} alt="Harry" />
                  <div className="intru">
                    <div className="name">Harry Styles</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Moana} alt="Moana" />
                  <div className="intru">
                    <div className="name">Moana Waialiki</div>
                    <div className="role">Moana (2016)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Tanjiro} alt="Tanjiro" />
                  <div className="intru">
                    <div className="name">Tanjiro Kamado</div>
                    <div className="role">Demon Slayer</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Judy} alt="Judy" />
                  <div className="intru">
                    <div className="name">Judy Hopps</div>
                    <div className="role">Zootopia (2016)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Jimin} alt="jimin" />
                  <div className="intru">
                    <div className="name">Jimin (BTS)</div>
                    <div className="role">
                      خوانندهٔ
                      <span dir="rtl"> k-pop </span>
                    </div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Nam} alt="nam" />
                  <div className="intru">
                    <div className="name">Oh Il-nam/No.001</div>
                    <div className="role">Squid Game (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Padme} alt="Padme" />
                  <div className="intru">
                    <div className="name">Padmé Amidala</div>
                    <div className="role">جنگ ستارگان</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Emma} alt="Emma" />
                  <div className="intru">
                    <div className="name">Emma Stone</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Lily} alt="Lily" />
                  <div className="intru">
                    <div className="name">Lily Aldrin</div>
                    <div className="role">How I Met Your Mother (2005)</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ENFJ;
