import { Modal } from "react-bootstrap";
import {MediaUrl, ServerUrl} from "utils/Constants";
function BlogPostDelete(props){
    
    const deleteItem=()=>{
        fetch(`${ServerUrl}/blogpost/posts/${props.post.id}`, {
            method: "DELETE",
            headers: {
              "Authorization": "Token " + localStorage.getItem("loginToken"),
            }
          }).then(
            res => {
                if (res.status === 204) {
                    window.location.reload();
                }
                else alert(res.statusText);
            }
        )
        .catch(e => console.log(e));
    }

    return <Modal show={props.showDeleteModal} onHide={() => props.setShowDeleteModal(false)} style={{ display: "flex" }} className="profile-outer-modal">
    <Modal.Header closeButton className="profile-modal">
        <Modal.Title>حذف پست</Modal.Title>
    </Modal.Header>
    <Modal.Body className="profile-modal">
        <div style={{ direction: "rtl" }}>
            <p>آیا از حذف این پست اطمینان دارید؟</p>
            <p> {props.post.title}</p>
            <div className="justify-content-center" style={{ width: "100%", display: "flex" }}>
                <div className="btn delete-button" onClick={() => deleteItem()}>تایید و حذف</div>
            </div>
        </div>
    </Modal.Body>

</Modal>
}

export default BlogPostDelete;