import { useEffect, useState } from "react";
import {MediaUrl, ServerUrl} from "utils/Constants";
import BlogPostDelete from "./BlogPostDelete";
import "./Blog.scss";

function BlogCard(props) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    return (
        <div className="blog blog-card">
            <div className="post">
                <div className="header">
                {props.post.image?.uploaded_file && <div className="img-container">
                        <img src={props.post.image?.uploaded_file.replace(ServerUrl,MediaUrl)} />
                    </div>}
                    <a className="title" href={`/post/${props.post.id}`}><p dir="auto">{props.post.title}</p></a>
                    <p className="summary" dir="auto">{props.post.summary}</p>
                    <div className="post-info">
                        <p className="keywords" dir="auto">{
                                props.post.tags?.map((tag, idx) => 
                                `${tag}` + (idx < props.post.tags?.length-1 ? "، " : "")
                                )
                            }</p>
                        <p className="authors_duration" dir="auto">{
                            //props.post.author_name?.map((user, idx) => `${user.first_name} ${user.last_name}` + (idx < props.post.authors_name.length-1 ? "، " : ""))
                            `${props.post.author_name?.first_name} ${props.post.author_name?.last_name}`
                            }
                             - {props.post.duration_time}</p>
                    </div>
                    {
                        props.isAdmin && <div className="buttons">
                        <div className="btn delete-btn" onClick={()=>setShowDeleteModal(true)}>حذف</div>
                        <a className="btn edit-btn" href={`/post/${props.post.id}/edit`} >ویرایش</a>
                        </div>
                    }
                    <BlogPostDelete post={props.post} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal}/>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;
