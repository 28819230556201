// import logo from "./logo.svg";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CustomNavbar from "apps/PVQA/components/Navbar/Navbar";
import PageFooter from "apps/PVQA/components/Footer/PageFooter";
import { AuthProvider } from "apps/PVQA/context/AuthContext";
import AppRouter from "apps/PVQA/routes/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import "theme/main.scss";
import "./PVQA_App.css";

function PVQA_App() {
  //for the themes, the character is either l (light) or d (dark)
  const [theme, setThemeVar] = useState("l");
  const [triggerNavbarUpdate, setTriggerNavUpdate] = useState(false);
  useEffect(() => {
    let mode = localStorage.getItem("mode");
    if (!mode) mode = "l";
    setMode(mode);
  }, []);

  const setMode = (mode) => {
    setThemeVar(mode);
    document.documentElement.setAttribute("data-theme", mode);
    localStorage.setItem("mode", mode);
  };

  return (
    <AuthProvider>
      <div className="PVQA_App">
        <CustomNavbar
          setMode={setMode}
          theme={theme}
          triggerNavbarUpdate={triggerNavbarUpdate}
          setTriggerNavUpdate={setTriggerNavUpdate}
        />

        <div className="page-holder">
          <AppRouter
            triggerNavbarUpdate={triggerNavbarUpdate}
            setTriggerNavUpdate={setTriggerNavUpdate}
          />
          <PageFooter />
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </AuthProvider>
  );
}

export default PVQA_App;
