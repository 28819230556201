import "./AcknowledgementList.css";
import boniadImg from "pages/MainLanding/images/boniad.png"
import iustImg from "pages/MainLanding/images/iust-logo.png"
import ravanImg from "pages/MainLanding/images/ravanshenasi.png"

function AcknowledgementList(props){
    return (<div className="acknowledgement row">
        <a href={"/"} className="col-6 col-sm-4"><img src={boniadImg} /></a>
        <a href={"/"} className="col-6 col-sm-4"><img src={iustImg} /></a>
        <a href={"/"} className="col-6 col-sm-4"><img src={ravanImg} /></a>
    </div>)
}

export default AcknowledgementList;