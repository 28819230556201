import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import TablePagination from "../TablePagination";
import { stableSort, getComparator, EnhancedTableHead } from "./common.jsx";
import "./style.scss";
import apiInstance from "config/axios";
function createData(id, username, categories) {
  return {
    id,
    username,
    categories,
  };
}

function Row(props) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow hover tabIndex={-1} key={row.id}>
        {row.categories.map((c) => (
          <TableCell key={c.id}>{c.useless}</TableCell>
        ))}
        <TableCell>
          <p>{row.username}</p>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CategoriesDatasetChart = () => {
  const [annotators, setAnnotators] = useState([]);
  const [headCells, setHeadCells] = useState([
    {
      id: "username",
      label: "نام‌کاربری",
    },
    {
      id: "categories",
      label: "دسته‌بندی‌ها",
    },
  ]);

  const [loading, setLoading] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("username");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - annotators.length) : 0;

  useEffect(() => {
    setLoading(true);
    apiInstance
      .get("/user/stats/categories")
      .then((res) => res.data)
      .then((data) => {
        setHeadCells([
          ...data[0].category_count.map((c, i) => {
            return { id: i, label: c.name };
          }),
          {
            id: "username",
            label: "نام‌کاربری",
          },
        ]);
        setAnnotators(
          data.map((x, i) => createData(i, x.username, x.category_count))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="dataset-page">
        <div className="dataset-page-inner">
          <div
            className="page-title-description"
            style={{ marginBottom: "5vh" }}
          >
            <h1>آمار دیتاست</h1>
          </div>

          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "auto",
                  marginTop: 24,
                }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  color="#20b2aa"
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <>
                {annotators.length == 0 ? (
                  <div>داده ای برای نمایش وجود ندارد.</div>
                ) : (
                  <div className="dataset-chart">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 750 }}>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          headCells={headCells}
                        />
                        <TableBody>
                          {stableSort(annotators, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              return <Row key={row.id} row={row} />;
                            })}
                          {emptyRows > 0 && (
                            <TableRow
                              style={{
                                height: 53 * emptyRows,
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      count={annotators.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesDatasetChart;
