import "./Demo.css";
import { useDropzone } from "react-dropzone";
import { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { toast } from 'react-toastify';
import {MediaUrl, VQAModelUrl} from "utils/Constants";

function Demo(props) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [question, setQuestion] = useState("");
  const [qError, setQError] = useState("");
  const [id, setID] = useState();
  // let showAnswers = true;
  let answersRef = useRef();

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      console.log(files);
    },
    [files]
  );

  function validateFile(file) {
    //null means accepted
    if (file.size > 5_000_000) {
      console.log("حجم تصویر باید کمتر از 5مگابایت باشد");
      return {
        code: "image-size-exceeded",
        message: `حجم تصویر باید کمتر از 5مگابایت باشد`,
      };
    }
    console.log(file);
    return null;
  }

  //can remove this later
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async function deletePhoto(x) {
    // setLoading(true);
    // await timeout(3000);

    fetch(`${VQAModelUrl}/photo/delete-file/${x}`, {
      method: "DELETE",
    })
      .then((response) => {
        console.log("response", response);
        if (response.status == 204)
          console.log("successfully deleted the file");
        // return response.json();
        return response;
      })
      .then(async (rep) => {
        console.log("response", rep);
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoading(false);
    scrollToAnswers();
  }

  async function submitVQ(x) {
    // setLoading(true);
    // await timeout(3000);
    // setAnswer([
    //   { answer: " گل", percentage: 65.5 },
    //   { answer: "بله", percentage: 12.0 },
    //   { answer: "کتاب‌خانه", percentage: 11.09 },
    //   { answer: "چشم", percentage: 9.454 },
    //   { answer: "خیر", percentage: 0.1 },
    // ]);
    // console.log("image id in submit vq :(", id);
    fetch(
      `${VQAModelUrl}/demo/get/?image_id=${x}&question=${question}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        console.log("status", response.status);
        return response.json();
      })
      .then(async (rep) => {
        console.log("response", rep);
        // if (showAnswers)
          setAnswer([
            { answer: rep[0][0], percentage: rep[0][1].toFixed(3) },
            { answer: rep[1][0], percentage: rep[1][1].toFixed(3) },
            { answer: rep[2][0], percentage: rep[2][1].toFixed(3) },
            { answer: rep[3][0], percentage: rep[3][1].toFixed(3) },
            { answer: rep[4][0], percentage: rep[4][1].toFixed(3) },
          ]);
        // else setAnswer([]);
        deletePhoto(x);
      })
      .catch((error) => {
        console.log("error", error);
      });
    // setLoading(false);
    // scrollToAnswers();
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept, //when dragging a file that will be accepted
    isDragReject, //when dragging a file that will be rejected
  } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    validator: validateFile,
    multiple: false,
    //changed onDrop to onDropAccepted
    onDropAccepted: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    // maxSize: 5_000_000
  });

  function scrollToAnswers() {
    window.scrollTo({
      behavior: "smooth",
      top:
        answersRef.current.getBoundingClientRect().top + window.scrollY - 100,
    });
  }

  function uploadPhoto() {
    if (!files || files.length === 0){
      toast.error("لطفا تصویری برای پرسش انتخاب کنید.", {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      return;
    }
    if (emptyQuestion()) return;
    setLoading(true);
    setAnswer([]);
    // showAnswers = true;

    let fd = new FormData();
    fd.append("image", files[0]);
    console.log("this is formdata", fd);

    fetch(`${VQAModelUrl}/photo/upload-file/`, {
      method: "POST",
      body: fd,
    })
      .then((response) => {
        console.log("status", response.status);
        return response.json();
      })
      .then(async (rep) => {
        console.log("response", rep);
        setID(rep.id);
        console.log("image uploaded id is:", rep.id);
        submitVQ(rep.id);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //not working
  // function cancel(e) {
  //   setLoading(false);
  //   showAnswers = false;
  //   console.log("show answer in cancel", showAnswers);
  //   // e.preventDefault();
  //   // setAnswer([]);
  // }
  //not working
  function emptyQuestion() {
    // console.log("this is questino", question);
    if (!question || question.length === 0) {
      // setQError("لطفا پرسش مورد نظرتان را وارد کنید.");
      // console.log("empty question!");
      toast.error("لطفا پرسش مورد نظرتان را وارد کنید.", {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      return true;
    }
    // setQError("");
    return false;
  }

  return (
    <>
      <div className="demo">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div
              {...getRootProps({
                className: "dropzone",
                style: isDragAccept
                  ? {
                      border: "2px solid var(--primary-color)",
                      borderStyle: "dashed",
                    }
                  : isDragReject
                  ? { border: "2px solid red", borderStyle: "dashed" }
                  : {},
              })}
            >
              <input {...getInputProps()} />
              {!files || files.length == 0 ? (
                <>
                  <p>تصویر را اینجا رها کنید یا برای انتخاب تصویر کلیک کنید</p>
                  <ExpandMoreIcon />
                </>
              ) : (
                <>
                  <p>تصویر دیگری را اینجا رها کرده یا برای انتخاب کلیک کنید.</p>
                  <div className="dropzone-hover">
                    <p>تغییر عکس</p>
                  </div>
                </>
              )}
              {files.map((file) => (
                <div key={file.name}>
                  <div className="img-container">
                    <img src={file.preview} />
                  </div>
                </div>
              ))}
            </div>
            <div className="q-submit-field">
              <textarea
                rows={5}
                placeholder="متن پرسش را اینجا بنویسید"
                maxlength="200"
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
              />
              <p className="feedback-text"> {qError}</p>

              <button
                className="btn"
                onClick={() => {
                  uploadPhoto();
                  //   submitVQ();
                }}
                disabled={loading}
              >
                <div className="btn-inner">
                  {"پاسخ"}
                  <ChevronLeftIcon />
                </div>
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="answer-field">
              <div className="answer-field-inner" ref={answersRef}>
                {!answer || answer.length === 0 ? (
                  loading ? (
                    <div className="demo-loading-answer-inner">
                      <h5>در حال یافتن پاسخ</h5>
                      <p>لطفا منتظر بمانید</p>
                      <div className="stage">
                        <div className="dot-flashing"></div>
                      </div>
                      {/* <button className="btn" onClick={() => setLoading(false)}> */}
                      {/* <button className="btn" onClick={cancel}>
                        لغو پاسخ
                      </button> */}
                    </div>
                  ) : (
                    <div className="demo-not-asked-answer">
                      <p>محل نمایش پاسخ‌ها</p>
                    </div>
                  )
                ) : (
                  <div className="demo-answers">
                    <div className="best-answer">
                      <p>بهترین پاسخ:</p>
                      <h2>{answer[0].answer}</h2>
                    </div>
                    <div className="answers">
                      <h4>۵ پاسخ برتر:</h4>
                      {answer.map((a, i) => (
                        <div key={i} className="answer-and-percentage">
                          <p>{a.answer}</p>
                          <div className="percentage">
                            <p>{a.percentage}%</p>
                            <div className="bar">
                              <div
                                className="filled-bar"
                                style={{ width: a.percentage * 0.99 + 1 + "%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Demo;
