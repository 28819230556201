import React, { useState, useMemo } from "react";
import { Select, IconButton, Tooltip, MenuItem } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";
import { MediaUrl, ServerUrl } from "utils/Constants";
import useAuth from "apps/PVQA/context/AuthContext";
import apiInstance from "config/axios";
import "./DatasetList.css";

function Scenario2DatasetList(props) {
  let username = localStorage.getItem("username");
  const category_val = {
    1: "object presence",
    2: "sport recognition",
    3: "positional reasoning",
    4: "sentiment understanding",
    5: "color attributes",
    6: "counting object",
    7: "activity recognition",
    8: "object detection",
    9: "object material",
    10: "gender recognition",
    11: "other",
  };
  const auth = useAuth();

  async function checkPermissions(question) {
    if (question.author.username == username) return true;

    if (auth.isAdmin || auth.isSuperAnnotator) return true;
  }
  const [approved, setApproved] = useState([]);
  const [nonApproved, setNonApproved] = useState([]);

  const CategoryBox = ({ question }) => {
    const [categoryEditMode, setCategoryEditMode] = useState(false);
    const [category, setCategory] = useState(question.category);
    const toggleEditMode = () => {
      setCategoryEditMode(!categoryEditMode);
    };
    const submitEdittedCategory = (qId) => {
      apiInstance
        .patch(`/dataset/persian-data/${qId}`, { category })
        .then((res) => {
          toggleEditMode();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <div>
        {categoryEditMode ? (
          <>
            <div style={{marginBottom:'10px'}}>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {Object.entries(category_val).map(([val, label], i) => (
                  <MenuItem value={val} key={i}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              className="btn"
              onClick={() => submitEdittedCategory(question.id)}
            >
              ثبت
            </div>
          </>
        ) : (
          <span onClick={toggleEditMode}>{category_val[category]}</span>
        )}
      </div>
    );
  };

  const ApproveBox = ({ question }) => {
    const approvedBy = question.approved_by?.username;
    return (
      <td className="cell approved_by">
        {nonApproved.includes(question.id)
          ? "-"
          : approvedBy ??
            (approved.includes(question.id) ? <div>{auth.username}</div> : "-")}
      </td>
    );
  };

  const QuestionStatusBox = ({ question }) => {
    const approvedBy = question.approved_by?.username;
    const approveQ = (qId, isRemove) => {
      apiInstance
        .post(`/dataset/persian-data/${qId}/approve`, { remove: isRemove })
        .then((res) => {
          if (isRemove) {
            setNonApproved([...nonApproved, qId]);
            setApproved(approved.filter((x) => x !== qId));
          } else {
            setApproved([...approved, qId]);
            setNonApproved(nonApproved.filter((x) => x !== qId));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (!auth.isSuperAnnotator) return <></>;
    return (
      <div className="">
        {(!nonApproved.includes(question.id) && approvedBy) ||
        approved.includes(question.id) ? (
          <Tooltip
            title="حذف تایید"
            onClick={() => approveQ(question.id, true)}
          >
            <IconButton>
              <RemoveDoneIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="تایید سوال">
            <IconButton
              color="success"
              onClick={() => approveQ(question.id, false)}
            >
              <DoneAllIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const editQuestion = (question) => {
    checkPermissions(question).then((r) => {
      if (!r) {
        throwError("شما اجازه ویرایش پرسش را ندارید!");
        return;
      }
      document.getElementById(`question-${question.id}`).hidden = true;
      let editBox = document.getElementById(`question-${question.id}-edit`);
      editBox.hidden = false;
      document.getElementById(
        `question-${question.id}-edit-btn`
      ).hidden = false;
      editBox.firstChild.focus();
      editBox.firstChild.setSelectionRange(
        editBox.firstChild.value.length,
        editBox.firstChild.value.length
      );
    });
  };
  function throwError(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const submitEdittedQuestion = (question) => {
    document.getElementById(`question-${question.id}-edit-btn`).hidden = true;
    let new_q_text = document.getElementById(
      `question-${question.id}-edit-val`
    ).value;
    let fd = new FormData();
    fd.append("question", new_q_text);
    fetch(`${ServerUrl}/dataset/persian-data/${question.id}`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
      body: fd, //JSON.stringify({text: new_q_text})
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((res) => {
        if (!res) {
          throwError("مشکلی در ثبت داده رخ داد!");
          return;
        }
        document.getElementById(`question-${question.id}`).hidden = false;
        document.getElementById(`question-${question.id}-edit`).hidden = true;
        props.setReload(!props.reload);
      })
      .catch((err) => {
        console.log(err);
        throwError("مشکلی در ثبت داده رخ داد!");
      });
  };

  const editAnswer = (q_id, answer) => {
    checkPermissions(answer).then((r) => {
      if (!r) {
        throwError("شما اجازه ویرایش پاسخ را ندارید!");
        return;
      }
      document.getElementById(`q-${q_id}-a-1`).hidden = true;
      let editBox = document.getElementById(`q-${q_id}-a-1-edit`);
      editBox.hidden = false;
      document.getElementById(`q-${q_id}-a-1-edit-btn`).hidden = false;
      editBox.firstChild.focus();
      editBox.firstChild.setSelectionRange(
        editBox.firstChild.value.length,
        editBox.firstChild.value.length
      );
    });
  };

  const editLongAnswer = (q_id, answer) => {
    checkPermissions(answer).then((r) => {
      if (!r) {
        throwError("شما اجازه ویرایش پاسخ را ندارید!");
        return;
      }
      document.getElementById(`q-${q_id}-al-1`).hidden = true;
      let editBox = document.getElementById(`q-${q_id}-al-1-edit`);
      editBox.hidden = false;
      document.getElementById(`q-${q_id}-al-1-edit-btn`).hidden = false;
      editBox.firstChild.focus();
      editBox.firstChild.setSelectionRange(
        editBox.firstChild.value.length,
        editBox.firstChild.value.length
      );
    });
  };

  const editFormal = (formal) => {
    checkPermissions(formal).then((r) => {
      if (!r) {
        throwError("شما اجازه ویرایش پاسخ را ندارید!");
        return;
      }
      document.getElementById(`q-${formal.id}-formal`).hidden = true;
      let editBox = document.getElementById(`q-${formal.id}-formal-edit`);
      editBox.hidden = false;
      document
        .getElementById(`q-${formal.id}-formal-edit-btn`)
        .removeAttribute("disabled");
      editBox.firstChild.focus();
      editBox.firstChild.setSelectionRange(
        editBox.firstChild.value.length,
        editBox.firstChild.value.length
      );
    });
  };

  const submitEdittedFormal = (formal) => {
    document.getElementById(`q-${formal.id}-formal-edit-btn`).disabled = true;
    let fd = new FormData();
    fd.append("formal", !formal.formal);
    fetch(`${ServerUrl}/dataset/persian-data/${formal.id}`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
      body: fd, //JSON.stringify({text: new_q_text})
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((res) => {
        if (!res) {
          throwError("مشکلی در ثبت داده رخ داد!");
          return;
        }
        document.getElementById(`q-${formal.id}-formal`).hidden = false;
        document.getElementById(`q-${formal.id}-formal-edit`).hidden = true;
        props.setReload(!props.reload);
      })
      .catch((err) => {
        console.log(err);
        throwError("مشکلی در ثبت داده رخ داد!");
      });
  };

  const submitEdittedAnswer = (q_id, answer) => {
    document.getElementById(`q-${q_id}-a-1-edit-btn`).hidden = true;
    let new_q_text = document.getElementById(`q-${q_id}-a-1-edit-val`).value;
    let fd = new FormData();
    fd.append("short_answer", new_q_text);
    fetch(`${ServerUrl}/dataset/persian-data/${answer.id}`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
      body: fd, //JSON.stringify({text: new_q_text})
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((res) => {
        if (!res) {
          throwError("مشکلی در ثبت داده رخ داد!");
          return;
        }
        document.getElementById(`q-${q_id}-a-1`).hidden = false;
        document.getElementById(`q-${q_id}-a-1-edit`).hidden = true;
        props.setReload(!props.reload);
      })
      .catch((err) => {
        console.log(err);
        throwError("مشکلی در ثبت داده رخ داد!");
      });
  };

  const submitEdittedLongAnswer = (q_id, answer) => {
    document.getElementById(`q-${q_id}-al-1-edit-btn`).hidden = true;
    let new_q_text = document.getElementById(`q-${q_id}-al-1-edit-val`).value;
    let fd = new FormData();
    fd.append("long_answer", new_q_text);
    fetch(`${ServerUrl}/dataset/persian-data/${answer.id}`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("loginToken"),
      },
      body: fd, //JSON.stringify({text: new_q_text})
    })
      .then((res) => (res.ok ? res.json() : null))
      .then((res) => {
        if (!res) {
          throwError("مشکلی در ثبت داده رخ داد!");
          return;
        }
        document.getElementById(`q-${q_id}-al-1`).hidden = false;
        document.getElementById(`q-${q_id}-al-1-edit`).hidden = true;
        props.setReload(!props.reload);
      })
      .catch((err) => {
        console.log(err);
        throwError("مشکلی در ثبت داده رخ داد!");
      });
  };

  return (
    <div className="dataset-list scenario2-dataset-list">
      <table>
        <thead>
          <tr>
            <th>تصویر</th>
            <th>پرسش</th>
            <th>پاسخ کوتاه</th>
            <th>پاسخ بلند</th>
            <th>ثبت کننده</th>
            <th>تاریخ</th>
            <th>نوشتاری</th>
            <th>دسته بندی</th>
            <th>تایید شده توسط</th>
          </tr>
        </thead>
        {/* <tbody> */}
        {/* {props.currentTableData.map(item => {
            return (
              <tr className="main-row">
                <td className='image-cell'>
                    <img src={item.image} alt={`image id ${item.id}`} 
                    onClick={() => window.open(item.image, "_blank")}/>
                    </td>
                {item.questions.map(question => 
                    <>
                    <td className='cell question-cell scenario2-ques' id={`question-${question.id}`} onClick={()=>editQuestion(question)}>{question.text}</td>
                    <td className='cell question-cell scenario2-ques' id={`question-${question.id}-edit`} hidden="true"><textarea /></td>
                    <td className='answers-container answer-cell'>
                        {question.answers.map(answer=> 
                    <div key={answer.id} className='answer cell'>{answer.text}</div>)}</td>
                    </>)}
              </tr>
            );
          })} */}
        {props.currentTableData?.results?.map((item) => {
          return (
            <tbody key={item.image.id}>
              <tr className="main-row border-top">
                <td rowspan={`${item.row_count}`} className="image-cell">
                  <img
                    src={MediaUrl + "/" + item.image.path}
                    alt={`image id ${item.image.id}`}
                    onClick={() =>
                      window.open(MediaUrl + "/" + item.image.path, "_blank")
                    }
                  />
                </td>
                {item.data?.length > 0 && (
                  <>
                    <td
                      rowspan={`1`}
                      className="cell question-cell scenario2-ques"
                      id={`question-${item.data[0].id}`}
                    >
                      <span onClick={(e) => editQuestion(item.data[0])}>
                        {item.data[0].question}
                      </span>
                      <QuestionStatusBox question={item.data[0]} />
                    </td>
                    <td
                      rowspan={`1`}
                      className="cell question-cell scenario2-ques"
                      id={`question-${item.data[0].id}-edit`}
                      hidden="true"
                    >
                      <textarea
                        id={`question-${item.data[0].id}-edit-val`}
                        defaultValue={item.data[0].question}
                        rows="3"
                        autofocus
                      />
                      <div
                        className="btn"
                        id={`question-${item.data[0].id}-edit-btn`}
                        onClick={() => submitEdittedQuestion(item.data[0])}
                      >
                        ثبت
                      </div>
                    </td>

                    {item.data?.length > 0 && (
                      <>
                        <td
                          className="answer cell short-cell"
                          id={`q-${item.data[0].id}-a-1`}
                          onClick={() =>
                            editAnswer(item.data[0].id, item.data[0])
                          }
                        >
                          {item.data[0].short_answer}
                        </td>
                        <td
                          className="answer cell short-cell"
                          id={`q-${item.data[0].id}-a-1-edit`}
                          hidden="true"
                        >
                          <textarea
                            id={`q-${item.data[0].id}-a-1-edit-val`}
                            defaultValue={item.data[0].short_answer}
                            rows="1"
                            autofocus
                          />
                          <div
                            className="btn"
                            id={`q-${item.data[0].id}-a-1-edit-btn`}
                            onClick={() =>
                              submitEdittedAnswer(item.data[0].id, item.data[0])
                            }
                          >
                            ثبت
                          </div>
                        </td>
                      </>
                    )}
                    <>
                      <td
                        className="answer cell  long-cell"
                        id={`q-${item.data[0].id}-al-1`}
                        onClick={() =>
                          editLongAnswer(item.data[0].id, item.data[0])
                        }
                      >
                        {item.data[0].long_answer}
                      </td>
                      <td
                        className="answer cell long-cell"
                        id={`q-${item.data[0].id}-al-1-edit`}
                        hidden="true"
                      >
                        <textarea
                          id={`q-${item.data[0].id}-al-1-edit-val`}
                          defaultValue={item.data[0].long_answer}
                          rows="3"
                          autofocus
                        />
                        <div
                          className="btn"
                          id={`q-${item.data[0].id}-al-1-edit-btn`}
                          onClick={() =>
                            submitEdittedLongAnswer(
                              item.data[0].id,
                              item.data[0]
                            )
                          }
                        >
                          ثبت
                        </div>
                      </td>
                    </>
                    <td rowspan={`1`} className="cell author">
                      {item.data[0].author?.username}
                    </td>
                    <td rowspan={`1`} className="cell time">
                      {item.data[0].update_date_fixed}
                    </td>
                    <td
                      rowspan={`1`}
                      className="cell"
                      id={`q-${item.data[0].id}-formal`}
                      onClick={() => editFormal(item.data[0])}
                    >
                      {item.data[0].formal ? "بله" : "خیر"}
                    </td>
                    <td
                      className="cell"
                      id={`q-${item.data[0].id}-formal-edit`}
                      hidden="true"
                    >
                      <div
                        className="btn"
                        id={`q-${item.data[0].id}-formal-edit-btn`}
                        onClick={() => submitEdittedFormal(item.data[0])}
                      >
                        ثبت به عنوان{" "}
                        {item.data[0].formal ? "محاوره‌ای" : "رسمی"}
                      </div>
                    </td>
                    <td rowspan={`1`} className="cell category">
                      <CategoryBox question={item.data[0]} />
                    </td>
                    <ApproveBox question={item.data[0]} />
                  </>
                )}
              </tr>
              {/* <div className='answers-container answer-cell'> */}
              {/* {item.questions?.length > 0 &&
                item.questions[0].answers?.map((answer, answer_idx) =>
                  answer_idx ? (
                    <tr key={answer.id}>
                      <td
                        className="answer cell"
                        id={`q-${item.questions[0].id}-a-${answer.id}`}
                        onClick={() => editAnswer(item.questions[0].id, answer)}
                      >
                        {answer.text}
                      </td>
                      <td
                        className="answer cell"
                        id={`q-${item.questions[0].id}-a-${answer.id}-edit`}
                        hidden="true"
                      >
                        <textarea
                          id={`q-${item.questions[0].id}-a-${answer.id}-edit-val`}
                          defaultValue={answer.text}
                          rows="1"
                          autofocus
                        />
                        <div
                          className="btn"
                          onClick={() =>
                            submitEdittedAnswer(item.questions[0].id, answer)
                          }
                        >
                          ثبت
                        </div>
                      </td>
                      <td className="answer cell author">
                        {answer.author?.username}
                      </td>
                      <td className="answer cell time">
                        {answer.update_date_fixed}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )
                )} */}
              {/* </div> */}
              {item.data?.map((question, q_idx) => (
                <>
                  {q_idx ? (
                    <>
                      <tr className="border-top2" key={q_idx}>
                        <td
                          rowspan={`1`}
                          className="cell question-cell scenario2-ques"
                          id={`question-${question.id}`}
                        >
                          <span onClick={(e) => editQuestion(question)}>
                            {question.question}
                          </span>
                          <QuestionStatusBox question={question} />
                        </td>
                        <td
                          rowspan={`1`}
                          className="cell question-cell scenario2-ques"
                          id={`question-${question.id}-edit`}
                          hidden="true"
                        >
                          <textarea
                            id={`question-${question.id}-edit-val`}
                            defaultValue={question.question}
                            rows="3"
                            autofocus
                          />
                          <div
                            className="btn"
                            id={`question-${question.id}-edit-btn`}
                            onClick={() => submitEdittedQuestion(question)}
                          >
                            ثبت
                          </div>
                        </td>

                        {question.short_answer && (
                          <>
                            <td
                              className="answer cell short-cell"
                              id={`q-${question.id}-a-1`}
                              onClick={() => editAnswer(question.id, question)}
                            >
                              {question.short_answer}
                            </td>
                            <td
                              className="answer cell short-cell"
                              id={`q-${question.id}-a-1-edit`}
                              hidden="true"
                            >
                              <textarea
                                id={`q-${question.id}-a-1-edit-val`}
                                defaultValue={question.short_answer}
                                rows="1"
                                autofocus
                              />
                              <div
                                className="btn"
                                id={`q-${question.id}-a-1-edit-btn`}
                                onClick={() =>
                                  submitEdittedAnswer(question.id, question)
                                }
                              >
                                ثبت
                              </div>
                            </td>
                          </>
                        )}

                        {question.long_answer && (
                          <>
                            <td
                              className="answer cell long-cell"
                              id={`q-${question.id}-al-1`}
                              onClick={() =>
                                editLongAnswer(question.id, question)
                              }
                            >
                              {question.long_answer}
                            </td>
                            <td
                              className="answer cell long-cell"
                              id={`q-${question.id}-al-1-edit`}
                              hidden="true"
                            >
                              <textarea
                                id={`q-${question.id}-al-1-edit-val`}
                                defaultValue={question.long_answer}
                                rows="3"
                                autofocus
                              />
                              <div
                                className="btn"
                                id={`q-${question.id}-al-1-edit-btn`}
                                onClick={() =>
                                  submitEdittedLongAnswer(question.id, question)
                                }
                              >
                                ثبت
                              </div>
                            </td>
                          </>
                        )}
                        <td rowspan={`1`} className="cell author">
                          {question.author?.username}
                        </td>
                        <td rowspan={`1`} className="cell time">
                          {question.update_date_fixed}
                        </td>
                        <td
                          rowspan={`1`}
                          className="cell"
                          id={`q-${question.id}-formal`}
                          onClick={() => editFormal(question)}
                        >
                          {question.formal ? "بله" : "خیر"}
                        </td>
                        <td
                          className="cell"
                          id={`q-${question.id}-formal-edit`}
                          hidden="true"
                        >
                          <div
                            className="btn"
                            id={`q-${question.id}-formal-edit-btn`}
                            onClick={() => submitEdittedFormal(question)}
                          >
                            ثبت به عنوان{" "}
                            {question.formal ? "محاوره‌ای" : "رسمی"}
                          </div>
                        </td>
                        <td rowspan={`1`} className="cell category">
                          <CategoryBox question={question} />
                        </td>
                        <ApproveBox question={question} />
                      </tr>
                      {/* <div className='answers-container answer-cell'> */}
                      {/* {question.answers?.map((answer, answer_idx) =>
                        answer_idx ? (
                          <tr key={answer.id}>
                            <td
                              className="answer cell"
                              id={`q-${question.id}-a-${answer.id}`}
                              onClick={() => editAnswer(question.id, answer)}
                            >
                              {answer.text}
                            </td>
                            <td
                              className="answer cell"
                              id={`q-${question.id}-a-${answer.id}-edit`}
                              hidden="true"
                            >
                              <textarea
                                id={`q-${question.id}-a-${answer.id}-edit-val`}
                                defaultValue={answer.text}
                                rows="1"
                                autofocus
                              />
                              <div
                                className="btn"
                                onClick={() =>
                                  submitEdittedAnswer(question.id, answer)
                                }
                              >
                                ثبت
                              </div>
                            </td>
                            <td className="answer cell author">
                              {answer.author?.username}
                            </td>
                            <td className="answer cell time">
                              {answer.update_date_fixed}
                            </td>
                            <td className="answer cell time">
                              {answer.update_date_fixed}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )} */}
                      {/* </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </tbody>
          );
        })}
        {/* </tbody> */}
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={props.currentPage}
        totalCount={
          props.currentTableData?.count ? props.currentTableData.count : 1
        }
        pageSize={props.currentTableData?.results_length ? 21 : 1}
        onPageChange={(page) => props.setCurrentPage(page)}
      />
    </div>
  );
}

export default Scenario2DatasetList;
