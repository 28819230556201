import { useEffect, useState } from "react";
import "./Blog.scss";
import {MediaUrl, ServerUrl} from "utils/Constants";

function BlogPost(props) {
    const [post, setPost] = useState({});
    const id = window.location.pathname.split("/")[2];
    useEffect(() => {
        fetch(`${ServerUrl}/blogpost/posts/${id}`, {
            method: "GET",
          }).then(res => res.json())
          .then(res=>{
              setPost(res);
              console.log(res);
              console.log(id);
              document.title = `${res.title} | PersianVQA`;
          })
          .catch(err => alert(err))
    }, []);

    return (
        <div className="blog-page">
            <div className="blog">
                <div className="post-container non-preview-post-container">
                <div className="post">
                <a href={"/blog"} className="back">{"< مشاهده دیگر پست‌ها"}</a>
                    <div className="header">
                        {post.image?.uploaded_file && <div className="img-container">
                            <img src={post.image?.uploaded_file} alt="post-header"/>
                        </div>}
                        <p className="title" dir="auto">{post.title}</p>
                        <p className="summary" dir="auto">{post.summary}</p>
                        <div className="post-info">
                            <p className="keywords" dir="auto">{
                                post.tags?.map((tag, idx) => 
                                `${tag}` + (idx < post.tags?.length-1 ? "، " : "")
                                )
                            }</p>
                            <p className="authors_duration" dir="auto">{
                            //post.authors_name?.map((user, idx) => `${user.first_name} ${user.last_name}` + (idx < post.authors_name.length-1 ? "، " : ""))
                            `${post.author_name?.first_name} ${post.author_name?.last_name}`
                            }
                             - {post.duration_time}</p>
                        </div>
                    </div>
                    <div className="post-inner" dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPost;
