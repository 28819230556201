import { useEffect, useState } from 'react';
import Items from 'components/HorizontalList/HorizontalList';
import './LandingPage.css';
import PeopleMiniView from 'components/PeopleMiniView/PeopleMiniView';
import AcknowledgementList from 'components/AcknowledgementList/AcknowledgementList';

function LandingPage(props) {
    const [blogposts, setBlogPosts] = useState([])
    document.title="پرسش و پاسخ تصویری به زبان فارسی | PersianVQA"

    return <div className="landing" >
        <div className="landing-section">
            <div className="landing-section-inner">
            {/* <h1 >Header!</h1> */}
            <p>this is a landing section! skdfjlsdf lskdfjlsdkfj skdf skdf lskdf lskdf lskdf kslfjslkdfj skdfjl sldkfjl sflkdsj lskdfj lsdkfj lskdfj</p>
            </div>
            <h1 >Projects</h1>
            <Items />
            <h1 >Members</h1>
            <PeopleMiniView />
            <h1 >Acknowledgement Of Support</h1>
            <AcknowledgementList />
            <div style={{height:"20vh"}} />
        </div>
        <div className='landing-empty' />

    </div>
}


export default LandingPage;