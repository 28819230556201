import React from "react";
import "./MBTI_INFO.style.scss";
import "antd/dist/antd.min.css";
import { Card, Space, Col, Row } from "antd";
import Button from "../../utilities/components/button/button.index";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

const MbtiInfo = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const handleClick = (url) => {
    history.push(url);
  };
  return (
    <div className="MBTI-INFO">
      <div className="background scroll ">
        <div className="content  ">
          <div className="info">
            <h1>
              تست شخصیت <span dir="rtl">MBTI</span>
            </h1>
            <div className="infoText">
              تیپ‌نمای مایرز بریگز، که به اختصار
              <span dir="rtl"> MBTI</span> نامیده می‌شود، یک ابزار سنجش شخص است
              که تمایلات و گرایش‌های فرد را مشخص ساخته و نتایج آن تفاوت‌های
              ارزشمندی را به افراد مشخص می‌نماید. تیپ‌نمای مایرز بریگز به افراد
              کمک می‌کند که خود، انگیزه توانایی‌های موجود و بالقوهٔ‌شان را
              بشناسند.
            </div>
            <div className="infoText">
              مایرز بریگز به رغم آن که ۱۶ تیپ شخصیتی را مشخص می‌کند، در مورد
              اینکه کدام یک از آن‌ها ارجحیت دارد، قضاوت نمی‌کند. فقط نشان می‌دهد
              که هر شخص توانایی‌ها و گرایش‌های مخصوص به خود را دارد. این مقیاس
              مبتنی بر نظریه شخصیتی یونگ است.
            </div>
            <div className="infoText">
              در این تست شما به 60 پرسش دو گزینه ای پاسخ خواهید داد و در پایان
              با توجه به پاسخ‌های شما تیپ شخصیتی شما از میان 16 تیپ موجود مشخص
              می‌گردد. این 16 نوع تیپ شخصیتی از جفت ویژگی‌هایی تشکیل شده‌اند که
              با توجه به پاسخ‌هایتان به سوالات شخصیت شما به یکی از فاکتورها
              نزدیک‌تر خواهد بود.
            </div>
          </div>
          <Space direction="vertical">
            <div className="typescard1">
              <h2>برون‌گرا Extrovert </h2>
              <div className="infoText">
                افرادی که برون‌گرایی را ترجیح می‌دهند، تمایل دارند بر دنیای
                بیرونی و افراد و خارجی تمرکز کنند. آنها انرژی و توجه‌شان را به
                طرف خارج معطوف ساخته، از تجربه‌ها و تعاملات بیرونی، انرژی
                می‌گیرند.
              </div>
              <h2>درون‌گرا Introvert</h2>
              <div className="infoText">
                افرادی که درون‌گرایی را ترجیح می‌دهند، تمایل دارند روی تجربیات و
                عقاید دنیای درونی خود تمرکز کنند. انرژی و توجه‌شان را متوجه درون
                کرده و از افکار، احساسات و اندیشه‌های درونی خود انرژی می‌گیرند.
              </div>
            </div>

            <div className="typescard2">
              <h2>حسی Sensing</h2>
              <div className="infoText">
                افرادی که ترجیح می‌دهند با استفاده از چشم، گوش و دیگر حواس
                پنجگانه به آنچه که در اطرافشان می‌گذرد پی ببرند. آنها به هر آن
                چه که در اطرافشان وجود دارد، توجه می‌کنند و به ویژه درباره شناخت
                حقایق عملی یک موقعیت به خوبی عمل می‌کنند.
              </div>

              <h2>شهودی Ntuiting</h2>
              <div className="infoText">
                افرادی که ترجیح می‌دهند با دیدن تصویر بزرگ و تمرکز بر پیوندها و
                ارتباطات موجود آن حقایق، اطلاعات را درک کنند. آن‌ها ترجیح
                می‌دهند الگوها را خوب بفهمند و به ویژه در بیان امکانات جدید
                (خلاقیت) و روش های مختلف کارها، درک و بینش خوبی دارند.
              </div>
            </div>

            <div className="typescard3">
              <h2>فکری Thinking</h2>
              <div className="infoText">
                افرادی که ترجیح می‌دهند در تصمیم‌گیری از تفکر استفاده کنند، به
                نتایج منطقی انتخاب یا عمل توجه دارند. آن‌ها سعی دارند از لحاظ
                فکری خود را از یک موقعیت جدا کرده و بی طرفانه آن را آزموده و علت
                و معلول را تجزیه و تحلیل کنند.
              </div>

              <h2>احساسی Feeling</h2>
              <div className="infoText">
                افرادی که به احساسات دیگران توجه می‌کنند، نیازها و ارزش‌ها را
                درک می‌کنند، احساساتشان را نشان می‌دهند و تحت تأثیر میل به
                قدردانی شدن انگیزه پیدا می‌کنند و در تصمیم‌گیری به آن چه دل بر
                آن گواهی می‌دهد عمل می‌کنند.
              </div>
            </div>

            <div className="typescard4">
              <h2>منضبط Judging</h2>
              <div className="infoText">
                برنامه خودشان را به‌طور دقیق مشخص کرده و بر انجام آن‌ها در سروقت
                تعیین شده پافشاری می‌کنند. به نظم و ترتیب و برنامه خیلی اهمیت
                می‌دهند. سبک زندگی آنها ساختاری و سازمان‌یافته است.
              </div>

              <h2>ملاحظه‌کار Perceiving</h2>
              <div className="infoText">
                انطباق‌پذیر و انعطاف‌پذیر هستند؛ خود را ملزم به انجام کارها در
                اسرع وقت مشخص نمی‌دانند؛ زندگی خود را با توجه به شرایطی که پیش
                می‌آید، تنظیم و اداره می‌کنند؛ در انجام کارهایی که از قبل انجام
                نشده یا فعالیت‌هایی که به سرعت و واکنش فوری نیاز دارد، موفق
                هستند.
              </div>
            </div>

            <div className="typescard5">
              <Button text="بازگشت" onClick={() => handleClick("/")} />
            </div>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default MbtiInfo;
