import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import { RoutePath } from "../pages/data";
import StartPage from "../pages/MainProject/startPage/startpage.index";
import IdTwitter from "../pages/MainProject/idTwitter/idtwitter.index";
import MbtiInfo from "../pages/MainProject/MBTI_INFO/MBTI_INFO.index";
import UserInfo from "../pages/MainProject/User_Info/userInfo.index";
import Questionnaire from "../pages/MainProject/questionnaire/questionnaire.index";
import ISTJ from "../pages/MainProject/Types/ISTJ/ISTJ.index";
import ISTP from "../pages/MainProject/Types/ISTP/ISTP.index";
import ISFJ from "../pages/MainProject/Types/ISFJ/ISFJ.index";
import ISFP from "../pages/MainProject/Types/ISFP/ISFP.index";
import INFJ from "../pages/MainProject/Types/INFJ/INFJ.index";
import INFP from "../pages/MainProject/Types/INFP/INFP.index";
import INTJ from "../pages/MainProject/Types/INTJ/INTJ.index";
import INTP from "../pages/MainProject/Types/INTP/INTP.index";
import ESTP from "../pages/MainProject/Types/ESTP/ESTP.index";
import ESTJ from "../pages/MainProject/Types/ESTJ/ESTJ.index";
import ESFP from "../pages/MainProject/Types/ESFP/ESFP.index";
import ESFJ from "../pages/MainProject/Types/ESFJ/ESFJ.index";
import ENFP from "../pages/MainProject/Types/ENFP/ENFP.index";
import ENTP from "../pages/MainProject/Types/ENTP/ENTP.index";
import ENTJ from "../pages/MainProject/Types/ENTJ/ENTJ.index";
import ENFJ from "../pages/MainProject/Types/ENFJ/ENFJ.index";
import FinalPage from "../pages/MainProject/FinalPage/FinalPage.index";
// import LandingPage from "MBTI/pages/LandingPage/LandingPage";

const AppRouter = ({ triggerNavbarUpdate, setTriggerNavUpdate }) => {
  const [questionnaireValue, setQuestionnaireValue] = useState([]);
  const [Token, setToken] = useState("");
  const [Id, setId] = useState("");
  const [Code, setCode] = useState("");

  const saveAutoToken = (v) => {
    setToken(v);
  };
  const SaveAutoCode = (v) => {
    setCode(v);
  };
  const saveAutoId = (v) => {
    setId(v);
  };

  const saveQuestionnaireValue = (v) => {
    setQuestionnaireValue(v);
  };

  return (
    <Router basename="/mbti">
      <Switch>
        {/* <Route exact path={"/"} element={<StartPage />}></Route> */}
        <Route exact path={"/"} component={StartPage}></Route>
        <Route
          path={RoutePath.UserInfo.Form}
          render={(props) => (
            <UserInfo
              QValue={questionnaireValue}
              token={Token}
              Id={Id}
              SaveCode={SaveAutoCode}
            />
          )}
        ></Route>
        <Route
          path={"/thanks"}
          render={(props) => <FinalPage Code={Code} />}
        ></Route>
        <Route path={RoutePath.Start.MbtiInfo} component={MbtiInfo}></Route>
        <Route
          path={RoutePath.Twitter.GetId}
          render={(props) => (
            <IdTwitter saveToken={saveAutoToken} saveId={saveAutoId} />
          )}
        ></Route>
        <Route
          path={RoutePath.Questionnaire.Question}
          render={(props) => <Questionnaire save={saveQuestionnaireValue} />}
        ></Route>
        <Route path={RoutePath.Types.ENFJ} component={ENFJ}></Route>
        <Route path={RoutePath.Types.ENFP} component={ENFP}></Route>
        <Route path={RoutePath.Types.ENTJ} component={ENTJ}></Route>
        <Route path={RoutePath.Types.ENTP} component={ENTP}></Route>
        <Route path={RoutePath.Types.ESFJ} component={ESFJ}></Route>
        <Route path={RoutePath.Types.ESFP} component={ESFP}></Route>
        <Route path={RoutePath.Types.ESTJ} component={ESTJ}></Route>
        <Route path={RoutePath.Types.ESTP} component={ESTP}></Route>
        <Route path={RoutePath.Types.INFJ} component={INFJ}></Route>
        <Route path={RoutePath.Types.INFP} component={INFP}></Route>
        <Route path={RoutePath.Types.INTJ} component={INTJ}></Route>
        <Route path={RoutePath.Types.INTP} component={INTP}></Route>
        <Route path={RoutePath.Types.ISFJ} component={ISFJ}></Route>
        <Route path={RoutePath.Types.ISFP} component={ISFP}></Route>
        <Route path={RoutePath.Types.ISTJ} component={ISTJ}></Route>
        <Route path={RoutePath.Types.ISTP} component={ISTP}></Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;

// {/* <Switch> */}
// {/* <Route path="/" exact render={(props) => <LandingPage {...props} />} /> */}
