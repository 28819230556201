// import logo from "./logo.svg";
import AppRouter from "apps/MBTI/routes/AppRouter";
import { useState } from "react";
import "./App.css";

function App() {
  const [triggerNavbarUpdate, setTriggerNavUpdate] = useState(false);
  return (
    <div className="App">
      <AppRouter
        triggerNavbarUpdate={triggerNavbarUpdate}
        setTriggerNavUpdate={setTriggerNavUpdate}
      />
    </div>
  );
}

export default App;
