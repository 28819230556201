import { useHistory } from "react-router-dom";
import React from "react";
import "./INFP.style.scss";
import infpPic from "../../../assets/images/types/infp/INFP.jpg";
import Frodo from "../../../assets/images/types/infp/infp1.png";
import Kafka from "../../../assets/images/types/infp/infp2.png";
import Parker from "../../../assets/images/types/infp/infp3.png";
import Chalamet from "../../../assets/images/types/infp/infp4.png";
import Chihiro from "../../../assets/images/types/infp/infp5.png";
import Vincent from "../../../assets/images/types/infp/infp6.png";
import Keanu from "../../../assets/images/types/infp/infp7.png";
import Belle from "../../../assets/images/types/infp/infp8.png";
import Kaneki from "../../../assets/images/types/infp/infp9.png";
import Hayao from "../../../assets/images/types/infp/infp10.png";
// import { useNavigate } from "react-router-dom";

const INFP = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-infp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={infpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> INFP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد INFP: </b>
              پر از وفاداری و هواخواهی پرحرارت می‌باشد. اغلب راجع به شایستگی‌های
              خود صحبت نمی‌کند تا زمانی که شما را به خوبی بشناسد. علاقهٔ فراوانی
              به یادگیری، ایده‌های جدید، زبان و پروژه‌های مستقل دارد. گاهی اوقات
              بیش از حد مسئولیت قبول می‌نماید و اغلب آن را به‌طورکامل به پایان
              می‌رساند. خوش‌برخورد است ولی عموما مشغول تفکر به آن می‌باشد که چه
              عواملی باعث جلب توجه و اجتماعی‌بودن آنها می‌شود.
            </div>
            <div className="jobstitle">شغل‌های مناسب یک INFP:</div>
            <div className="jobs">
              بازیگر، معمار، هنرمند، آهنگساز، مشاور، سردبیر، ویراستار، مفسر،
              مترجم، روزنامه‌نگار، کتاب‌خانه‌دار، ماساژ درمانگر، موسیقی‌دان،
              عکاس، روان‌درمانگر، معلم دینی، نویسنده، پژوهشگر، دانشمند اجتماعی،
              آموزگار
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>از مقررات، نظم، رویه، برنامه و سروقت بودن خوششان نمی‌آید.</li>
              <li>
                نیازی به این ندارند که ارزش‌های خود را به دیگران تحمیل کنند.
                آن‌ها ترجیح می‌دهند که به آرامی نظراتشان را با دیگران در میان
                بگذارند و آن‌ها را متقاعد سازند.
              </li>
              <li>
                آن‌ها اغلب موفق و مصمم هستند و به روشی آرام برنامه‌های خود را
                دنبال می‌کنند.
              </li>
              <li>
                اگر با گروهی باشند می‌توانند رهبری آن‌ها را به‌دست‌بگیرند.
              </li>
              <li>در شرایط رقابت‌آمیز ممکن است با مشکلاتی روبه‌رو شوند.</li>
              <li>
                به استقلال و خودمختاری بها می‌دهند. از قطع‌شدن حواس خود خوششان
                نمی‌آید.
              </li>
              <li>
                دوست دارند هرکاری را که می‌کنند به‌نحو احسن انجام بدهند.
                می‌توانند صبورانه کارهای سنجیده را انجام دهند.
              </li>
              <li>می‌خواهند به خاطر کارشان مورد تشویق و تحسین قرار بگیرند.</li>
              <li>خودشان را با معیارهای سطح بالا مقایسه می‌کنند.</li>
              <li>
                از جوانب مختلف به مسائل نگاه می‌کنند ممکن است گاهی قاطعیت لازم
                نداشته‌باشند زیرا نمی‌دانند برای آن‌ها چه چیزی اهمیت دارد.
              </li>
              <li>
                اگر نتوانند هدف‌های خود را تحقق ببخشند ممکن است مأیوس و دلسرد
                شوند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> INFP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Frodo} alt="Frodo Baggins" />
                  <div className="intru">
                    <div className="name">Frodo Baggins</div>
                    <div className="role">The Lord of the Rings</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kafka} alt="Franz Kafka" />
                  <div className="intru">
                    <div className="name">Franz Kafka</div>
                    <div className="role">نویسنده </div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Vincent} alt="Vincent van Gogh" />
                  <div className="intru">
                    <div className="name">Vincent van Gogh</div>
                    <div className="role">نقاش</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Chalamet} alt="Timothée Chalamet" />
                  <div className="intru">
                    <div className="name">Timothée Chalamet</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Parker} alt="Peter Parker" />
                  <div className="intru">
                    <div className="name">Peter Parker</div>
                    <div className="role">The Spider-Man Trilogy (2002)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Chihiro} alt="Chihiro Ogino" />
                  <div className="intru">
                    <div className="name">Chihiro Ogino</div>
                    <div className="role">Spirited Away</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Keanu} alt="Keanu Reeves" />
                  <div className="intru">
                    <div className="name">Keanu Reeves</div>
                    <div className="role">بازیگر</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Kaneki} alt="Kaneki Ken" />
                  <div className="intru">
                    <div className="name">Kaneki Ken</div>
                    <div className="role">Tokyo Ghoul</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Hayao} alt="Hayao Miyazaki" />
                  <div className="intru">
                    <div className="name">Hayao Miyazaki</div>
                    <div className="role">کارگردان</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Belle} alt="Belle" />
                  <div className="intru">
                    <div className="name">Belle</div>
                    <div className="role">Beauty and the Beast (1991)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default INFP;
