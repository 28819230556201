import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./LandingPage.css";
import Image1 from 'assets/images/blogposts/AI-Twitter-Poll-Feb-2021-1024x841.jpg';
import Image2 from 'assets/images/blogposts/Screenshot 2021-07-31 131624.jpg';
import Image3 from 'assets/images/blogposts/unnamed.png';
import HImage from 'assets/images/1.jpg';
import datasetL from 'assets/images/dataset-light.jpg';
import datasetD from 'assets/images/dataset-dark.jpg';
import Demo from 'apps/PVQA/components/Demo/Demo';
import BlogCard from 'apps/PVQA/components/Blog/BlogCard';
import {MediaUrl, ServerUrl} from "utils/Constants";

function LandingPage(props) {
    const [blogposts, setBlogPosts] = useState([])
    document.title="پرسش و پاسخ تصویری به زبان فارسی | PersianVQA"
    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }
    useEffect(async () => {
        if (document.getElementById("header-image").className.includes("visible"))
            return;
        fetch(`${ServerUrl}/blogpost/posts/latest`, {
            method: "GET",
          }).then(res => res.json())
          .then(res=>{
            setBlogPosts(res.slice(0, 5));
          })
          .catch(err => console.log(err))
        document.getElementById("header-image").className += " right-message-visible"
        await timeout(1000)
        document.getElementById("header-question1").className += " right-message-visible"
        document.getElementById("q-text").textContent += "گل موجود در این تصویر چه رنگی است؟"
        await timeout(1000)
        document.getElementById("header-answer1").className += " left-message-visible"
        await timeout(1500)


        let str = "ورتی"
        let answer_text = document.getElementById("answer-text1")
        answer_text.textContent = "ص"
        for (let i=0; i < str.length; i++) {
            await timeout(100);
            answer_text.textContent += str[i];
        }

        document.getElementById("header-question2").className += " right-message-visible"
        document.getElementById("q-text2").textContent += "آیا این گل مصنوعی است؟"
        await timeout(1000)
        document.getElementById("header-answer2").className += " left-message-visible"
        await timeout(1500)

        str = "یر"
        answer_text = document.getElementById("answer-text2")
        answer_text.textContent = "خ"
        for (let i=0; i < str.length; i++) {
            await timeout(100);
            answer_text.textContent += str[i];
        }
    })
    return <div className="landing">
        <div className="csection csection-right-text">
            <div className="csection-text csection-first-text">
                <h1 style={{ color: "white" }}>پرسش و پاسخ تصویری</h1>
                <p style={{ color: "rgb(231, 241, 250)" }}>فضایی برای یادگیری پرسش و پاسخ تصویری در زبان فارسی و شبکه سازی با متخصصین این حوزه</p>
                <div className="btn" onClick={()=> window.location.href="/pvqa/login"}>وارد شوید/ ثبت نام کنید</div>
            </div>
            <div className="csection-img-container">
                {/* <img src={HImage} alt="header" /> */}
                <div className="header-img-container">
                    {/* <div className="header-img-inner"> */}
                    <div className="message right-message" id="header-image"  style={{maxWidth:"100%", maxHeight:"40vh"}} >
                        <div className="message-inner">
                            <img src={HImage} alt="header-image" />
                        </div>
                    </div>
                    <div className="message right-message" id="header-question1">
                        <div className="message-inner">
                            <p id="q-text"></p>
                        </div>
                    </div>

                    <div className="message left-message" id="header-answer1">
                        <div className="message-inner">
                            <p id="answer-text1">...</p>
                        </div>
                    </div>

                    <div className="message right-message" id="header-question2">
                        <div className="message-inner">
                            <p id="q-text2"></p>
                        </div>
                    </div>

                    <div className="message left-message" id="header-answer2">
                        <div className="message-inner">
                            <p id="answer-text2">...</p>
                        </div>
                    </div>

                    </div>
                </div>
            {/* </div> */}
        </div>
        <div className="csection csection-centered-items csection-intro">
            <div className="csection-text">
                <h1>پرسش و پاسخ تصویری چیست؟</h1>
                <p>پرسش و پاسخ تصویری نسخه گسترش یافته مسئله پرسش و پاسخ متنی است که اطلاعات بصری به مسئله اضافه شده است.</p>
                <p>در سیستم پرسش و پاسخ تصویری، یک تصویر و یک سوال متنی به ورودی سیستم داده می‌شود
                    و انتظار می‌رود که سیستم بتواند با استفاده از عناصر بصری تصویر و تفسیری که از سوال بدست می‌آورد؛ یک پاسخ متنی را در خروجی نشان دهد.
                </p>
                <p>
                    مسئله پرسش و پاسخ تصویری پیچیدگی بیشتری نسبت به مسئله پرسش و پاسخ متنی دارد
                    زیرا تصاویر بعد بالاتر و نویز بیشتری نسبت به متن دارند. علاوه بر این، تصاویر فاقد ساختار و قواعد دستوری زبان هستند.
                    در نهایت هم، تصاویر غنای بیشتری از دنیای واقعی را ضبط می‌کنند، در حالی که زبان طبیعی در حال حاضر نشانگر سطح بالاتری از انتزاع دنیای واقعی است.
                </p>
            </div>
        </div>
        <div className="csection">
            <div className="page-title-description" style={{ marginBottom: "5vh" }}>
                <h1>دمو</h1>
                <p dir="rtl" style={{ margin: "5vh" }}>برای طراحی این دمو از مدل
                    Stack Attention Network
                    استفاده کرده ایم.
                    برای آموزش این مدل از دادگان
                    pvqa v1
                    که به وسیله
                    Google Translate
                    ترجمه ماشینی شده است؛ بهره برده ایم. برای استفاده از این دمو کافی است یک تصویر و یک سوال را در مکان مخصوص به خودشان بارگذاری نمایید.
                    سپس با فشردن دکمه «پاسخ» می توانید جواب سوال را مشاهده کنید.</p>
                {/* <div>
                    <div className="btn">ورود به صفحه دمو</div> 
                    <div className="btn">راهنمای کار با دمو</div>
                </div> 
                */}

            </div>
            {/* <div className="csection-img-container">
                <img src={HImage} alt="header" />
            </div> */}
            <div className="demo-page-inner" style={{ margin: "0 5%" }}>
                <Demo />
            </div>
        </div>
        <div className="csection csection-right-text">
            <div className="csection-text">
                <h1>جمع‌آوری دیتاست</h1>
                <p>با ورود به حساب شخصی خود و ایجاد داده‌های بیشتر، به ما کمک کنید!</p>
                {localStorage.getItem("loginToken") ?
                    <div className="btn" onClick={()=> window.location.href = "/pvqa/dataset"}>کمک به جمع‌آوری دیتاست</div>
                    :
                    <div className="btn" onClick={() => window.location.href = "/pvqa/login"}>ابتدا وارد شوید/ ثبت نام کنید</div>
                }
            </div>
            <div className="csection-img-container" style={{padding: "0 5%"}}>
                <img src={localStorage.getItem("mode") === 'l' ? datasetL : datasetD} className="dataset-img" alt="dataset-example" />
            </div>
        </div>
        <div className="csection">
            <h1>بلاگ‌پست‌ها</h1>
            <Carousel>
                {blogposts.map((item, i) => {
                    if (item)
                        return (<Carousel.Item key={i} className="blog-post-holder">
                            <BlogCard post={item}/>
                        </Carousel.Item>)
                })}
            </Carousel>
        </div>
        <div className="csection csection-centered-items">
            <div className="csection-text">
            <h1>دربارۀ ما</h1>
            <p dir="rtl" style={{ margin: "5vh" }}>
                این وبسایت به تلاش گروهی از دانشجویان آزمایشگاه پردازش زبان طبیعی دانشگاه علم و صنعت زیر نظر دکتر سید صالح اعتمادی راه‌اندازی شده است.
                هدف از این وبسایت ایجاد فضایی صمیمی و دوستانه برای یادگیری حوزه پرسش و پاسخ تصویری در زبان فارسی و شبکه‌سازی با متخصصان و علاقه‌مندان این حوزه است.
                در صورتی که تمایل به همکاری با ما دارید با ایمیل hashemi94@comp.iust.ac.ir در ارتباط باشید.
            </p>
            </div>
        </div>
        <div className="csection csection-centered-items">
        <div className="csection-text">
          <h1>مسابقه پرسش و پاسخ تصویری</h1>
          <p dir="rtl" style={{ margin: "5vh" }}>
            در سایت kaggle یک مینی‌پروژه تعریف شده‌است.
          </p>
          <a
            href="https://www.kaggle.com/c/minivqaiust/overview"
            rel="noreferrer"
          >
            برای شرکت در آن کلیک کنید.
          </a>
        </div>
      </div>
    </div>
}

export default LandingPage;