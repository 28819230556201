import { useHistory } from "react-router-dom";
import { React, useState } from "react";
import Button from "../../utilities/components/button/button.index";
import Input from "../../utilities/components/input/input.index";
import "./idtwitter.style.scss";
import { Route } from "react-router-dom";
import { Modal } from "antd";
import "antd/dist/antd.min.css";
import { get, responseValidator, post } from "../../scripts/api";
import { APIPath, RoutePath } from "../../data";
// import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const IdTwitter = (props) => {
  // const navigate = useNavigate();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHave, setIsHave] = useState(false);
  const [Id, setID] = useState("");
  const [token, setToken] = useState("");

  const checkHave = () => {
    setIsHave(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const CreateRes = () => {
    const body = { twitter_id: Id };
    post(APIPath.createRespondent.create, body).then((data) => {
      if (data.status == 201) {
        setToken(data.data.auth_token);
        props.saveToken(data.data.auth_token);
        props.saveId(Id);
        history.push("/questionnaire");
      } else {
        toast.error(
          "متاسفانه اکانت شما برای سیستم ما قابل استفاده نیست (احتمالاً اکانتتون پرایوته یا تعداد توییت کمی داره و یا تازه‌سازه). از همکاری شما ممنونیم."
        );
      }
    });
  };

  return (
    <div className="background-idtwitter">
      <div className="content">
        <div>
          <li>
            آیا اکانت توییتری دارید که هم
            <span dir="rtl">
              <b> public </b>
            </span>
            باشه و هم اون‌جا بالای 150 تا توییت کرده باشید؟
          </li>
        </div>
        <b>
          توجه کنید که آی‌دی توییترتون فقط برای جمع‌آوری متون استفاده می‌شه
          بنابراین آی‌دی توییتر شما توی هیچ مرحله‌ای از کار پژوهشی ما جایی منتشر
          نمی‌شه.
        </b>
        <div className="btns">
          <div className="Nobtn" onClick={showModal}>
            <Button text="ندارم" />
          </div>
          <Modal
            class="ant-modal-body"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            <div className="textmodal">
              خیلی ممنون از این‌که تا این‌جا ما رو همراهی کردید اما متأسفانه
              اطلاعات شما برای ما قابل استفاده نیست. اگه مایلید جدا از این
              پرسش‌نامه تیپ شخصیتی خودتون رو پیدا کنید می‌تونید از لینک زیر
              استفاده کنید:
            </div>
            <p style={{ direction: "ltr" }}>
              <a href="https://www.16personalities.com/fa">
                "این جارو کلیک کن"
              </a>
            </p>
          </Modal>

          <div className="Yesbtn" onClick={checkHave}>
            <Button text="دارم" />
          </div>
        </div>

        {isHave && (
          <div className="getIdBox">
            <p>
              <li>لطفاً آی‌دی توییتر خودتون رو وارد کنید.</li>
            </p>
            <div className="btninpBox">
              <input
                className="inpBox"
                placeholder="آی‌دی توییتر"
                onChange={(event) => setID(event.target.value)}
              ></input>
              <Button text="وارد کردم" onClick={CreateRes} />
            </div>

            <p>
              لطفاً اطمینان حاصل کنید که اکانت متعلق به خودتون باشه چون کد تخفیف
              از طریق دایرکت همین اکانت به شما ارسال خواهد شد.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default IdTwitter;
