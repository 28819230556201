import React from "react";
import MainNav from "components/MainNav";
import "./style.scss";

export default function HomePage() {
  return (
    <div>
      <MainNav />
    </div>
  );
}
