import { useHistory } from "react-router-dom";
import React from "react";
import "./ESFP.style.scss";
import esfpPic from "../../../assets/images/types/esfp/ESFP.jpg";
import Seong from "../../../assets/images/types/esfp/esfp1.png";
import Gon from "../../../assets/images/types/esfp/esfp2.png";
import Lucifer from "../../../assets/images/types/esfp/esfp3.png";
import Han from "../../../assets/images/types/esfp/esfp4.png";
import Rachel from "../../../assets/images/types/esfp/esfp5.png";
import Ron from "../../../assets/images/types/esfp/esfp6.png";
import Nicki from "../../../assets/images/types/esfp/esfp7.png";
import Jesse from "../../../assets/images/types/esfp/esfp8.png";
import Freddie from "../../../assets/images/types/esfp/esfp9.png";
import Joey from "../../../assets/images/types/esfp/esfp10.png";
// import { useNavigate } from 'react-router-dom';

const ESFP = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-esfp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={esfpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ESFP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ESFP: </b>
              برونگرا، زود جوش، مهمان‌نواز و خوش‌برخورد می‌باشد. علاقهٔ زیادی به
              لذت‌بردن از زمان حال دارد.به ورزش، تولید و ساخت علاقه دارد. معمولا
              می‌داند اطراف او چه می‌گذرد و بسیار مشتاقانه حاضر به همکاری
              می‌باشد و برای او به حافظه‌سپردن حقایق و اطلاعات اهمیت بیشتری نسبت
              به تئوری‌های پیچیده دارد و بهترین توانایی‌های او در زمینهٔ
              پیداکردن راه‌حل‌های منطقی و قابل قبول در مورد اختلافی پیچیده
              می‌باشد. با مردم و موضوعات روزمره به راحتی کنار می‌آید.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ESFP:</div>
            <div className="jobs">
              رام‌کننده حیوانات، مربیِ ورزش، طراح، متخصص رژیم‌های غذایی، مربی
              آمادگی‌های جسمانی، مهماندار هواپیما، دستیار پزشک، موسیقی‌دان،
              پرستار، بازیگر صحنه، افسر پلیس، ورزشکار حرفه‌ای، متخصص روابط
              عمومی، مدیرعاملِ ملک و املاک، فروشنده، مددکارِ اجتماعی، آموزگار،
              اپراتورِ حمل و نقل، مسئولِ آژانس مسافرتی، دام‌پزشک یا دستیار
              دام‌پزشکی
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>
                کارکردن در فضایی مهیج با اشخاصی دوستانه و پرانرژی را دوست دارند.
              </li>
              <li>با مردم رابطهٔ خوبِ حسنه‌ای دارند.</li>
              <li>
                تنوع را دوست دارند طوری که مشاغلشان را به سرعت تغییر می‌دهند.
              </li>
              <li>
                مرتبأ محیط را وارسی می‌کنند و به سرعت بر موقعیت حاکم می‌شوند .
              </li>
              <li>برای برطرف‌کردن تضاد و تعارض از مهارتِ فراوان برخوردارند.</li>
              <li>
                می‌توانند به دیگران کمک کنند تا کار گروهی را به نمایش بگذارند.
              </li>
              <li>
                می‌توانند به خوبی به دیگران انگیزه بدهند تا تصمیم بگیرند و کار
                گروهی را تقویت کنند .
              </li>
              <li>از کارهایی که بتوانند به دیگران خدمت کنند لذت می‌برند.</li>
              <li>دوست دارند که در اسرع وقت به راه حل برسند.</li>
              <li>
                در بسیاری از مواقع تا آخرین لحظه صبر می‌کنند تا کاری را انجام
                بدهند.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ESFP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={Seong} alt="Seong Gi-hun/No. 456" />
                  <div className="intru">
                    <div className="name">Seong Gi-hun/No. 456</div>
                    <div className="role">Squid Game (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Gon} alt="Gon Freecss" />
                  <div className="intru">
                    <div className="name">Gon Freecss</div>
                    <div className="role">Hunter X Hunter</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Lucifer} alt="Lucifer Morningstar" />
                  <div className="intru">
                    <div className="name">Lucifer Morningstar</div>
                    <div className="role">Lucifer (2016)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Han} alt="Han Mi-nyeo/No. 212" />
                  <div className="intru">
                    <div className="name">Han Mi-nyeo/No. 212</div>
                    <div className="role">Squid Game (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Rachel} alt="Rachel Green" />
                  <div className="intru">
                    <div className="name">Rachel Green</div>
                    <div className="role">Friends (1994)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Ron} alt="Ron Weasley" />
                  <div className="intru">
                    <div className="name">Ron Weasley</div>
                    <div className="role">Harry Potter (2007)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Nicki} alt="Nicki Minaj" />
                  <div className="intru">
                    <div className="name">Nicki Minaj</div>
                    <div className="role">خوانندهٔ پاپ</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Jesse} alt="Jesse Pinkman" />
                  <div className="intru">
                    <div className="name">Jesse Pinkman</div>
                    <div className="role">Breaking Bad (2008)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Freddie} alt="Freddie Mercury" />
                  <div className="intru">
                    <div className="name">Freddie Mercury</div>
                    <div className="role">خوانندهٔ راک</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Joey} alt="Joey Tribbiani" />
                  <div className="intru">
                    <div className="name">Joey Tribbiani</div>
                    <div className="role">Friends (1994)</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ESFP;
