import { useHistory } from "react-router-dom";
import React from "react";
import "./ENTP.style.scss";
import entpPic from "../../../assets/images/types/entp/ENTP.jpg";
// import { useNavigate } from "react-router-dom";

import jiyeong from "../../../assets/images/types/entp/entp1.png";
import Barack from "../../../assets/images/types/entp/entp2.png";
import Rick from "../../../assets/images/types/entp/entp3.png";
import BoJack from "../../../assets/images/types/entp/entp4.png";
import Chandler from "../../../assets/images/types/entp/entp5.png";
import Captain from "../../../assets/images/types/entp/entp6.png";
import Jim from "../../../assets/images/types/entp/entp7.png";
import Socrates from "../../../assets/images/types/entp/entp8.png";
import Tony from "../../../assets/images/types/entp/entp9.png";
import Edward from "../../../assets/images/types/entp/entp10.png";

const ENTP = () => {
  const history = useHistory();
  // const navigate = useNavigate();
  return (
    <div className="back-entp">
      <div className="card">
        <div className="content">
          <div className="typeimg">
            <img src={entpPic} class="responsive"></img>
          </div>
          <div className="info scroll">
            <div className="title">
              شما یک
              <span dir="rtl">
                <b> ENTP </b>
              </span>
              هستید.
            </div>
            <div className="explain">
              <b>یک فرد ENTP: </b>
              صریح و بی‌ریا می‌باشد و در زمینه‌های مختلف توانایی دارد یك دوست
              پرهیجان، پرحرف و باهوش و رك باشد. برای لذت‌بردن از هر دو طرف یک
              موضوع بحث می‌کند. در پیداکردن راه‌حل‌های مبتکرانه در مورد موضوعات
              پیچیده از خود مهارت خاصی نشان می‌دهد.از انجام کارهای یکنواخت
              روزانه سرباز می‌زند همیشه درحالِ یافتن موضوع جدید مورد علاقه
              می‌باشد. قادر به یافتن دلایل منطقی برای هرکاری که می‌خواهد
              انجام‌دهد می‌باشد.
            </div>
            <div className="jobstitle"> شغل‌های مناسب یک ENTP:</div>
            <div className="jobs">
              بازیگر، مدیرِ تبلیغات، وکیل، تحلیل‌گر کامپیوتر، مشاور، مهندس،
              کارفرما، برنامه‌ریز مالی، مخترع، بازاریاب، پزشک، روانشناس، کارمند
              روابط‌ عمومی، سخنران، بازسازی‌کننده ساختمان‌ها، متخصص تحقیق و
              توسعه، فروشنده، دانشمندِ اجتماعی، استادِ دانشگاه، نویسنده
            </div>
            <div className="Propertytitle">ویژگی‌های بارز:</div>
            <div className="Property">
              <li>از نبوغ و فکر بکر خود برای حل مسائلشان استفاده می‌کنند. </li>
              <li>
                اشخاصی با هوش هستند و می‌توانند دیگران را متقاعد کنند تا
                نقطه‌نظر آن‌ها را باور نمایند.{" "}
              </li>
              <li>
                به روش‌های سنتی انجام‌دادن کارها بی‌علاقه و به دنبال روش‌های
                تازه‌اند.{" "}
              </li>
              <li>
                اشخاصی الهام‌بخش هستند. بنابر سريع‌الانتقال‌بودن، اعتمادبه‌نفس و
                نیز به خاطر مهارت‌هایشان هواداران زیادی دارند.{" "}
              </li>
              <li>علاقه به انجام همزمان چند کار با هم دارند. </li>
              <li>ارتباط با آدم‌های مختلفی را دوست دارند. </li>
              <li>
                نمی‌توانند توجه خود را محدود کنند زیرا موضوعات عدیده‌ای برای
                آن‌ها جالب است.
              </li>
            </div>
            <div className="personstitle">
              <span dir="rtl">
                <b> ENTP </b>
              </span>
              هایی که ممکنه بشناسی:
            </div>
            <div className="persons">
              <div className="item">
                <div className="item-detail">
                  <img src={jiyeong} alt="jiyeong" />
                  <div className="intru">
                    <div className="name">Ji-yeong/No.240</div>
                    <div className="role">Squid Game (2021)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Barack} alt="Barack" />
                  <div className="intru">
                    <div className="name">Barack Obama</div>
                    <div className="role">سیاست‌مدار</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Rick} alt="Rick" />
                  <div className="intru">
                    <div className="name">Rick Sanchez</div>
                    <div className="role">Rick and Morty (2013)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={BoJack} alt="BoJack Horseman" />
                  <div className="intru">
                    <div className="name">BoJack Horseman</div>
                    <div className="role">BoJack Horseman (2014)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Chandler} alt="Chandler Bing" />
                  <div className="intru">
                    <div className="name">Chandler Bing</div>
                    <div className="role">Friends (1994)</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item-detail">
                  <img src={Captain} alt="Captain Jack Sparrow" />
                  <div className="intru">
                    <div className="name">Captain Jack Sparrow</div>
                    <div className="role">Pirates of the Caribbean</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Jim} alt="Jim Halpert" />
                  <div className="intru">
                    <div className="name">Jim Halpert</div>
                    <div className="role">The Office (2005)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Socrates} alt="Socrates" />
                  <div className="intru">
                    <div className="name">Socrates</div>
                    <div className="role">فیلسوف</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Tony} alt="Tony Stark “Iron Man”" />
                  <div className="intru">
                    <div className="name">Tony Stark</div>
                    <div className="role">Iron Man (2008)</div>
                  </div>
                </div>
                <div className="item-detail">
                  <img src={Edward} alt="Edward Elric" />
                  <div className="intru">
                    <div className="name">Edward Elric</div>
                    <div className="role">Fullmetal Alchemist</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="content-button"
                type="button"
                onClick={() => history.push("/thanks")}
              >
                دریافت کد تخفیف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ENTP;
