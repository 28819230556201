import React, { useState } from "react";
import "./Auth.css";
import { useSpring, animated } from "react-spring";
import { Link, Redirect, withRouter, useHistory } from "react-router-dom";
import { MediaUrl, ServerUrl } from "utils/Constants";
import { ToastContainer, toast } from "react-toastify";
var moment = require("moment-jalaali");

function Auth() {
  const [registrationFormStatus, setRegistartionFormStatus] = useState(false);
  const [Date, setDate] = useState({
    value: ["", "", ""],
    error: ["", "", "", ""],
  });
  const [fullName, setFullName] = useState({ first: "", last: "", error: "" });
  const [username, setUsername] = useState({ value: "", error: "" });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPass] = useState({
    value: "",
    error: "",
    confirmErr: "",
  });
  const [gender, setGender] = useState("NS");

  const [loginUsername, setLoginUsername] = useState({ value: "", error: "" });
  const [loginPassword, setLoginPassword] = useState({ value: "", error: "" });
  const [loginError, setLoginError] = useState("");
  const history = useHistory();

  document.title = "ورود | PersianVQA";

  //#region
  //signup functions
  //for validation
  function changeDate(value, idx /*, errvalue, erridx*/) {
    let date = [...Date.value];
    date[idx] = value;
    // let err = [...Date.error];
    // err[erridx] = errvalue;
    // setDate({value : date, error : err})
    return date;
  }

  function arrayElementsEmpty(arr) {
    for (let i in arr) {
      if (!!arr[i]) return false;
    }
    return true;
  }

  function validateFullName() {
    if (!fullName.first || !fullName.last) {
      setFullName({
        first: fullName.first,
        last: fullName.last,
        error: "لطفا نام و نام خانوادگیتان را وارد کنید.",
      });
      return false;
    }
    return true;
  }

  function validateDate(val, pos, pos_num, true_length) {
    let new_date = null;
    let err = [...Date.error];
    new_date = [...changeDate(val, pos_num)];

    if (err[3]) err[3] = "";

    let userError = "";
    if (!val) {
    } else if (val.match(/^\d+$/) === null) {
      userError = "تنها عدد برای " + pos + " وارد کنید ";
    } else if (val.length !== true_length) {
      userError = pos + " باید " + true_length + "رقمی باشد ";
    }
    err[pos_num] = userError;

    setDate({ value: new_date, error: err });
  }

  function validateDateFinal() {
    if (arrayElementsEmpty(Date.value)) return true;
    let m = moment(Date.value.join("/"), "jYYYY/jMM/jDD");
    let formatted = m.format("jYYYY/jM/jD [is] YYYY/MM/DD");
    console.log(formatted);
    if (formatted === "Invalid date") {
      let err = [...Date.error];
      err[3] = "تاریخ وارد شده نادرست است.";
      setDate({ value: Date.value, error: err });
      return false;
    }
    return true;

    // if (! /^[1-4]\d{3}\/((0[1-6]\/((3[0-1])|([1-2][0-9])|(0[1-9])))|((1[0-2]|(0[7-9]))\/(30|([1-2][0-9])|(0[1-9]))))$/.test(Date.value.join("/"))) {
    //   let err = [...Date.error];
    //   err[3] = "تاریخ وارد شده نادرست است.";
    //   setDate({value : Date.value, error : err})
    //   return false;
    // }
    // else{
    //   return true;
    // }
  }
  //at least one letter, number or @.+-_
  const userregex =
    /^((?=.*[0-9]{1,})|(?=.*[@.+-_]{1,}))|(?=.*[a-zA-Z]{1,}).{1,}$/;
  function validateUsername(e) {
    let userError = "";
    if (!userregex.test(String(e).toLowerCase())) {
      userError =
        "نام کاربری می تواند شامل حروف، اعداد و کاراکترهای @.+-_ باشد.";
    }
    setUsername({ value: e, error: userError });
  }

  const emailregex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  function validateEmail(e) {
    let userError = "";
    if (!emailregex.test(String(e).toLowerCase())) {
      userError = "ایمیل به درستی وارد نشده است!";
    }
    setEmail({ value: e, error: userError });
  }

  const passregex =
    /^((?=.*[0-9]{0,})|(?=.*[!.@#$&*-_]{0,}))(?=.*[a-zA-Z]{1,}).{8,}$/;
  function validatePass(val) {
    let userError = "";
    if (!passregex.test(String(val).toLowerCase())) {
      userError =
        "رمز عبور باید شامل حروف باشد، میتواند اعداد و کاراکترهای @.+-_ نیز داشته باشد (حداقل 8 حرف).";
    }
    setPass({ value: val, error: userError });
  }

  function validateConfirmPass(val) {
    if (val !== password.value)
      setPass({
        value: password.value,
        error: password.error,
        confirmErr: "رمز عبور متفاوت است.",
      });
  }

  function register(e) {
    e.preventDefault();

    var nodes = [
      ...document.getElementById("registerform").firstElementChild.children,
    ];
    nodes = nodes.filter(
      (x) => x.nodeName === "INPUT" || x.className === "fullname-container"
    );
    nodes.forEach((node) => {
      if (node.className === "fullname-container") {
        node = [...node.children];
        node
          .filter((x) => x.nodeName === "INPUT")
          .forEach((innernode) => {
            innernode.focus();
            innernode.blur();
          });
      } else {
        node.focus();
        node.blur();
      }
    });

    if (
      !validateFullName() ||
      !arrayElementsEmpty(Date.error) ||
      !validateDateFinal() ||
      username.error ||
      email.error ||
      password.error ||
      password.confirmErr ||
      !fullName.first ||
      !fullName.last
    )
      return;

    let fd = new FormData();
    fd.append("username", username.value);
    fd.append("first_name", fullName.first);
    fd.append("last_name", fullName.last);
    fd.append("email", email.value);
    fd.append("password1", password.value);
    fd.append("password2", password.value);
    fd.append("gender", gender);
    if (!arrayElementsEmpty(Date.value)) {
      let m = moment(Date.value.join("/"), "jYYYY/jM/jD");
      fd.append("birth_date", m.format("YYYY-MM-DD"));
    }

    fetch(`${ServerUrl}/dj-rest-auth/registration/`, {
      method: "POST",
      body: fd,
    })
      .then((res) => {
        if (res.ok) return null;
        else return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.success("ثبت نام با موفقیت انجام شد.", {
            position: "bottom-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRegistartionFormStatus(false);
          return;
        }
        if (res.username)
          setUsername({
            value: username.value,
            error: "نام کاربری تکراریست. لطفا نام دیگری را امتحان کنید.",
          });
        if (res.email)
          setEmail({
            value: email.value,
            error: "ایمیل تکراریست. لطفا ایمیل دیگری را امتحان کنید.",
          });
        if (res.password1) {
          res.password1.forEach((err) => {
            switch (err) {
              case "This password is too common.":
                setPass({
                  value: password.value,
                  error: "رمز عبور ساده است. لطفا رمز عبور سختتری انتخاب کنید.",
                });
            }
          });
        }
        if (res.non_field_errors) {
          res.non_field_errors.forEach((err) => {
            if (err.includes("The password is too similar to the"))
              setPass({
                value: password.value,
                error:
                  "رمز عبور مشابه اطلاعات وارد شده است. از رمز عبور دیگری استفاده کنید یا فیلد مشابه را تغییر دهید.",
              });
          });
        }
      })
      .catch((e) => console.log(e));
  }
  //#endregion

  //#region
  //login functions
  function validateLoginUsername(u) {
    let errorMessage = "";
    if (u.length === 0) errorMessage = "لطفا نام کاربری خود را وارد کنید.";
    setLoginUsername({
      value: u,
      error: errorMessage,
    });
  }

  function validateLoginPassword(p) {
    let errorMessage = "";
    if (p.length === 0) errorMessage = "لطفا رمز عبور خود را وارد کنید.";
    setLoginPassword({
      value: p,
      error: errorMessage,
    });
  }

  function ReturnWhereLoginIsCalledFrom() {
    if (
      history.location &&
      history.location.state &&
      history.location.state.from
    ) {
      return history.location.state.from;
    }
    return "/";
  }

  //to add delay to a function
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const asyncLocalStorage = {
    setItem: async function (key, value) {
      await null;
      return localStorage.setItem(key, value);
    },
    getItem: async function (key) {
      await null;
      return localStorage.getItem(key);
    },
  };

  function login(e) {
    e.preventDefault();
    // console.log("got here!");

    var nodes = [
      ...document.getElementById("loginform").firstElementChild.children,
    ];
    nodes = nodes.filter((x) => x.nodeName === "INPUT");
    nodes.forEach((node) => {
      node.focus();
      node.blur();
    });

    if (loginUsername.error || loginPassword.error) return;

    let fd = new FormData();
    fd.append("username", loginUsername.value);
    fd.append("password", loginPassword.value);

    var myHeaders = new Headers();

    fetch(`${ServerUrl}/dj-rest-auth/login/`, {
      method: "POST",
      body: fd,
      headers: myHeaders,
    })
      .then((response) => {
        console.log("status", response.status);
        return response.json();
      })
      .then(async (rep) => {
        for (let key in rep) {
          console.log(key);
          switch (key) {
            case "non_field_errors":
              console.log("wrong info");
              setLoginError(
                "اطلاعات وارد شده صحیح نیست. لطفا مجددا تلاش کنید."
              );
              break;

            case "key":
              console.log("am i here?");
              asyncLocalStorage
                .setItem("loginToken", rep[key])
                .then(() =>
                  asyncLocalStorage
                    .setItem("username", loginUsername.value)
                    .then(() => (window.location.href = "/pvqa"))
                );
              break;

            default:
              console.log(rep[key]);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //#endregion

  const loginProps = useSpring({
    left: registrationFormStatus
      ? window.screen.width > 500
        ? -window.screen.width * 0.5
        : -window.screen.width
      : 0, // Login form sliding positions
  });
  const registerProps = useSpring({
    height: registrationFormStatus ? "100%" : "350px",
    minHeight: "350px",
    left: registrationFormStatus
      ? 0
      : window.screen.width > 500
      ? window.screen.width * 0.5
      : window.screen.width, // Register form sliding positions
  });

  const loginBtnProps = useSpring({
    borderBottom: registrationFormStatus
      ? "solid 0px transparent"
      : "solid 2px #1059FF", //Animate bottom border of login button
  });
  const registerBtnProps = useSpring({
    borderBottom: registrationFormStatus
      ? "solid 2px #1059FF"
      : "solid 0px transparent", //Animate bottom border of register button
  });

  function registerClicked() {
    setRegistartionFormStatus(true);
  }
  function loginClicked() {
    setRegistartionFormStatus(false);
  }

  return (
    <div className="login-register-page">
      <div className="login-register-wrapper">
        <div className="nav-buttons">
          <animated.button
            onClick={loginClicked}
            id="loginBtn"
            style={loginBtnProps}
          >
            ورود
          </animated.button>
          {/* <animated.button
            onClick={registerClicked}
            id="registerBtn"
            style={registerBtnProps}
          >
            ثبت نام
          </animated.button> */}
        </div>
        <div className="form-group">
          <animated.form action="" id="loginform" style={loginProps}>
            {/* <LoginForm /> */}
            <div>
              <label htmlFor="loginusername">نام کاربری</label>
              <input
                type="text"
                id="loginusername"
                value={loginUsername.value}
                maxLength="150"
                style={{ border: loginUsername.error ? "2px solid red" : "" }}
                onChange={(e) =>
                  setLoginUsername({
                    value: e.target.value,
                    error: loginUsername.error,
                  })
                }
                onBlur={(e) => validateLoginUsername(e.target.value)}
              />
              <p className="feedback-text"> {loginUsername.error}</p>

              <label htmlFor="loginpassword">رمز عبور</label>
              <input
                type="password"
                id="loginpassword"
                value={loginPassword.value}
                style={{ border: loginPassword.error ? "2px solid red" : "" }}
                onChange={(e) =>
                  setLoginPassword({
                    value: e.target.value,
                    error: loginPassword.error,
                  })
                }
                onBlur={(e) => validateLoginPassword(e.target.value)}
              />
              <p className="feedback-text"> {loginPassword.error}</p>
              <p className="feedback-text"> {loginError}</p>

              <input
                type="submit"
                value="ورود"
                className="submit"
                onClick={login}
              />
            </div>
          </animated.form>
          <animated.form action="" id="registerform" style={registerProps}>
            <div>
              <label htmlFor="fullname" style={{ margin: 0 }}>
                نام و نام خانوادگی
              </label>
              <div className="fullname-container">
                <input
                  type="text"
                  id="fullname"
                  style={{
                    direction: "rtl",
                    border:
                      !fullName.first && fullName.error ? "2px solid red" : "",
                  }}
                  value={fullName.first}
                  onChange={(e) => {
                    e.preventDefault();
                    setFullName({ first: e.target.value, last: fullName.last });
                  }}
                />
                <input
                  type="text"
                  id="fullname"
                  style={{
                    direction: "rtl",
                    border:
                      !fullName.last && fullName.error ? "2px solid red" : "",
                  }}
                  value={fullName.last}
                  onChange={(e) =>
                    setFullName({ first: fullName.first, last: e.target.value })
                  }
                />
              </div>
              <p className="feedback-text"> {fullName.error}</p>

              <label htmlFor="username">نام کاربری</label>
              <input
                type="text"
                id="username"
                value={username.value}
                maxLength="150"
                style={{ border: username.error ? "2px solid red" : "" }}
                onChange={(e) =>
                  setUsername({ value: e.target.value, error: username.error })
                }
                onBlur={(e) => validateUsername(e.target.value)}
              />
              <p className="feedback-text"> {username.error}</p>

              <label htmlFor="email">ایمیل</label>
              <input
                type="email"
                id="email"
                value={email.value}
                style={{ border: email.error ? "2px solid red" : "" }}
                onChange={(e) =>
                  setEmail({ value: e.target.value, error: email.error })
                }
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <p className="feedback-text"> {email.error}</p>

              <label htmlFor="password">رمز عبور</label>
              <input
                type="password"
                id="password"
                value={password.value}
                style={{ border: password.error ? "2px solid red" : "" }}
                onChange={(e) =>
                  setPass({
                    value: e.target.value,
                    error: password.error,
                    confirmErr: password.confirmErr,
                  })
                }
                onBlur={(e) => validatePass(e.target.value)}
              />
              <p className="feedback-text"> {password.error}</p>

              <label htmlFor="confirmpassword">تکرار رمز عبور</label>
              <input
                type="password"
                id="confirmpassword"
                onBlur={(e) => validateConfirmPass(e.target.value)}
                style={{ border: password.confirmErr ? "2px solid red" : "" }}
              />
              <p className="feedback-text"> {password.confirmErr}</p>

              <label htmlFor="date">تاریخ تولد (اختیاری)</label>
              <div className="fullname-container">
                <input
                  type="text"
                  id="date"
                  placeholder="روز"
                  value={Date.value[2]}
                  style={{
                    border:
                      Date.error[2] || Date.error[3] ? "2px solid red" : "",
                  }}
                  onChange={(e) =>
                    setDate({
                      value: changeDate(e.target.value, 2),
                      error: Date.error,
                    })
                  }
                  onBlur={(e) => validateDate(e.target.value, "روز", 2, 2)}
                />
                <input
                  type="text"
                  id="date"
                  placeholder="ماه"
                  value={Date.value[1]}
                  style={{
                    border:
                      Date.error[1] || Date.error[3] ? "2px solid red" : "",
                  }}
                  onChange={(e) =>
                    setDate({
                      value: changeDate(e.target.value, 1),
                      error: Date.error,
                    })
                  }
                  onBlur={(e) => validateDate(e.target.value, "ماه", 1, 2)}
                />
                <input
                  type="text"
                  id="date"
                  placeholder="سال"
                  value={Date.value[0]}
                  style={{
                    border:
                      Date.error[0] || Date.error[3] ? "2px solid red" : "",
                  }}
                  onChange={(e) =>
                    setDate({
                      value: changeDate(e.target.value, 0),
                      error: Date.error,
                    })
                  }
                  onBlur={(e) => validateDate(e.target.value, "سال", 0, 4)}
                />
              </div>
              {!!Date.error &&
                Date.error.map((err, i) => {
                  if (err) return <p className="feedback-text"> {err}</p>;
                })}
              <label htmlFor="gender">جنسیت (اختیاری)</label>
              <select
                name="gender"
                id="gender"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option value="NS">نامشخص</option>
                <option value="Male">مرد</option>
                <option value="Female">زن</option>
              </select>
              <input
                type="submit"
                value="ثبت نام"
                className="submit"
                onClick={register}
              />
            </div>
          </animated.form>
        </div>
        <animated.div className="forgot-panel" style={loginProps}>
          <a herf="#">رمز خود را فراموش کرده اید؟</a>
        </animated.div>
      </div>
    </div>
  );
}

export default Auth;
