import Dataset from "./Dataset";
import "./Dataset.css";

function DatasetSelectPage(props) {
  document.title="جمع‌آوری دیتاست | PersianVQA"
  return (
    <div className="dataset-page">
      <div className="dataset-page-inner">
        <div className="page-title-description" style={{ marginBottom: "5vh" }}>
          <h1>جمع‌آوری دیتاست</h1>
          <p className="description">
            به ما در جمع‌آوری دیتاست کمک کنید. شما می توانید به یکی از سه شکل زیر عمل کنید: 
          </p>
          {/* <p className="description">
            در صورت نیاز می‌توانید راهنمای جمع‌آوری دیتاست را ببینید.
          </p> */}
        </div>
        <div className="dataset-select-container" >
          <a href="/pvqa/dataset/answer-only">سناریوی ۱: وارد کردن پاسخ پرسش‌های مطرح شده</a>
          <a href="/pvqa/dataset/question-answer">سناریوی ۲: وارد کردن پرسش و پاسخ برای تصاویر</a>
          <a href="/pvqa/dataset/captions" dir="rtl">سناریوی ۳: وارد کردن متن (caption) برای تصویر</a>
        </div>

      </div>
    </div>
  );
}

export default DatasetSelectPage;